import DashboardTitle from '../../../components/atoms/DashboardTitle';
import EOJsTable from '../../../components/molecules/EOJsTable';

function DashboardEOJs() {
  return (
    <>
      <DashboardTitle title='EOJ List' />
      <EOJsTable />
    </>
  );
}

export default DashboardEOJs;
