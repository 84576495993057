import React, { ChangeEvent, useState } from 'react';
import { toast } from 'material-react-toastify';
import {
  Box,
  Button,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Notifications } from '@mui/icons-material';
import api from '../../../services/api';
import { UserProps } from '../../../types/UserProps';
import subscribeUser from '../../../utils/subscription';
import regexPatterns from '../../../utils/regexPatterns';

export default function ProfileForm({ user }: { user: UserProps }) {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

  const [userData, setUserData] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
    phone: user.phone,
  });
  const [currentUserDetails, setCurrentUserDetails] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
    phone: user.phone,
  });

  type ObjectKey = keyof typeof userData;

  const inputs = [
    {
      name: 'firstName',
      type: 'text',
      label: 'First name',
      message: "First name shouldn't include any special character!",
      pattern: regexPatterns.spacedAlphanumeric,
    },
    {
      name: 'lastName',
      type: 'text',
      label: 'Last name',
      message: "Last name shouldn't include any special character!",
      pattern: regexPatterns.spacedAlphanumeric,
    },
    {
      name: 'phone',
      type: 'text',
      label: 'Phone',
      message: 'Invalid phone!',
      pattern: regexPatterns.phone,
    },
  ];

  const isFormInvalid = inputs.some(input => {
    const inputName = input.name as ObjectKey;
    const inputPattern = userData[inputName]
      ? userData[inputName]!.toString()
      : '';
    return !input.pattern.test(inputPattern);
  });

  const hasUpdatedFields =
    JSON.stringify(userData) !== JSON.stringify(currentUserDetails);

  const canSubmit = hasUpdatedFields && !isFormInvalid;

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value ? e.target.value : null,
    });
  };

  const [subcription, setSubscription] = useState(user.subscription);

  const handleSubmit = async () => {
    const response = await api.post('/user', userData);
    if (response.status === 200) {
      setCurrentUserDetails(userData);
      toast.success('Updated sucessfully!');
    }
  };

  const handleSubscription = async () => {
    try {
      subscribeUser();
      setSubscription((sub: any) => !sub);
    } catch (error) {
      toast.error('Something went wrong with subscribe');
    }
  };

  const handleUnregister = async () => {
    try {
      await api.post('/notifications/unsubscribe');
      toast.info('Notifications disabled.');
      setSubscription((sub: any) => !sub);
    } catch (error) {
      toast.error('Something went wrong with unsubscribe');
    }
  };

  return (
    <Paper
      elevation={3}
      sx={{ p: 4 }}
    >
      <Typography variant='h6'>Profile details:</Typography>
      <Box
        sx={{
          mt: 2,
          display: 'flex',
          flexWrap: 'wrap',

          mb: 4,
        }}
      >
        {inputs.map((input, index) => {
          const inputName = input.name as ObjectKey;
          const inputValue = userData[inputName]
            ? userData[inputName]!.toString()
            : '';
          const hasError =
            !!userData[inputName] && !input.pattern.test(inputValue);
          const errorMessage = hasError ? input.message : '';

          return (
            <TextField
              key={input.name}
              value={inputValue}
              size='small'
              variant='standard'
              onChange={onChange}
              error={hasError}
              helperText={errorMessage}
              fullWidth
              sx={{ mt: index > 0 ? 2 : 0 }}
              {...input}
            />
          );
        })}
      </Box>
      <Box display={isMobile ? 'grid' : 'flex'}>
        <Button
          variant='contained'
          disabled={!canSubmit}
          onClick={() => handleSubmit()}
        >
          Save
        </Button>
        <Button
          sx={{
            ml: isMobile ? 0 : 4,
            mt: isMobile ? 2 : 0,
          }}
          variant='contained'
          onClick={subcription ? handleUnregister : handleSubscription}
          endIcon={<Notifications />}
        >
          {subcription ? 'Unregister notifications' : 'Activate notifications'}
        </Button>
      </Box>
    </Paper>
  );
}
