import {
  Link,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { ReactElement } from 'react';
import { Link as RouterLink } from 'react-router-dom';

export default function DrawerItem({
  title,
  children,
  href,
}: {
  title: string;
  children: ReactElement;
  href: string;
}) {
  return (
    <Link
      component={RouterLink}
      to={href}
    >
      <ListItemButton>
        <ListItemIcon>{children}</ListItemIcon>
        <ListItemText primary={title} />
      </ListItemButton>
    </Link>
  );
}
