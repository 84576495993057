import {
  Autocomplete,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { toast } from 'material-react-toastify';
import { useState } from 'react';
import { useDialog } from '../../../contexts/DialogContext';
import useVisits from '../../../hooks/useVisits';
import api from '../../../services/api';

interface QuoteVisitsProps {
  quoteId: number;
  refreshQuote: Function;
}

export default function QuoteVisitsDialog({
  quoteId,
  refreshQuote,
}: QuoteVisitsProps) {
  const { toggleDialog } = useDialog();
  const { visits } = useVisits();
  const [visitId, setVisitId] = useState(0);
  const handleSubmit = async () => {
    const response = await api.post('/quote/addVisit', { visitId, quoteId });
    if (response.status === 200) {
      toast.success('Visit added to quote successfully!');
      refreshQuote();
    }
    toggleDialog();
  };

  const visitsFiltered = visits.filter(visit => visit.quoteId === null);

  return (
    <>
      <DialogTitle>Add visits to quote</DialogTitle>
      <DialogContent>
        <Autocomplete
          sx={{ mt: 2 }}
          id='checkboxes-tags-demo'
          options={visitsFiltered}
          getOptionLabel={option => option.id?.toString() || ''}
          style={{ width: 400 }}
          renderInput={params => (
            <TextField
              {...params}
              label='Visits'
              placeholder='Visits'
            />
          )}
          onChange={(data, value) => setVisitId(value?.id!)}
        />
      </DialogContent>
      <DialogActions>
        <Button
          type='button'
          sx={{ color: 'error.main' }}
          onClick={() => toggleDialog()}
        >
          Cancel
        </Button>
        <Button onClick={() => handleSubmit()}>Update</Button>
      </DialogActions>
    </>
  );
}
