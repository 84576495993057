import { toast } from 'material-react-toastify';
import { useEffect, useState } from 'react';
import api from '../services/api';
import { QuoteProps } from '../types/QuoteProps';

const useQuotes = ({ onlyContracts }: { onlyContracts?: boolean }) => {
  const [quotes, setQuotes] = useState([] as QuoteProps[]);

  const getAllquotes = async () => {
    const response = await api.get(onlyContracts ? '/contracts' : '/quotes');
    if (response.status === 200) {
      setQuotes(response.data.quotes);
    }
  };

  const createQuote = async (quotesDetails: QuoteProps) => {
    const response = await api.post('/quote', quotesDetails);
    if (response.status === 200) {
      toast.success('Quote created successfully!');
      getAllquotes();
      return;
    }
    toast.error('Something went wrong creating quote');
  };

  const deleteQuote = async (id: number) => {
    const response = await api.delete(`/quote/${id}`);
    if (response.status === 200) {
      toast.success('Quote deleted successfully!');
      getAllquotes();
      return;
    }
    toast.error('Something went wrong creating quote');
  };

  const updateQuote = async (quotesDetails: QuoteProps) => {
    const response = await api.put(`/quote`, quotesDetails);
    if (response.status === 200) {
      toast.success('Quote updated successfully!');
      getAllquotes();
      return;
    }
    toast.error('Something went wrong creating quote');
  };

  useEffect(() => {
    getAllquotes();
  }, []);

  return {
    quotes,
    setQuotes,
    getAllquotes,
    createQuote,
    deleteQuote,
    updateQuote,
  };
};
export default useQuotes;
