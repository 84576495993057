import { ComponentProps } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SxProps,
  Theme,
} from '@mui/material';

interface SelectFieldProps
  extends Omit<ComponentProps<typeof Select>, 'onChange' | 'value'> {
  label: string;
  options: {
    value: string | number;
    label: string;
  }[];
  value: string | number | undefined;
  // eslint-disable-next-line
  onChange: (event: SelectChangeEvent<string | number>) => void;
  variant?: 'standard' | 'outlined' | 'filled';
  sx?: SxProps<Theme>;
}

function SelectField({
  label,
  options,
  value,
  onChange,
  variant = 'standard',
  sx,
  ...selectProps
}: SelectFieldProps) {
  return (
    <FormControl
      sx={sx}
      fullWidth
    >
      <InputLabel id={`${label}-label`}>{label}</InputLabel>
      <Select
        id={`${label}`}
        labelId={`${label}-label`}
        value={value}
        label={label}
        variant={variant}
        onChange={onChange as any}
        {...selectProps}
      >
        {options.map(option => (
          <MenuItem
            key={`option-${option.value}`}
            value={option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SelectField;
