import { Chip } from '@mui/material';
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { toast } from 'material-react-toastify';
import api from '../../../services/api';
import DashboardTable from '../../atoms/DashboardTable';
import useBillings from '../../../hooks/useBillings';

function BillingsTable() {
  const { getAllBillings, billings } = useBillings();

  const handleDeleteBilling = async (id: number) => {
    const response = await api.delete('/billing', {
      data: { id },
    });

    if (response.status === 200) {
      toast.success(`Billing deleted successfully!`);
      getAllBillings();
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      type: 'string',
      maxWidth: 80,
      align: 'center',
      headerAlign: 'center',
      filterable: false,
    },
    {
      field: 'companyName',
      headerName: 'Company name',
      flex: 1,
      filterable: true,
    },
    {
      field: 'accountsEmail',
      headerName: 'Accounts email',
      flex: 1,
    },
    {
      field: 'accountsContactNumber',
      headerName: 'Accounts contact number',
      flex: 1,
      filterable: true,
    },
    {
      field: 'companyAddress',
      headerName: 'Company address',
      flex: 1,
      filterable: true,
    },
    {
      field: 'companyRegNumber',
      headerName: 'Company register number',
      flex: 1,
      filterable: true,
    },
    {
      field: 'clientId',
      headerName: 'Client',
      flex: 1,
      filterable: true,
      renderCell: (params: GridRenderCellParams) =>
        params.row.client ? params.row.client.reference : null,
    },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'left',
      align: 'center',
      width: 100,
      filterable: true,
      renderCell: (params: GridRenderCellParams) => (
        <Chip
          label={params.row.status}
          color='primary'
        />
      ),
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      align: 'center',
      headerAlign: 'center',
      width: 69,
      filterable: false,
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          sx={{ color: 'error.main' }}
          icon={<DeleteForeverIcon sx={{ color: 'error.main' }} />}
          label='Delete'
          onClick={() => handleDeleteBilling(params.row.id)}
          showInMenu
        />,
      ],
    },
  ];

  return (
    <DashboardTable
      getRowId={Billing => Billing.id}
      columns={columns}
      rows={billings}
    />
  );
}

export default BillingsTable;
