import React, { useState, FormEvent } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Link,
  TextField,
} from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { toast } from 'material-react-toastify';
import api from '../../services/api';
import regexPatterns from '../../utils/regexPatterns';
import isEmpty from '../../utils/hasEmptyProperties';
import { useUser } from '../../contexts/UserContext';
import getErrorMessage, {
  ResponseErrorMessage,
} from '../../utils/getErrorMessage';

interface LoginProps {
  email: string;
  password: string;
}

const defaultValues: LoginProps = {
  email: '',
  password: '',
};

function Login() {
  const navigate = useNavigate();
  const { setUser } = useUser();

  const [loginData, setLoginData] = useState({
    email: defaultValues.email,
    password: defaultValues.password,
  });

  const emailInput = {
    autoFocus: true,
    name: 'email',
    type: 'email',
    label: 'Email',
    message: 'Invalid email address!',
    pattern: regexPatterns.email,
    sx: { mb: 2 },
  };

  const isFormInvalid = !emailInput.pattern.test(loginData.email);
  const noEmptyFields = isEmpty([loginData?.email, loginData?.password]);
  const hasError = !!loginData.email && isFormInvalid;
  const canSubmit = !isFormInvalid && noEmptyFields;

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    try {
      const response = await api.post('/login', loginData);

      if (response.status === 200) {
        const { token, user } = response.data;
        localStorage.setItem('HPS:token', token);
        setUser(user);
        navigate('/dashboard');
      }
    } catch (error) {
      toast.dismiss();
      toast.error(getErrorMessage(error as ResponseErrorMessage));
    }
  };

  return (
    <Card
      sx={{ maxWidth: 400, width: '100%', mx: 'auto', mt: 7 }}
      elevation={3}
    >
      <CardHeader
        sx={{
          bgcolor: 'secondary.main',
          color: 'white',
          px: 3,
          py: 2,
        }}
        titleTypographyProps={{ variant: 'h6', fontWeight: 'normal' }}
        title={
          <Grid
            container
            direction='row'
            alignItems='center'
            gap={1}
          >
            <LockIcon />
            Sign in
          </Grid>
        }
      />
      <CardContent sx={{ p: 3 }}>
        <form onSubmit={handleSubmit}>
          <TextField
            value={loginData.email}
            size='small'
            variant='standard'
            onChange={e =>
              setLoginData({ ...loginData, email: e.target.value })
            }
            error={hasError}
            helperText={hasError ? emailInput.message : ''}
            fullWidth
            {...emailInput}
          />
          <TextField
            key={emailInput.name}
            value={loginData.password}
            label='Password'
            type='password'
            size='small'
            variant='standard'
            onChange={e =>
              setLoginData({ ...loginData, password: e.target.value })
            }
            fullWidth
            sx={{ mb: 3 }}
          />
          <Link
            component={RouterLink}
            to='/forgotPassword'
          >
            Forgot password?
          </Link>
          <Button
            type='submit'
            variant='contained'
            sx={{ my: 2, bgcolor: 'secondary.main', boxShadow: 3 }}
            fullWidth
            disabled={!canSubmit}
          >
            Login
          </Button>
          <Link
            component={RouterLink}
            to='/terms'
          >
            Terms of Service & Privacy policy
          </Link>
        </form>
      </CardContent>
    </Card>
  );
}

export default Login;
