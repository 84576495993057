import { createTheme } from '@mui/material/styles';
import type {} from '@mui/x-data-grid/themeAugmentation';

const customTheme = createTheme({
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: '#FFFFFF',
            borderRadius: '25px',
          },
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
        },
        columnHeader: {
          backgroundColor: '#263262',
          color: 'white',
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          backgroundColor: 'background.paper',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#263262',
    },
    secondary: {
      main: '#263262',
    },
    error: {
      main: '#D22030',
      light: '#ED8A93',
    },
    info: {
      main: '#AACEF2',
    },
    warning: {
      main: '#96790b',
      light: '#FCF0C0',
    },
    success: {
      main: '#0f7331',
    },
    background: {
      default: '#E5E5E5',
    },
  },
});

export default customTheme;
