import { Button, useMediaQuery, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardTitle from '../../../../components/atoms/DashboardTitle';
import UsersTable from '../../../../components/molecules/UsersTable';
import api from '../../../../services/api';

function AllOperativesBySupervisor() {
  const [operatives, setOperatives] = useState([]);
  const navigate = useNavigate();
  const getOperativesBySupervisor = async () => {
    const response = await api.get('/operatives/bySupervisor');
    if (response.status === 200) setOperatives(response.data.operatives);
  };

  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

  useEffect(() => {
    getOperativesBySupervisor();
  }, []);
  return (
    <>
      <DashboardTitle title='Operatives assigned to you' />
      <Button
        variant='contained'
        sx={{ my: 2 }}
        fullWidth={isMobile}
        onClick={() => navigate('/dashboard/operatives')}
      >
        See all operatives
      </Button>
      <UsersTable users={operatives} />
    </>
  );
}

export default AllOperativesBySupervisor;
