import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardTitle from '../../../../components/atoms/DashboardTitle';
import DriverTimesheetForm from '../../../../components/molecules/DriverTimesheetForm';
import { useUser } from '../../../../contexts/UserContext';

function AddDriverTimesheet() {
  const { user } = useUser();
  const navigate = useNavigate();
  const isNotSupervisor = user?.role !== 'supervisor';

  useEffect(() => {
    if (isNotSupervisor) navigate('/dashboard');
  }, [isNotSupervisor, navigate, user?.role]);

  return (
    <>
      <DashboardTitle
        title='Add driver timesheet'
        hasBackButton
      />
      <DriverTimesheetForm />
    </>
  );
}

export default AddDriverTimesheet;
