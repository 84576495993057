import { Box, Button, Stack, SxProps, Theme, Typography } from '@mui/material';
import { ReactElement } from 'react';

interface SquaredButtonProps {
  label: string;
  subTitle?: string;
  icon?: ReactElement;
  onClick: Function;
  sx?: SxProps<Theme> | undefined;
}

function SquaredButton({
  label,
  subTitle,
  icon,
  onClick,
  sx,
}: SquaredButtonProps) {
  return (
    <Button
      variant='contained'
      color='primary'
      aria-label={label}
      onClick={() => onClick()}
      sx={sx || { width: 150, height: 150 }}
    >
      <Stack
        direction='column'
        alignItems='center'
        justifyContent='center'
      >
        <Box display='flex'>{icon}</Box>
        <Typography variant='subtitle2'>{subTitle}</Typography>
        <Typography sx={{ mt: 1 }}>{label}</Typography>
      </Stack>
    </Button>
  );
}

export default SquaredButton;
