import React, { FormEvent, useEffect, useState, useMemo } from 'react';
import {
  Box,
  Card,
  CardHeader,
  CardContent,
  Grid,
  FormControl,
  Autocomplete,
  TextField,
  Typography,
  Button,
} from '@mui/material';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import { useNavigate } from 'react-router-dom';
import { toast } from 'material-react-toastify';
import useClients from '../../../hooks/useClients';
import { VisitProps, VisitStatus } from '../../../types/VisitProps';
import { ClientOptionProps } from '../../../types/ClientProps';
import { BuildingOptionProps } from '../../../types/BuildingProps';
import api from '../../../services/api';
import {
  BuildingServiceOptionProps,
  BuildingServiceProps,
} from '../../../types/BuildingServicesProps';
import convertDecimalToTime from '../../../utils/convertDecimalToTime';
import DateField from '../../atoms/DateField';
import isEmpty from '../../../utils/hasEmptyProperties';

const initialValues: VisitProps = {
  id: 0,
  status: VisitStatus.POTENTIAL,
  startDate: new Date(),
  finishDate: new Date(),
  buildingId: 0,
};

function VisitForm() {
  const navigate = useNavigate();

  const { clients } = useClients();

  /* STATE */
  const [visitData, setVisitData] = useState<VisitProps>(initialValues);
  const [buildings, setBuildings] = useState<BuildingOptionProps[]>();
  const [buildingServices, setBuildingServices] = useState<
    BuildingServiceProps[] | null
  >(null);

  const [clientOption, setClientOption] = useState<ClientOptionProps>();
  const [buildingOption, setBuildingOption] =
    useState<BuildingOptionProps | null>(null);
  const [buildingServiceOption, setBuildingServiceOption] =
    useState<BuildingServiceOptionProps | null>(null);

  const { buildingId, serviceName, startDate, finishDate, visitTasks } =
    visitData;

  /* COMPUTED VALUES */
  const compatibleDates = useMemo(
    () => finishDate > startDate,
    [finishDate, startDate],
  );

  const canSubmit = useMemo(() => {
    if (!visitTasks) return false;
    const noEmptyFields = isEmpty([
      buildingId,
      serviceName,
      startDate,
      finishDate,
    ]);
    const hasFilledUnits = visitTasks.some(visitTask => visitTask.units);

    return noEmptyFields && compatibleDates && hasFilledUnits;
  }, [
    visitTasks,
    buildingId,
    serviceName,
    startDate,
    finishDate,
    compatibleDates,
  ]);

  const selectedBuildingService = useMemo(
    () => buildingServices?.find(item => item.id === buildingServiceOption?.id),
    [buildingServices, buildingServiceOption],
  );

  /* Functions */
  const getBuildingServices = async (_buildingId: number) => {
    const response = await api.get(`/building/${_buildingId}/services`);
    if (response.status === 200) {
      const filteredBuildingServices = response.data.buildingServices.filter(
        (buildingService: BuildingServiceProps) =>
          buildingService.buildingTasks &&
          buildingService.buildingTasks.length > 0,
      );

      setBuildingServices(filteredBuildingServices);
    }
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const response = await api.post('/visit', visitData);

    if (response.status === 200) {
      toast.success(`Visit registered sucessfully!`);
      navigate('/dashboard/visits');
    }
  };

  /* SIDE EFFECTS */
  useEffect(() => {
    const formattedBuildingTasks =
      !selectedBuildingService || !selectedBuildingService.buildingTasks
        ? []
        : selectedBuildingService.buildingTasks.map(task => ({
            ...task,
            units: 0,
          }));
    setVisitData(prevState => ({
      ...prevState,
      visitTasks: formattedBuildingTasks,
    }));
  }, [selectedBuildingService]);

  useEffect(() => {
    setBuildings(
      clients.find(client => client.id === clientOption?.id)?.buildings,
    );
  }, [clientOption?.id, clients]);

  useEffect(() => {
    if (buildingOption?.id) getBuildingServices(buildingOption?.id);
  }, [buildingOption?.id]);

  return (
    <Card elevation={3}>
      <CardHeader
        sx={{
          bgcolor: 'secondary.main',
          color: 'white',
          px: 3,
          py: 2,
        }}
        titleTypographyProps={{ variant: 'h6', fontWeight: 'normal' }}
        title={
          <Grid
            container
            direction='row'
            alignItems='center'
            gap={1}
          >
            Add visit
          </Grid>
        }
      />
      <CardContent sx={{ p: 3 }}>
        <form onSubmit={handleSubmit}>
          <FormControl fullWidth>
            <Autocomplete
              options={clients || []}
              value={clientOption || null}
              onChange={(e, value) => {
                if (value?.id && value.reference) {
                  setClientOption({ id: value.id, reference: value.reference });
                  setBuildingOption(null);
                  setBuildingServices(null);
                  setBuildingServiceOption(null);
                }
              }}
              fullWidth
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={option => option.reference}
              renderInput={params => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  margin='dense'
                  variant='standard'
                  label='Client'
                />
              )}
            />
            <Autocomplete
              options={buildings || []}
              value={buildingOption || null}
              onChange={(e, value) => {
                if (value?.id && value.name) {
                  setBuildingOption({ id: value.id, name: value.name });
                  setBuildingServiceOption(null);
                  setVisitData({
                    ...visitData,
                    buildingId: value.id,
                  });
                }
              }}
              fullWidth
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={option => option.name}
              renderInput={params => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  sx={{ mt: 1.7 }}
                  margin='dense'
                  variant='standard'
                  label='Building'
                />
              )}
            />
            <Autocomplete
              options={buildingServices || []}
              value={buildingServiceOption || null}
              onChange={(e, value) => {
                if (value?.id && value.serviceName) {
                  setBuildingServiceOption({
                    id: value.id,
                    serviceId: value.serviceId,
                    serviceName: value.serviceName,
                  });
                  setVisitData({
                    ...visitData,
                    serviceName: value.serviceName,
                  });
                }
              }}
              fullWidth
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={option => option.serviceName}
              renderInput={params => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  sx={{ mt: 1.7, mb: 2 }}
                  margin='dense'
                  variant='standard'
                  label='Service'
                />
              )}
            />
          </FormControl>
          {selectedBuildingService?.buildingTasks &&
            selectedBuildingService?.buildingTasks.length > 0 && (
              <Box sx={{ flexGrow: 1, mt: 3, mb: 6 }}>
                <Grid
                  container
                  direction='row'
                  alignItems='center'
                  columns={4}
                  spacing={3}
                  style={{ width: '100%' }}
                >
                  <Grid
                    item
                    xs={1}
                  >
                    <Typography variant='subtitle2'>Task name</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                  >
                    <Typography variant='subtitle2'>Number of units</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                  >
                    <Typography variant='subtitle2'>Price</Typography>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                  >
                    <Typography variant='subtitle2'>Time</Typography>
                  </Grid>
                  {selectedBuildingService?.buildingTasks &&
                    selectedBuildingService?.buildingTasks
                      .sort((a, b) => Number(a?.id) - Number(b?.id))
                      .map(buildingTask => {
                        const { id, name, price, time } = buildingTask;
                        const unitValue = visitTasks?.find(
                          visitTask => visitTask.id === id,
                        )?.units;

                        return (
                          <React.Fragment key={`buildingTask-${name}`}>
                            <Grid
                              item
                              xs={1}
                            >
                              <Typography variant='subtitle1'>
                                {name}
                              </Typography>
                            </Grid>
                            <Grid
                              item
                              xs={1}
                            >
                              <TextField
                                type='number'
                                value={unitValue || 0}
                                inputProps={{ min: 0 }}
                                variant='standard'
                                fullWidth
                                size='small'
                                onChange={event => {
                                  if (
                                    !visitTasks ||
                                    !event.target.value ||
                                    Number(event.target.value) < 0
                                  )
                                    return;
                                  const newVisitTasks = visitTasks.map(
                                    visitTask => {
                                      if (visitTask.id !== id) {
                                        return visitTask;
                                      }
                                      return {
                                        ...visitTask,
                                        units: Number(event.target.value),
                                      };
                                    },
                                  );
                                  setVisitData({
                                    ...visitData,
                                    visitTasks: newVisitTasks,
                                  });
                                }}
                              />
                            </Grid>
                            <Grid
                              item
                              xs={1}
                            >
                              <TextField
                                variant='standard'
                                value={price || 'No price set'}
                                disabled
                                fullWidth
                                size='small'
                              />
                            </Grid>
                            <Grid
                              item
                              xs={1}
                            >
                              <TextField
                                variant='standard'
                                value={
                                  time
                                    ? convertDecimalToTime(time)
                                    : 'No time set'
                                }
                                disabled
                                fullWidth
                                size='small'
                              />
                            </Grid>
                          </React.Fragment>
                        );
                      })}
                </Grid>
              </Box>
            )}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
            <DateField
              label='Start date'
              value={visitData.startDate}
              minDate={new Date()}
              onChange={date => {
                setVisitData({
                  ...visitData,
                  startDate: date || new Date(),
                });
              }}
              sx={{ width: '100%', mr: 6 }}
            />
            <TimeField
              format='HH:mm'
              label='Start time (optional)'
              variant='outlined'
              value={visitData.startDate}
              onChange={date => {
                setVisitData({
                  ...visitData,
                  startDate: date || new Date(),
                });
              }}
              sx={{ width: '100%' }}
            />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
            <DateField
              label='Finish date'
              value={visitData.finishDate}
              minDate={visitData.startDate}
              onChange={date => {
                setVisitData({
                  ...visitData,
                  finishDate: date || new Date(),
                });
              }}
              sx={{ width: '100%', mr: 6 }}
            />
            <TimeField
              format='HH:mm'
              label='Finish time (optional)'
              variant='outlined'
              value={visitData.finishDate}
              onChange={date => {
                setVisitData({
                  ...visitData,
                  finishDate: date || new Date(),
                });
              }}
              sx={{ width: '100%' }}
            />
          </Box>
          <Button
            type='submit'
            variant='contained'
            disabled={!canSubmit}
            sx={{ mt: 3, bgcolor: 'secondary.main', boxShadow: 3 }}
            fullWidth
          >
            Submit
          </Button>
        </form>
      </CardContent>
    </Card>
  );
}

export default VisitForm;
