/* eslint-disable no-plusplus */

import { toast } from 'material-react-toastify';
import api from '../services/api';

function urlBase64ToUint8Array(base64String: string) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  // eslint-disable-next-line
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

const convertedVapidKey = urlBase64ToUint8Array(
  process.env.REACT_APP_PUBLIC_VAPID_KEY || '',
);

function sendSubscription(subscription: any) {
  return api.post(
    '/notifications/subscribe',
    JSON.stringify({
      subscription,
    }),
  );
}

export function checkSubscription() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then(registration => {
        if (!registration.pushManager) {
          console.log('Push manager unavailable.');
          return;
        }

        registration.pushManager.getSubscription().then(existedSubscription => {
          if (existedSubscription === null) {
            console.log('No subscription detected, make a request.');
            registration.pushManager
              .subscribe({
                applicationServerKey: convertedVapidKey,
                userVisibleOnly: true,
              })
              .then(newSubscription => {
                console.log('New subscription added.', newSubscription);
                toast.success('subscription added successfully!');
                sendSubscription(newSubscription);
              })
              .catch(e => {
                if (Notification.permission !== 'granted') {
                  console.log('Permission was not granted.');
                } else {
                  console.error(
                    'An error ocurred during the subscription process.',
                    e,
                  );
                }
              });
          } else {
            console.log('Existed subscription detected.');
            sendSubscription(existedSubscription);
          }
        });
      })
      .catch(e => {
        console.error(
          'An error ocurred during Service Worker registration.',
          e,
        );
        toast.error('An error ocurred during Service Worker registration.');
      });
  }
}

export default function subscribeUser() {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.ready
      .then(registration => {
        if (!registration.pushManager) {
          console.log('Push manager unavailable.');
          return;
        }

        registration.pushManager.getSubscription().then(existedSubscription => {
          if (existedSubscription === null) {
            console.log('No subscription detected, make a request.');
            registration.pushManager
              .subscribe({
                applicationServerKey: convertedVapidKey,
                userVisibleOnly: true,
              })
              .then(newSubscription => {
                console.log('New subscription added.', newSubscription);
                toast.success('subscription added successfully!');
                sendSubscription(newSubscription);
              })
              .catch(e => {
                if (Notification.permission !== 'granted') {
                  console.log('Permission was not granted.');
                } else {
                  console.error(
                    'An error ocurred during the subscription process.',
                    e,
                  );
                }
              });
          } else {
            console.log('Existed subscription detected.');
            toast.info('Notifications enabled!');
            sendSubscription(existedSubscription);
          }
        });
      })
      .catch(e => {
        console.error(
          'An error ocurred during Service Worker registration.',
          e,
        );
        toast.error('An error ocurred during Service Worker registration.');
      });
  }
}
