import React, { ReactElement, useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import {
  AppBar,
  Drawer,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Menu, Person, Logout } from '@mui/icons-material';
import Toolbar from '@mui/material/Toolbar';
import { useUser } from '../../../contexts/UserContext';
import api from '../../../services/api';
import UserMenu from '../UserMenu';
import { PagesProps } from '../../../types/PagesProps';

interface DrawerItemProps {
  page: {
    title: string;
    icon: ReactElement;
    href: string;
  };
}

function DrawerItem({ page }: DrawerItemProps) {
  return (
    <ListItemButton
      component={RouterLink}
      to={page.href}
    >
      <ListItemIcon sx={{ color: 'white' }}>
        {page.icon}
        <ListItemText sx={{ mx: 2 }}>{page.title}</ListItemText>
      </ListItemIcon>
    </ListItemButton>
  );
}

function SideBar({ pages }: PagesProps) {
  const [toggleDrawer, setToggleDrawer] = useState(false);
  const navigate = useNavigate();

  const handleUserLogout = () => {
    localStorage.removeItem('HPS:token');
    navigate('/login');
  };

  return (
    <>
      <Drawer
        PaperProps={{
          sx: {
            backgroundColor: 'primary.main',
            color: 'white',
          },
        }}
        open={toggleDrawer}
        onClose={() => setToggleDrawer(false)}
      >
        <List>
          <ListItemButton
            component={RouterLink}
            to='/dashboard'
          >
            <ListItemText>
              <strong>HPS</strong>
            </ListItemText>
          </ListItemButton>
          {pages.map(page => (
            <DrawerItem
              key={page.title}
              page={page}
            />
          ))}
          <DrawerItem
            page={{
              title: 'Profile',
              icon: <Person />,
              href: '/dashboard/profile',
            }}
          />
          <ListItemButton onClick={handleUserLogout}>
            <ListItemIcon sx={{ color: 'white' }}>
              <Logout />
              <ListItemText sx={{ mx: 2 }}>Logout</ListItemText>
            </ListItemIcon>
          </ListItemButton>
        </List>
      </Drawer>
      <IconButton
        sx={{ color: 'white ' }}
        onClick={() => setToggleDrawer(!toggleDrawer)}
      >
        <Menu />
      </IconButton>
    </>
  );
}

function MobileMenuBar({ pages }: PagesProps) {
  const { user, setUser } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    const handleUser = async () => {
      const token = localStorage.getItem('HPS:token');
      if (!token) {
        setUser(null);
        navigate('/login');
        return;
      }
      if (!user) {
        const response = await api.get('/user');
        if (response.status === 200) setUser(response.data.user);
      }
    };
    handleUser();
  }, [navigate, user, location.pathname, setUser]);

  return (
    <AppBar position='fixed'>
      <Toolbar
        sx={{
          pr: '24px',
        }}
      >
        <Typography
          component={RouterLink}
          to='/dashboard'
          variant='h6'
          noWrap
          sx={{ color: 'white', textDecoration: 'none', flexGrow: 1 }}
        >
          HPS
        </Typography>
        {isMobile ? (
          <SideBar pages={pages} />
        ) : (
          <>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
            >
              {pages.map((page, index) => (
                <Tab
                  sx={{ color: 'white' }}
                  component={RouterLink}
                  key={page.title}
                  value={index}
                  label={page.title}
                  to={page.href}
                />
              ))}
            </Tabs>
            <UserMenu />
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}

export default MobileMenuBar;
