import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import DashboardTitle from '../../../components/atoms/DashboardTitle';
import JobBriefsTable from '../../../components/molecules/JobBriefsTable';
import { useUser } from '../../../contexts/UserContext';

function DashboardJobBriefs() {
  const navigate = useNavigate();
  const { user } = useUser();
  const isAdmin = user?.role === 'admin';

  return (
    <>
      <DashboardTitle title='Job Briefs' />
      {isAdmin && (
        <Button
          variant='contained'
          endIcon={<AddIcon />}
          sx={{ my: 2 }}
          onClick={() => navigate('/dashboard/jobBriefs/add')}
        >
          Add Job Brief
        </Button>
      )}
      <JobBriefsTable />
    </>
  );
}

export default DashboardJobBriefs;
