import React from 'react';
import { Box, Typography, useMediaQuery, useTheme } from '@mui/material';
import GoBackButton from '../GoBackButton';

export default function DashboardTitle({
  title,
  hasBackButton,
}: {
  title: string;
  hasBackButton?: boolean;
}) {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

  return (
    <Box
      display='flex'
      sx={{
        mb: 2,
        ml: hasBackButton && !isMobile ? 1 : 0,
        justifyContent: isMobile ? 'center' : 'left',
      }}
    >
      {hasBackButton && <GoBackButton />}
      <Typography
        variant={isMobile ? 'h4' : 'h3'}
        sx={{ ml: hasBackButton ? 2 : 0 }}
      >
        {title}
      </Typography>
    </Box>
  );
}
