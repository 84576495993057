import DashboardTitle from '../../../../components/atoms/DashboardTitle';
import JobBriefForm from '../../../../components/molecules/JobBriefForm';

function AddJobBrief() {
  return (
    <>
      <DashboardTitle
        title='Add Job Brief'
        hasBackButton
      />
      <JobBriefForm />
    </>
  );
}

export default AddJobBrief;
