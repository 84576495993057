import React from 'react';
import DashboardTitle from '../../../components/atoms/DashboardTitle';
import ServiceTaskForm from '../../../components/molecules/ServiceTaskForm';

function DashboardServiceTask() {
  return (
    <>
      <DashboardTitle
        title='Services'
        hasBackButton
      />
      <ServiceTaskForm />
    </>
  );
}

export default DashboardServiceTask;
