import { useEffect, useState } from 'react';
import api from '../services/api';
import { JobBriefProps } from '../types/JobBriefProps';

const useJobBriefs = () => {
  const [jobBriefs, setJobBriefs] = useState([] as JobBriefProps[]);

  const getAllJobBriefs = async () => {
    const response = await api.get('/jobBriefs');
    if (response.status === 200) {
      setJobBriefs(response.data.jobBriefs);
    }
  };

  useEffect(() => {
    getAllJobBriefs();
  }, []);

  return {
    jobBriefs,
    getAllJobBriefs,
  };
};
export default useJobBriefs;
