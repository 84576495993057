import React, { useState, FormEvent, useEffect, useRef } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Grid,
  FormControl,
  TextField,
  Stepper,
  Step,
  StepLabel,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { eachDayOfInterval, startOfWeek, isEqual, format } from 'date-fns';
import withReactContent from 'sweetalert2-react-content';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { toast } from 'material-react-toastify';
import { TimesheetProps } from '../../../types/TimesheetProps';
import { JobBriefProps } from '../../../types/JobBriefProps';
import { VisitProps } from '../../../types/VisitProps';
import { useUser } from '../../../contexts/UserContext';
import { TimesheetReportProps } from '../../../types/TimesheetReportProps';
import { ProgressReportProps } from '../../../types/ProgressReportProps';
import api from '../../../services/api';
import formatDate from '../../../utils/formatDate';
import SquaredButton from '../../atoms/SquaredButton';
import useTimesheets from '../../../hooks/useTimesheets';
import TimesheetReportForm from '../TimesheetReportForm';
import ProgressReportForm from '../ProgressReportForm';
import { PayrollReportProps } from '../../../types/PayrollReportProps';

const initialValues: TimesheetProps = {
  id: 0,
  comment: '',
  jobBriefId: 0,
  visitId: 0,
  dateReference: new Date(),
  createdAt: new Date(),
};

function TimesheetForm() {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);

  const { user } = useUser();
  const isSupervisor = user?.role === 'supervisor';
  const isContractor = user?.role === 'contractor';
  const { timesheets } = useTimesheets({
    bySupervisor: isSupervisor,
  });

  const [jobBriefs, setJobBriefs] = useState([] as JobBriefProps[]);
  const commentRef = useRef<HTMLInputElement>();

  const getJobBriefs = async () => {
    const response = await api.get(`/jobBriefs/bySupervisor`);
    if (response.status === 200) {
      setJobBriefs(response.data.jobBriefs);
    }
  };
  useEffect(() => {
    getJobBriefs();
  }, []);

  const [timesheetData, setTimesheetData] =
    useState<TimesheetProps>(initialValues);
  const [jobBrief, setJobBrief] = useState<JobBriefProps>({} as JobBriefProps);
  const [visit, setVisit] = useState<VisitProps>({} as VisitProps);
  const [showModal, setShowModal] = useState<boolean>(false);

  const getDatesFromCurrentWeek = () => {
    const today = new Date();

    return eachDayOfInterval({
      start: startOfWeek(today, {
        weekStartsOn: 1,
      }),
      end: today,
    });
  };

  const backToStep = (step: number) => (
    <Button
      type='submit'
      variant='contained'
      onClick={() => setActiveStep(step)}
      sx={{
        width: isMobile ? '100%' : 'fit-content',
        bgcolor: 'secondary.main',
        boxShadow: 3,
        mt: isMobile ? 3 : 1,
      }}
    >
      Back
    </Button>
  );

  const checkAssignedVisits = (selectedJobBrief: JobBriefProps) => {
    if (
      selectedJobBrief.visits!.filter(row => row.teamId === user?.teamId)
        .length < 1
    ) {
      setActiveStep(0);
      toast.dismiss();
      toast.info('No visits assigned for your team in this job brief!');
    } else {
      setActiveStep(1);
    }
  };

  const checkPreviousTimesheet = async (timesheetDate: Date) => {
    const previousTimesheet = timesheets.find(
      timesheet =>
        isEqual(new Date(timesheet.dateReference), new Date(timesheetDate)) &&
        timesheet.jobBriefId === timesheetData.jobBriefId,
    );

    if (previousTimesheet) {
      toast.dismiss();
      toast.error('Timesheet for selected day already exists!');
    } else {
      setActiveStep(2);
    }
  };

  const getTimesheetReports = (timesheetReports: TimesheetReportProps[]) => {
    //  can get payrate
    //  can get total number of hours
    //  can get referenceNumber
    setTimesheetData({
      ...timesheetData,
      timesheetReports,
    });
  };

  const getPayrollReports = (payrollReports: PayrollReportProps[]) => {
    setTimesheetData({
      ...timesheetData,
      payrollReports,
    });
  };

  const getProgressReports = (progressReports: ProgressReportProps[]) => {
    setTimesheetData({
      ...timesheetData,
      progressReports,
    });
  };

  const hasUnfilledTimesheetReports = () => {
    if (
      timesheetData.timesheetReports &&
      timesheetData.timesheetReports.length > 0
    ) {
      return timesheetData.timesheetReports.some(
        timesheetReport =>
          (!timesheetReport.dayOff &&
            (!timesheetReport.startTime || !timesheetReport.finishTime)) ||
          !timesheetData.timesheetReports,
      );
    }

    return true;
  };

  const hasUnfilledProgressReports = () => {
    if (
      timesheetData.progressReports &&
      timesheetData.progressReports.length > 0
    ) {
      return timesheetData.progressReports.some(progressReport =>
        Number.isNaN(progressReport.completedUnits),
      );
    }

    return true;
  };

  const submit = async () => {
    const response = await api.post('/timesheet', {
      ...timesheetData,
      supervisorId: isSupervisor || isContractor ? user.id : null,
      comment: commentRef.current?.value,
      jobBriefId: jobBrief.id,
    });

    if (response.status === 200) {
      toast.success('Timesheet registered successfully!');
      navigate('/dashboard/timesheets');
    }
  };

  //    eslint-disable-next-line
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!showModal) return submit();

    const MySwal = withReactContent(Swal);

    MySwal.fire({
      title: 'Are you sure?',
      text: 'Report is above the number of units contracted. Do you want to continue?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      icon: 'warning',
    }).then(async response => {
      if (response.isConfirmed) await submit();
    });
  };

  return (
    <Card elevation={3}>
      <CardHeader
        sx={{
          bgcolor: 'secondary.main',
          color: 'white',
          px: 3,
          py: 2,
        }}
        titleTypographyProps={{ variant: 'h6', fontWeight: 'normal' }}
        title={
          <Grid
            container
            direction='row'
            alignItems='center'
            gap={1}
          >
            Add timesheet
          </Grid>
        }
      />
      <CardContent sx={{ p: 3 }}>
        <Stepper
          activeStep={activeStep}
          sx={{ display: 'flex', flexWrap: 'wrap', rowGap: 4, mb: 3 }}
        >
          <Step>
            <StepLabel>SELECT JOB BRIEF</StepLabel>
          </Step>
          <Step>
            <StepLabel>SELECT DAY</StepLabel>
          </Step>
          <Step>
            <StepLabel>SELECT VISIT</StepLabel>
          </Step>
          <Step>
            <StepLabel>
              TIMESHEET REPORT{isContractor && ' (OPTIONAL)'}
            </StepLabel>
          </Step>
          <Step>
            <StepLabel>PROGRESS REPORT</StepLabel>
          </Step>
        </Stepper>
        <form onSubmit={handleSubmit}>
          <FormControl fullWidth>
            {activeStep === 0 && (
              <TableContainer>
                <Table
                  sx={{ width: '100%' }}
                  size='medium'
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>Ref. Number</TableCell>
                      <TableCell align='left'>Building</TableCell>
                      <TableCell align='left'>Building address</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {jobBriefs.map(row => (
                      <TableRow
                        onClick={() => {
                          setTimesheetData({
                            ...timesheetData,
                            timesheetReports: [],
                            progressReports: [],
                            jobBriefId: row.id,
                          });
                          setJobBrief(row);
                          checkAssignedVisits(row);
                        }}
                        key={row.id}
                        hover
                        selected={row.id === jobBrief.id}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                          cursor: 'pointer',
                        }}
                      >
                        <TableCell
                          component='th'
                          scope='row'
                        >
                          {row.referenceNumber}
                        </TableCell>
                        <TableCell align='left'>{row.building?.name}</TableCell>
                        <TableCell align='left'>
                          {row.building?.address}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            {activeStep === 1 && (
              <>
                <Box
                  sx={{
                    display: 'grid',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 2,
                    gridTemplateColumns: 'repeat(auto-fit, 120px)',
                  }}
                >
                  {getDatesFromCurrentWeek().map((date: Date) => (
                    <SquaredButton
                      key={date.toString()}
                      label={formatDate(date, true)}
                      subTitle={format(date, 'E')}
                      onClick={() => {
                        setTimesheetData({
                          ...timesheetData,
                          timesheetReports: [],
                          progressReports: [],
                          dateReference: date,
                        });
                        checkPreviousTimesheet(date);
                      }}
                      sx={{ width: 120, height: 120 }}
                    />
                  ))}
                </Box>
                {backToStep(0)}
              </>
            )}
            {activeStep === 2 && (
              <>
                <TableContainer>
                  <Table
                    sx={{ width: '100%' }}
                    size='medium'
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>ID</TableCell>
                        <TableCell align='left'>Visit date</TableCell>
                        <TableCell align='left'>Service Name</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {jobBrief
                        .visits!.filter(row => row.teamId === user?.teamId)
                        .map(row => (
                          <TableRow
                            onClick={() => {
                              setTimesheetData({
                                ...timesheetData,
                                timesheetReports: [],
                                progressReports: [],
                                visitId: row.id,
                              });
                              setVisit(row);
                              setActiveStep(3);
                            }}
                            key={row.id}
                            hover
                            selected={row.id === jobBrief.id}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                              cursor: 'pointer',
                            }}
                          >
                            <TableCell
                              component='th'
                              scope='row'
                            >
                              {row.id}
                            </TableCell>
                            <TableCell align='left'>
                              {formatDate(row.startDate)}
                            </TableCell>
                            <TableCell align='left'>
                              {row.serviceName}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {backToStep(1)}
              </>
            )}
            {activeStep === 3 && (
              <>
                <TimesheetReportForm
                  visit={visit}
                  handleTimesheetReports={getTimesheetReports}
                  handlePayrollReports={getPayrollReports}
                />
                <Box
                  sx={{
                    display: isMobile ? 'grid' : 'flex',
                    justifyContent: isMobile ? 'unset' : 'space-between',
                    alignItems: 'center',
                    mt: isMobile ? 0 : 6,
                    width: '100%',
                  }}
                >
                  <Button
                    disabled={hasUnfilledTimesheetReports()}
                    type='submit'
                    variant='contained'
                    onClick={() => setActiveStep(4)}
                    sx={{
                      width: '100%',
                      bgcolor: 'secondary.main',
                      boxShadow: 3,
                      mt: isMobile ? 2 : 0,
                    }}
                  >
                    Confirm & Next
                  </Button>
                  {isContractor && (
                    <Button
                      type='submit'
                      variant='contained'
                      onClick={() => setActiveStep(4)}
                      sx={{
                        width: isMobile ? '100%' : 'fit-content',
                        mt: isMobile ? 2 : 0,
                        ml: isMobile ? 0 : 2,
                        bgcolor: 'secondary.main',
                        boxShadow: 3,
                      }}
                    >
                      Skip
                    </Button>
                  )}
                </Box>
              </>
            )}
            {activeStep === 4 && (
              <>
                <ProgressReportForm
                  visit={visit}
                  jobBriefId={jobBrief.id}
                  handleProgressReports={getProgressReports}
                  setShowModal={setShowModal}
                />
                <TextField
                  sx={{ mt: 4 }}
                  label='Add Comments (Optional)'
                  multiline
                  fullWidth
                  variant='standard'
                  inputRef={commentRef}
                />
                <Button
                  disabled={hasUnfilledProgressReports()}
                  type='submit'
                  variant='contained'
                  onClick={handleSubmit}
                  sx={{
                    width: '100%',
                    bgcolor: 'secondary.main',
                    boxShadow: 3,
                    mt: 2,
                  }}
                >
                  Submit
                </Button>
              </>
            )}
          </FormControl>
        </form>
      </CardContent>
    </Card>
  );
}

export default TimesheetForm;
