import { useEffect, useState } from 'react';
import { toast } from 'material-react-toastify';
import api from '../services/api';
import { UserProps } from '../types/UserProps';

const useUsers = () => {
  const [users, setUsers] = useState([] as UserProps[]);

  const getAllUsers = async () => {
    const response = await api.get('/users');
    if (response.status === 200) {
      setUsers(response.data.users);
    }
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  const createUser = async (userDetails: UserProps) => {
    const response = await api.post('/user', userDetails);
    if (response.status === 200) {
      const { id } = response.data.user;
      setUsers([...users, { ...userDetails, id }]);
      toast.success('User registered successfully!');
    }
  };

  return {
    users,
    getAllUsers,
    createUser,
  };
};
export default useUsers;
