import React, { FC, Dispatch, SetStateAction } from 'react';
import { Grid } from '@mui/material';
import {
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';

const CustomTableToolbar: FC<{
  setFilterButtonEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
  // eslint-disable-next-line react/function-component-definition
}> = ({ setFilterButtonEl }) => (
  <Grid
    container
    direction='row'
    justifyContent='space-between'
    alignItems='center'
    sx={{ px: 0.6, py: 0.1 }}
  >
    <GridToolbarFilterButton ref={setFilterButtonEl} />
    <GridToolbarQuickFilter sx={{ p: 1 }} />
  </Grid>
);

export default CustomTableToolbar;
