import { useState, useEffect } from 'react';
import { toast } from 'material-react-toastify';
import api from '../../../services/api';
import DashboardTitle from '../../../components/atoms/DashboardTitle';
import ProductivityTable from '../../../components/molecules/ProductivityTable';

function DashboardProductivity() {
  const [reports, setReports] = useState([]);

  useEffect(() => {
    const fetchReports = async () => {
      try {
        const response = await api.get('/productivityReports');
        const { status, data } = response;
        if (status !== 200) {
          throw new Error(`Status from api is invalid: ${status}`);
        }
        const { productivityReport } = data;
        setReports(productivityReport);
      } catch (error) {
        toast.error('Failed to fetch productivity reports');
        console.error(error);
      }
    };

    fetchReports();
  }, []);

  return (
    <>
      <DashboardTitle title='Productivity Report' />
      <ProductivityTable reports={reports} />
    </>
  );
}

export default DashboardProductivity;
