import { MenuItem, TextField } from '@mui/material';
import { ChangeEventHandler } from 'react';

interface ListFieldProps {
  options: {
    name?: string;
    reference?: string;
    id: string | number;
  }[];
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  label: string;
}

export default function ListField({
  options,
  onChange,
  label,
}: ListFieldProps) {
  return (
    <TextField
      label={label}
      variant='standard'
      select
      onChange={onChange}
      sx={{ my: 2 }}
    >
      {options.map(option => (
        <MenuItem
          key={option.id}
          value={option.id}
        >
          {option.name || option.reference}
        </MenuItem>
      ))}
    </TextField>
  );
}
