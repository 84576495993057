import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import api from '../../services/api';
import RegisterForm from '../../components/molecules/RegisterForm';
import { InvitedUserProps } from '../../types/InviteUserProps';
import DashboardTitle from '../../components/atoms/DashboardTitle';

function Invite() {
  const { hashLink } = useParams();
  const [userInvited, setUserInvited] = useState({} as InvitedUserProps);
  const getInvite = async () => {
    const response = await api.get(`/invite/${hashLink}`);
    if (response.data.invite) {
      setUserInvited(response.data.invite);
    }
  };

  useEffect(() => {
    getInvite();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DashboardTitle title='Invite' />
      <RegisterForm invitedUser={userInvited} />
    </>
  );
}

export default Invite;
