import { differenceInMinutes } from 'date-fns';
import convertDecimalToTime from './convertDecimalToTime';

const getTimeBetweenTimeStrings = (timeA: string, timeB: string) => {
  const newDate = new Date();

  let day = newDate.getDate().toString();
  day = Number(day) > 9 ? day : `0${day}`;

  let month = (newDate.getMonth() + 1).toString();
  month = Number(month) > 9 ? month : `0${month}`;

  const year = newDate.getFullYear();

  const timeAHour = Number(timeA.replace(/\D/g, ''));
  const timeBHour = Number(timeB.replace(/\D/g, ''));

  const dateA = new Date(`${year}-${month}-${day}T${timeA || '00:00'}:00`);
  const dateB = new Date(`${year}-${month}-${day}T${timeB || '00:00'}:00`);

  if (timeAHour > timeBHour) {
    dateB.setDate(dateB.getDate() + 1);
  }

  const totalMinutes = Math.abs(differenceInMinutes(dateA, dateB));
  const decimal = Number((totalMinutes / 60).toFixed(2));

  return {
    formatted: timeA && timeB && convertDecimalToTime(decimal),
    decimal,
  };
};

export default getTimeBetweenTimeStrings;
