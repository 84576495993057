import React, { useState, FormEvent } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Grid,
  FormControl,
  Box,
  TextField,
  Autocomplete,
} from '@mui/material';
import isEmpty from '../../../utils/hasEmptyProperties';
import DateField from '../../atoms/DateField';
import useUsers from '../../../hooks/useUsers';
import { DriverReportFilterProps } from '../../../types/DriverReportFilterProps';
import useDriverTimesheets from '../../../hooks/useDriverTimesheets';

const initialValues: DriverReportFilterProps = {
  startDate: new Date(),
  endDate: new Date(),
  userId: 0,
  vehicleReg: '',
  from: '',
  to: '',
};

interface DriverReportFormProps {
  handleSubmit: Function;
}

function DriverReportForm({ handleSubmit }: DriverReportFormProps) {
  const [reportData, setDriverReportData] =
    useState<DriverReportFilterProps>(initialValues);

  const noEmptyFields = isEmpty([reportData?.startDate, reportData?.endDate]);
  const { users } = useUsers();
  const { driverTimesheets } = useDriverTimesheets();

  const [driverOption, setDriverOption] = useState<{
    name: string;
    id?: number;
  } | null>();
  const [vehicleReg, setVehicleReg] = useState<string | null>();
  const [from, setFrom] = useState<string | null>();
  const [to, setTo] = useState<string | null>();

  const drivers = users
    .filter(user => user.isDriver)
    .map(driver => ({ id: driver.id || undefined, name: driver.firstName }));
  const compatibleDates = reportData.startDate! <= reportData.endDate!;

  const filteredTo = driverTimesheets.map(dT => dT.to);
  const filteredFrom = driverTimesheets.map(dT => dT.from);
  const filteredVehicleReg = driverTimesheets.map(dT => dT.vehicleReg);

  const canSubmit = noEmptyFields && compatibleDates;

  const clearFilters = () => {
    setDriverOption(null);
    setVehicleReg(null);
    setTo(null);
    setFrom(null);
    setDriverReportData({
      ...reportData,
    });
  };

  return (
    <Card elevation={3}>
      <CardHeader
        sx={{
          bgcolor: 'secondary.main',
          color: 'white',
          px: 3,
          py: 2,
        }}
        titleTypographyProps={{ variant: 'h6', fontWeight: 'normal' }}
        title={
          <Grid
            container
            direction='row'
            alignItems='center'
            gap={1}
          >
            Generate report
          </Grid>
        }
      />
      <CardContent sx={{ p: 3 }}>
        <form
          onSubmit={(e: FormEvent) => {
            e.preventDefault();
            handleSubmit(reportData);
          }}
        >
          <FormControl fullWidth>
            <Autocomplete
              options={drivers || []}
              value={driverOption || null}
              onChange={(e, value) => {
                if (value?.id && value.name) {
                  setDriverOption({
                    id: value.id,
                    name: value.name,
                  });
                  setDriverReportData({
                    ...reportData,
                    userId: value.id,
                  });
                }
              }}
              fullWidth
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={option => option.name}
              renderInput={params => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  sx={{ mb: 2 }}
                  margin='dense'
                  variant='standard'
                  label='Driver'
                />
              )}
            />
            <Autocomplete
              options={filteredVehicleReg || []}
              value={vehicleReg || null}
              onChange={(e, value) => {
                setVehicleReg(value);
                setDriverReportData({
                  ...reportData,
                  vehicleReg: value || '',
                });
              }}
              fullWidth
              isOptionEqualToValue={(option, value) => option === value}
              getOptionLabel={option => option}
              renderInput={params => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  sx={{ mb: 2 }}
                  margin='dense'
                  variant='standard'
                  label='Vehicle Register'
                />
              )}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Autocomplete
                options={filteredFrom || []}
                value={from || null}
                onChange={(e, value) => {
                  setFrom(value);
                  setDriverReportData({
                    ...reportData,
                    from: value || '',
                  });
                }}
                fullWidth
                isOptionEqualToValue={(option, value) => option === value}
                getOptionLabel={option => option}
                sx={{ mr: 4, width: '100%' }}
                renderInput={params => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    sx={{ mb: 2 }}
                    margin='dense'
                    variant='standard'
                    label='From'
                  />
                )}
              />
              <Autocomplete
                options={filteredTo || []}
                value={to || null}
                onChange={(e, value) => {
                  setTo(value);
                  setDriverReportData({
                    ...reportData,
                    to: value || '',
                  });
                }}
                fullWidth
                isOptionEqualToValue={(option, value) => option === value}
                getOptionLabel={option => option}
                sx={{ width: '100%' }}
                renderInput={params => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    sx={{ mb: 2 }}
                    margin='dense'
                    variant='standard'
                    label='To'
                  />
                )}
              />
            </Box>
            <Button
              type='button'
              variant='contained'
              onClick={clearFilters}
              sx={{
                maxWidth: '250px',
                mr: 'auto',
                mb: 3,
                boxShadow: 3,
              }}
            >
              Clear filters
            </Button>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <DateField
                label='From'
                value={reportData.startDate}
                onChange={value =>
                  setDriverReportData({
                    ...reportData,
                    startDate: new Date(value!),
                  })
                }
                sx={{ width: '100%', mr: 6 }}
              />
              <DateField
                label='To'
                value={reportData.endDate}
                minDate={reportData.startDate}
                onChange={value =>
                  setDriverReportData({
                    ...reportData,
                    endDate: new Date(value!),
                  })
                }
                sx={{ width: '100%' }}
              />
            </Box>
            <Button
              type='submit'
              variant='contained'
              disabled={!canSubmit}
              sx={{ mt: 3, boxShadow: 3 }}
            >
              Generate
            </Button>
          </FormControl>
        </form>
      </CardContent>
    </Card>
  );
}

export default DriverReportForm;
