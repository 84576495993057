import { useEffect, useState } from 'react';
import api from '../services/api';
import { ServiceProps } from '../types/ServiceProps';

const useServices = ({ unhiddenOnly }: { unhiddenOnly?: boolean }) => {
  const [services, setServices] = useState([] as ServiceProps[]);

  const getAllServices = async () => {
    const response = await api.get('/services');
    if (response.status === 200) {
      const servicesList: ServiceProps[] = response.data.services;

      setServices(
        unhiddenOnly
          ? servicesList.filter(service => service.show)
          : servicesList,
      );
    }
  };

  useEffect(() => {
    getAllServices();
  }, []);

  return {
    services,
    getAllServices,
  };
};
export default useServices;
