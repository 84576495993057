const roundTimeToQuarters = (time: string): string => {
  const timeParts = time.split(':');
  const hours = Number(timeParts[0]);
  const minutes = Number(timeParts[1]);

  // Calculate the closest quarter
  const closestQuarter = (() => {
    if (minutes < 8) return 0;
    if (minutes < 23) return 15;
    if (minutes < 38) return 30;
    if (minutes < 53) return 45;
    return 0;
  })();

  // Determine if the rounding should adjust the hour
  const shouldAdjustHour = minutes > 45 && closestQuarter === 0;
  const adjustedHour = hours === 23 ? 0 : hours + 1;

  // Prepare the final hour and minute values
  const finalHour = shouldAdjustHour ? adjustedHour : hours;
  const finalMinute = closestQuarter;

  // Format the final hour and minute values to ensure two digits
  const formattedHour = finalHour.toString().padStart(2, '0');
  const formattedMinute = finalMinute.toString().padStart(2, '0');

  return `${formattedHour}:${formattedMinute}`;
};

export default roundTimeToQuarters;
