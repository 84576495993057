import React, { useEffect, useState, FormEvent, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'material-react-toastify';
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Grid,
  TextField,
  Box,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import api from '../../../services/api';
import useTeams from '../../../hooks/useTeams';
import regexPatterns from '../../../utils/regexPatterns';
import getErrorMessage, {
  ResponseErrorMessage,
} from '../../../utils/getErrorMessage';
import { InvitedUserProps } from '../../../types/InviteUserProps';
import SelectField from '../../atoms/SelectField';
import { ROLES } from '../../../utils/constants';

interface RegisterFormProps {
  invitedUser?: InvitedUserProps;
}

const roleOptions = ROLES.map(role => ({
  label: `${role.charAt(0).toUpperCase()}${role.slice(1)}`,
  value: role,
}));

function RegisterForm({ invitedUser }: RegisterFormProps) {
  const navigate = useNavigate();

  const [userData, setUserData] = useState({
    username: '',
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    confirmedPassword: '',
    phone: '',
    hourlyPayRate: 0,
  });

  type ObjectKey = keyof typeof userData;

  const { teams } = useTeams();

  const teamOptions = teams.map(team => ({
    label: team.name,
    value: team.id,
  }));

  const [role, setRole] = useState<string>('');
  const [teamId, setTeamId] = useState<number | null>(null);
  const [isDriver, setIsDriver] = useState(false);

  const isOperative = role === 'operative';

  const inputs = [
    /* {
      name: 'username',
      type: 'text',
      label: 'Username',
      message: "Username shouldn't include any special character!",
      pattern: regexPatterns.alphanumericAcceptEmpty,
      sx: { mb: 2 },
    }, */
    {
      name: 'firstName',
      type: 'text',
      label: 'First Name',
      message: "First Name shouldn't include any special character!",
      pattern: regexPatterns.alphanumeric,
      sx: { mb: 2 },
    },
    {
      name: 'lastName',
      type: 'text',
      label: 'Last Name',
      message: "Last Name shouldn't include any special character!",
      pattern: regexPatterns.alphanumeric,
      sx: { mb: 2 },
    },
    {
      name: 'email',
      type: 'email',
      label: 'Email',
      message: 'Invalid email address!',
      pattern: regexPatterns.email,
      disabled: !!invitedUser,
      sx: { mb: 2, gridColumn: '1 / span 2' },
    },
    ...(!isOperative
      ? [
          {
            name: 'password',
            type: 'password',
            label: 'Password',
            message: 'Password must be 6-20 characters long',
            pattern: regexPatterns.password,
            sx: { mb: 2 },
          },
          {
            name: 'confirmedPassword',
            type: 'password',
            label: 'Confirm password',
            message: 'Password must be 6-20 characters long',
            pattern: regexPatterns.password,
            sx: { mb: 2 },
          },
          {
            name: 'phone',
            type: 'text',
            label: 'Phone',
            message: 'Invalid phone!',
            pattern: regexPatterns.phone,
          },
        ]
      : []),
    {
      name: 'hourlyPayRate',
      type: 'number',
      label: 'Hourly pay rate',
      step: '0.1',
      pattern: regexPatterns.numeric,
      sx: { mb: 2 },
    },
  ];

  const unequalPasswords = userData.password !== userData.confirmedPassword;

  const isFormInvalid = inputs.some(input => {
    const inputName = input.name as ObjectKey;
    const inputPattern = userData[inputName]
      ? userData[inputName]!.toString()
      : '';

    return (
      !input.pattern.test(inputPattern) || (!isOperative && unequalPasswords)
    );
  });

  useEffect(() => {
    if (invitedUser) {
      setUserData({ ...userData, email: invitedUser.email });
      setRole(invitedUser.role);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invitedUser]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const url = invitedUser
      ? `/user/${invitedUser.hashLink}/create`
      : '/register';

    const data = {
      ...userData,
      hourlyPayRate: parseFloat(
        userData.hourlyPayRate.toString().replace(',', '.'),
      ),
      role,
      teamId,
      isDriver,
    };

    try {
      const response = await api.post(url, data);
      if (response.status === 200) {
        toast.success('User registered sucessfully!');
        if (invitedUser) {
          navigate('/login');
          return;
        }
        navigate('/dashboard/users');
      }
    } catch (error) {
      toast.dismiss();
      toast.error(getErrorMessage(error as ResponseErrorMessage));
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const getInputs = () => (
    <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', columnGap: 5 }}>
      {inputs.map(input => {
        const inputName = input.name as ObjectKey;
        const inputValue = userData[inputName]
          ? userData[inputName]!.toString()
          : '';
        const hasError =
          !!userData[inputName] && !input.pattern.test(inputValue);
        const passwordFields =
          input.name === 'password' || input.name === 'confirmedPassword';
        const unequalPasswordsError =
          passwordFields && unequalPasswords ? 'Passwords do not match!' : '';
        const errorMessage = hasError ? input.message : unequalPasswordsError;

        return (
          <TextField
            key={input.name}
            value={userData[inputName] || ''}
            size='small'
            variant='standard'
            fullWidth
            onChange={onChange}
            error={hasError || !!unequalPasswordsError}
            helperText={errorMessage}
            inputProps={input.type === 'number' ? { step: input.step } : {}}
            {...input}
          />
        );
      })}
      <FormControlLabel
        sx={{ minWidth: 'fit-content', gridColumn: '1 / span 2' }}
        control={
          <Checkbox
            checked={isDriver}
            name='show'
            onClick={() => setIsDriver(!isDriver)}
          />
        }
        label='Driver'
      />
    </Box>
  );

  return (
    <Card elevation={3}>
      <CardHeader
        sx={{
          bgcolor: 'secondary.main',
          color: 'white',
          px: 3,
          py: 2,
        }}
        titleTypographyProps={{ variant: 'h6', fontWeight: 'normal' }}
        title={
          <Grid
            container
            direction='row'
            alignItems='center'
            gap={1}
          >
            <PersonAddIcon />
            Register
          </Grid>
        }
      />
      <CardContent sx={{ p: 3 }}>
        <form onSubmit={handleSubmit}>
          <SelectField
            label='Role'
            sx={{ mb: 2 }}
            value={role}
            disabled={!!invitedUser}
            onChange={e => {
              const selectedRole = e.target.value;
              setRole(selectedRole as string);

              if (selectedRole === 'operative') {
                setUserData({
                  ...userData,
                  password: '',
                  confirmedPassword: '',
                  email: '',
                  phone: '',
                });
              }
            }}
            options={roleOptions}
          />
          {!!role && getInputs()}
          {isOperative && !invitedUser && (
            <SelectField
              label='Team'
              sx={{ mt: 2 }}
              displayEmpty
              defaultValue=''
              value={teamId || ''}
              onChange={e => setTeamId(parseInt(e.target.value as string, 10))}
              options={teamOptions}
            />
          )}
          <Button
            type='submit'
            variant='contained'
            sx={{ mt: 3, bgcolor: 'secondary.main', boxShadow: 3 }}
            disabled={isFormInvalid}
            fullWidth
          >
            Submit
          </Button>
        </form>
      </CardContent>
    </Card>
  );
}

export default RegisterForm;
