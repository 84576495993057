import {
  createContext,
  useState,
  useContext,
  useMemo,
  ReactElement,
  useEffect,
} from 'react';
import { checkSubscription } from '../utils/subscription';

interface UserProps {
  id: number;
  role: string;
  username: string;
  isActive: boolean;
  firstName: string;
  lastName: string;
  teamId: number | null;
}

interface UserContextProps {
  user?: UserProps | null;
  setUser: Function;
}

interface UserProviderProps {
  children: ReactElement;
}

const UserContext = createContext({} as UserContextProps);

function UserProvider({ children }: UserProviderProps) {
  const [user, setUser] = useState(null as UserProps | null);

  useEffect(() => {
    checkSubscription();
  }, []);

  const values = useMemo(
    () => ({
      user,
      setUser,
    }),
    [user, setUser],
  );
  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
}

export const useUser = () => useContext(UserContext);
export default UserProvider;
