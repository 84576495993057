import React from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardTitle from '../../../../components/atoms/DashboardTitle';
import api from '../../../../services/api';
import { ReportFilterProps } from '../../../../types/ReportFilterProps';
import { DriversPayrollReportProps } from '../../../../types/DriversPayrollReportProps';
import DriverReportForm from '../../../../components/molecules/DriverReportForm';

function DashboardDriversPayrollReport() {
  const navigate = useNavigate();

  const handleSubmit = async (reportData: ReportFilterProps) => {
    const response = await api.post('/driverPayrollReports', reportData);

    if (response.status === 200) {
      const { driverPayrollReports } = response.data;
      navigate('/dashboard/driversPayrollReport/details', {
        state: driverPayrollReports as DriversPayrollReportProps[],
      });
    }
  };

  return (
    <>
      <DashboardTitle
        title="Drivers' Payroll Report"
        hasBackButton
      />
      <DriverReportForm handleSubmit={handleSubmit} />
    </>
  );
}

export default DashboardDriversPayrollReport;
