import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import DashboardTitle from '../../../../components/atoms/DashboardTitle';
import TeamCard from '../../../../components/molecules/TeamCard';
import VisitTasksTable from '../../../../components/molecules/VisitTasksTable';
import api from '../../../../services/api';
import { VisitProps, VisitStatus } from '../../../../types/VisitProps';
import convertDecimalToTime from '../../../../utils/convertDecimalToTime';
import formatDate from '../../../../utils/formatDate';

const initialValues: VisitProps = {
  id: 0,
  status: VisitStatus.PROPOSED,
  startDate: new Date(),
  finishDate: new Date(),
  buildingId: 0,
};

function VisitDetails() {
  const { id } = useParams();
  const [visit, setVisit] = useState<VisitProps>(initialValues);
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

  const getVisitById = async (visitId: number) => {
    const response = await api.get(`/visit/${visitId}`);
    if (response.status === 200) {
      setVisit(response.data.visit);
    }
  };

  useEffect(() => {
    if (id) {
      getVisitById(parseInt(id, 10));
    }
  }, [id]);

  const totalUnits =
    visit.visitTasks?.reduce(
      (accumulator, object) => Number(accumulator) + (object.units || 0),
      0,
    ) || 0;

  const unassignedStatus =
    VisitStatus.POTENTIAL || VisitStatus.PROPOSED || VisitStatus.ACCEPTED;

  const remainingUnits = visit.completedUnits
    ? Number(totalUnits) - visit.completedUnits
    : totalUnits || '0';

  const informationsToDisplay = [
    {
      label: 'Building',
      value: visit.building?.name,
    },
    {
      label: 'Client',
      value: visit.building?.client?.reference,
    },
    {
      label: 'Billing',
      value: visit.building?.billing?.companyName,
    },
    {
      label: 'Service',
      value: visit.serviceName,
    },
    {
      label: 'Start date',
      value: visit.startDate ? formatDate(visit.startDate) : '',
    },
    {
      label: 'Finish date',
      value: visit.finishDate ? formatDate(visit.finishDate) : '',
    },
    {
      label: 'Estimated time',
      value: visit.estimatedTime
        ? convertDecimalToTime(visit.estimatedTime)
        : '',
      availableFor: [
        VisitStatus.POTENTIAL,
        VisitStatus.PROPOSED,
        VisitStatus.ACCEPTED,
      ],
    },
    {
      label: 'Required staff',
      value: visit.requiredStaff,
      availableFor: [
        VisitStatus.POTENTIAL,
        VisitStatus.PROPOSED,
        VisitStatus.ACCEPTED,
      ],
    },
    {
      label: 'Remaining units',
      value: remainingUnits || '0',
      availableFor: [VisitStatus.PROGRESS],
    },
    {
      label: 'Completed units',
      value: visit.completedUnits || '0',
      availableFor: [VisitStatus.PROGRESS, VisitStatus.COMPLETED],
    },
    {
      label: 'Number of units not finished',
      value: remainingUnits,
      availableFor: [VisitStatus.SUBMITTED],
    },
    {
      label: 'Who Completed',
      value: visit.whoCompleted,
      availableFor: [VisitStatus.COMPLETED],
    },
    {
      label: 'Status',
      value: visit.status,
    },
  ];

  const filteredInformation = informationsToDisplay.filter(info =>
    info.availableFor ? info.availableFor.includes(visit.status) : info,
  );

  return (
    <>
      <DashboardTitle
        title='Visit Details'
        hasBackButton
      />
      <Card sx={{ mb: 3 }}>
        <CardHeader
          title={`Visit ${visit.id}`}
          subheader={`Here you can see all details about the Visit from date ${formatDate(
            visit.startDate,
          )}`}
        />
        <CardContent sx={{ display: isMobile ? 'block' : 'flex' }}>
          <Box sx={{ width: '100%' }}>
            {filteredInformation.map(visitInfo => (
              <div key={visitInfo.label}>
                <Typography
                  variant='h5'
                  mt={2}
                  display='flex'
                  alignItems='center'
                >
                  {`${visitInfo.label}: `}
                  <Typography
                    variant='h6'
                    fontWeight={300}
                    sx={{ ml: 1 }}
                  >
                    {`${visitInfo.value || ''}`}
                  </Typography>
                </Typography>
                <Divider />
              </div>
            ))}
            <Typography
              variant='h6'
              mt={2}
            >
              Notes
              <Typography
                variant='subtitle1'
                fontWeight={200}
                sx={{ width: '100%', overflowWrap: 'break-word' }}
              >
                {visit.notes || 'No notes added!'}
              </Typography>
            </Typography>
          </Box>
          {visit.status !== unassignedStatus && visit.team && (
            <Box sx={isMobile ? { mt: 2 } : { ml: 4, mr: 2 }}>
              <TeamCard team={visit.team} />
            </Box>
          )}
        </CardContent>
      </Card>
      <Typography
        variant='h5'
        sx={{ mb: 2 }}
      >
        Tasks
      </Typography>
      {visit.visitTasks && <VisitTasksTable visitTasks={visit.visitTasks} />}
    </>
  );
}

export default VisitDetails;
