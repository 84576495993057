import { Box, useMediaQuery, useTheme } from '@mui/material';

import { useEffect, useState, useMemo } from 'react';
import useUsers from '../../../hooks/useUsers';
import { VisitProps } from '../../../types/VisitProps';
import { TimesheetReportProps } from '../../../types/TimesheetReportProps';
import TimesheetReportDesktopForm from '../../atoms/TimesheetReportDesktopForm';
import TimesheetReportMobileForm from '../../atoms/TimesheetReportMobileForm';
import { PayrollReportProps } from '../../../types/PayrollReportProps';

interface TimesheetReportFormProps {
  visit: VisitProps;
  handleTimesheetReports: Function;
  // eslint-disable-next-line
  handlePayrollReports?: (timesheetReports: PayrollReportProps[]) => void;
}

const resetValues = {
  startTime: '',
  finishTime: '',
  hours: 0,
  breakHours: 0,
  breakHoursFormatted: '',
  total: 0,
};

function TimesheetReportForm({
  visit,
  handleTimesheetReports,
  handlePayrollReports,
}: TimesheetReportFormProps) {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  const { users } = useUsers();
  const operatives = useMemo(
    () =>
      users
        .filter(user => user.teamId === visit.teamId)
        .sort((a, b) => Number(a?.id) - Number(b?.id)),
    [users, visit.teamId],
  );

  const [globalDayOff, setGlobalDayOff] = useState(false);

  const [timesheetReports, setTimesheetReports] = useState<
    TimesheetReportProps[]
  >([]);

  useEffect(() => {
    const filteredTimesheetReports = operatives.map(operative => ({
      id: 0,
      ...resetValues,
      timesheetId: 0,
      dayOff: false,
      userId: operative.id!,
      user: operative,
    }));

    setTimesheetReports(filteredTimesheetReports);
  }, [operatives]);

  const updatePayrollReport = () => {
    if (handlePayrollReports) {
      const payrollReports: PayrollReportProps[] = timesheetReports.map(
        timesheetReport => ({
          refenceNumber: Number(timesheetReport.user?.referenceNumber || 0),
          payrate: timesheetReport.user?.hourlyPayRate || 0,
          totalHours: timesheetReport.hours,
        }),
      );

      handlePayrollReports(payrollReports);
    }
  };

  const updateTimesheetReport = (
    currentTimesheetReport: TimesheetReportProps,
  ) => {
    const updatedTimesheetReports = timesheetReports.map(current =>
      current.userId === currentTimesheetReport.userId
        ? currentTimesheetReport
        : current,
    );

    updatePayrollReport();

    setTimesheetReports(updatedTimesheetReports);
    handleTimesheetReports(updatedTimesheetReports);
  };

  const setDayOffForAllTimesheetReports = () => {
    setGlobalDayOff(!globalDayOff);

    const updatedTimesheetReports = timesheetReports.map(current => ({
      ...current,
      ...resetValues,
      dayOff: !globalDayOff,
    }));

    setTimesheetReports(updatedTimesheetReports);
    handleTimesheetReports(updatedTimesheetReports);
  };

  const copyToAllTimesheetReports = (timesheetReport: TimesheetReportProps) => {
    const updatedTimesheetReports = timesheetReports.map(current => ({
      ...timesheetReport,
      total: Number(
        (timesheetReport.hours * current.user!.hourlyPayRate).toFixed(1),
      ),
      userId: current.userId,
      user: current.user,
    }));

    setTimesheetReports(updatedTimesheetReports);
    handleTimesheetReports(updatedTimesheetReports);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      {isMobile ? (
        <TimesheetReportMobileForm
          operatives={operatives}
          timesheetReports={timesheetReports}
          updateTimesheetReport={updateTimesheetReport}
          setGlobalDayOff={setGlobalDayOff}
        />
      ) : (
        <TimesheetReportDesktopForm
          timesheetReports={timesheetReports}
          updateTimesheetReport={updateTimesheetReport}
          setDayOffForAllTimesheetReports={setDayOffForAllTimesheetReports}
          copyToAllTimesheetReports={copyToAllTimesheetReports}
          globalDayOff={globalDayOff}
          setGlobalDayOff={setGlobalDayOff}
        />
      )}
    </Box>
  );
}

export default TimesheetReportForm;
