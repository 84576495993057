import { ComponentProps } from 'react';
import { DataGrid, DataGridProps } from '@mui/x-data-grid';
import CustomTableToolbar from '../CustomTableToolbar';

interface CustomDataGridProps extends ComponentProps<typeof DataGrid> {
  sortModel?: DataGridProps['sortModel'];
}

function DashboardTable({ sortModel, ...props }: CustomDataGridProps) {
  return (
    <DataGrid
      initialState={{
        sorting: {
          sortModel: sortModel || [{ field: 'id', sort: 'desc' }],
        },
        pagination: {
          paginationModel: { pageSize: 25 },
        },
      }}
      pageSizeOptions={[25, 50, 100]}
      disableColumnMenu
      showCellVerticalBorder
      showColumnVerticalBorder
      disableColumnSelector
      disableDensitySelector
      autoHeight
      autoPageSize={false}
      checkboxSelection={false}
      sx={{
        boxShadow: 3,
        '& .MuiDataGrid-actionsCell': {
          gridGap: 0,
        },
        '& .MuiDataGrid-sortIcon': {
          color: 'white',
        },
        '& .MuiDataGrid-columnSeparator': {
          color: 'white',
          ':hover': {
            color: 'white',
          },
        },
      }}
      slots={{
        toolbar: CustomTableToolbar as DataGridProps['slots'] as any,
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    />
  );
}

export default DashboardTable;
