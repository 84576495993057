import {
  Box,
  Chip,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
} from '@mui/material';
import { ReactNode } from 'react';

interface ItemsListProps {
  items: string[];
  title: string;
  icon: ReactNode;
  onClickItem?: Function;
}

export default function ItemsList({
  items,
  title,
  icon,
  onClickItem,
}: ItemsListProps) {
  return (
    <Box sx={{ width: '100%', px: 2 }}>
      {icon}
      <List
        sx={{
          width: '100%',
          bgcolor: 'background.paper',
          overflow: 'auto',
          maxHeight: 250,
          '& ul': { padding: 0 },
        }}
        subheader={
          <ListSubheader
            component='div'
            id='nested-list-subheader'
          >
            {title}
          </ListSubheader>
        }
      >
        {items.map(item => (
          <ListItem key={`item-${item}`}>
            <ListItemText
              primary={
                <Chip
                  label={item}
                  onClick={() => onClickItem && onClickItem(item)}
                />
              }
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}
