import {
  Autocomplete,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { toast } from 'material-react-toastify';
import { useState } from 'react';
import { useDialog } from '../../../contexts/DialogContext';
import api from '../../../services/api';
import isEmpty from '../../../utils/hasEmptyProperties';
import { BuildingProps } from '../../../types/BuildingProps';
import TimeField from '../../atoms/TimeField';
import { EntityTaskProps } from '../../../types/EntityTaskProps';
import { BuildingServiceOptionProps } from '../../../types/BuildingServicesProps';

interface BuildingTaskDialogProps {
  building: BuildingProps;
  refreshBuilding: Function;
}

const initialValues: EntityTaskProps = {
  id: 0,
  name: '',
  price: 0,
  time: 0,
};

export default function BuildingTaskDialog({
  building,
  refreshBuilding,
}: BuildingTaskDialogProps) {
  const { toggleDialog } = useDialog();
  const [buildingTaskData, setBuildingTaskData] = useState(initialValues);
  const [buildingServiceOption, setBuildingServiceOption] =
    useState<BuildingServiceOptionProps | null>(null);

  const canSubmit = isEmpty([
    buildingServiceOption?.id,
    buildingTaskData?.name,
    buildingTaskData?.price,
    buildingTaskData?.time,
  ]);

  const handleSubmit = async () => {
    const data = {
      buildingServiceId: buildingServiceOption
        ? buildingServiceOption.id
        : null,
      ...buildingTaskData,
    };

    const response = await api.post('/buildingTask', data);

    if (response.status === 200) {
      toast.success('Task added successfully!');
      refreshBuilding();
    }

    toggleDialog();
  };

  return (
    <>
      <DialogTitle>Add service task</DialogTitle>
      <DialogContent>
        <Autocomplete
          sx={{ my: 2 }}
          options={building.buildingServices || []}
          value={buildingServiceOption}
          onChange={(e, value) => {
            if (value?.id && value.serviceName) {
              setBuildingServiceOption({
                id: value.id,
                serviceId: value.serviceId,
                serviceName: value.serviceName,
              });
            } else {
              setBuildingServiceOption(null);
            }
          }}
          getOptionLabel={option => option.serviceName || ''}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          style={{ width: 400 }}
          renderInput={params => (
            <TextField
              {...params}
              label='Service'
              placeholder='Service'
            />
          )}
        />
        <TextField
          label='Name'
          value={buildingTaskData.name}
          variant='standard'
          sx={{ mb: 2 }}
          fullWidth
          onChange={e =>
            setBuildingTaskData({
              ...buildingTaskData,
              name: e.target.value,
            })
          }
        />
        <TextField
          label='Price'
          value={buildingTaskData.price || ''}
          variant='standard'
          type='number'
          sx={{ mb: 2 }}
          fullWidth
          onChange={e =>
            setBuildingTaskData({
              ...buildingTaskData,
              price: parseFloat(e.target.value),
            })
          }
        />
        <TimeField
          label='Execution Time'
          value={buildingTaskData.time}
          sx={{ width: '100%', mb: -0.5 }}
          onChange={e =>
            setBuildingTaskData({
              ...buildingTaskData,
              time: Number(parseFloat(e.target.value).toFixed(2)),
            })
          }
        />
      </DialogContent>
      <DialogActions>
        <Button
          type='button'
          sx={{ color: 'error.main' }}
          onClick={() => toggleDialog()}
        >
          Cancel
        </Button>
        <Button
          onClick={() => handleSubmit()}
          disabled={!canSubmit}
        >
          Save
        </Button>
      </DialogActions>
    </>
  );
}
