import {
  Checkbox,
  FormControlLabel,
  TextField,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Table,
} from '@mui/material';
import { FileCopy } from '@mui/icons-material';

import { TimesheetReportProps } from '../../../types/TimesheetReportProps';
import getTimeBetweenTimeStrings from '../../../utils/getTimeBetweenTimeStrings';
import roundTimeToQuarters from '../../../utils/roundTimeToQuarters';
import convertDecimalToTime from '../../../utils/convertDecimalToTime';
import getMinimumBreakHours from '../../../utils/getMinimumHoursBreak';

interface TimesheetReportDesktopFormProps {
  timesheetReports: TimesheetReportProps[];
  updateTimesheetReport: Function;
  setDayOffForAllTimesheetReports: Function;
  copyToAllTimesheetReports: Function;
  globalDayOff: boolean;
  setGlobalDayOff: Function;
}

function TimesheetReportDesktopForm({
  timesheetReports,
  updateTimesheetReport,
  setDayOffForAllTimesheetReports,
  copyToAllTimesheetReports,
  globalDayOff,
  setGlobalDayOff,
}: TimesheetReportDesktopFormProps) {
  const resetValues = {
    startTime: '',
    finishTime: '',
    hours: 0,
    breakHours: 0,
    breakHoursFormatted: '',
    total: 0,
  };

  const updateStartTime = (
    timesheetReport: TimesheetReportProps,
    value: string,
  ) => {
    // calculate hours
    const { decimal: hours } = getTimeBetweenTimeStrings(
      value,
      timesheetReport.finishTime,
    );
    const breakHours = getMinimumBreakHours(hours);
    const total = Number(hours.toFixed(1)) - breakHours;

    updateTimesheetReport({
      ...timesheetReport,
      startTime: value,
      hours,
      breakHours,
      breakHoursFormatted: breakHours.toString().replace(/,/g, '.'),
      total,
    });
  };

  const updateEndTime = (
    timesheetReport: TimesheetReportProps,
    value: string,
  ) => {
    const { decimal: hours } = getTimeBetweenTimeStrings(
      timesheetReport.startTime,
      value,
    );
    const breakHours = getMinimumBreakHours(hours);
    const total = Number(hours.toFixed(1)) - breakHours;

    updateTimesheetReport({
      ...timesheetReport,
      finishTime: value,
      hours,
      breakHours,
      breakHoursFormatted: breakHours.toString().replace(/,/g, '.'),
      total,
    });
  };

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ borderBottom: 'none' }}>
              Operative/Supervisor
            </TableCell>
            <TableCell
              align='left'
              style={{ borderBottom: 'none' }}
            >
              Start time
            </TableCell>
            <TableCell
              align='left'
              style={{ borderBottom: 'none' }}
            >
              Finish time
            </TableCell>
            <TableCell
              align='left'
              style={{ borderBottom: 'none' }}
            >
              Hours
            </TableCell>
            <TableCell
              align='left'
              style={{ borderBottom: 'none' }}
            >
              Break
            </TableCell>
            <TableCell
              align='left'
              style={{ borderBottom: 'none' }}
            >
              Total
            </TableCell>
            <TableCell
              align='left'
              style={{ borderBottom: 'none' }}
            >
              <FormControlLabel
                sx={{ minWidth: 'fit-content', mb: -0.5 }}
                control={
                  <Checkbox
                    name='show'
                    checked={globalDayOff}
                    onClick={() => setDayOffForAllTimesheetReports()}
                  />
                }
                label='Day off'
              />
            </TableCell>
            <TableCell
              align='left'
              style={{ borderBottom: 'none' }}
            />
          </TableRow>
        </TableHead>
        <TableBody>
          {timesheetReports &&
            timesheetReports
              .sort((a, b) => Number(a?.userId) - Number(b?.userId))
              .map(timesheetReport => {
                const { user } = timesheetReport;
                const nameToDisplay = `${user?.referenceNumber} - ${user?.firstName} ${user?.lastName}`;
                const isTimeFilled =
                  timesheetReport.startTime && timesheetReport.finishTime;

                return (
                  <TableRow>
                    <TableCell
                      style={{
                        borderBottom: 'none',
                        minWidth: '120px',
                      }}
                    >
                      {nameToDisplay}
                      {timesheetReport.user?.role === 'supervisor' &&
                        ' (Supervisor)'}
                    </TableCell>
                    <TableCell style={{ borderBottom: 'none' }}>
                      <TextField
                        type='time'
                        variant='standard'
                        fullWidth
                        size='small'
                        disabled={timesheetReport.dayOff}
                        value={timesheetReport.startTime || ''}
                        onChange={e => {
                          const { value } = e.target;
                          updateStartTime(timesheetReport, value);
                        }}
                        onBlur={e => {
                          const { value } = e.target;
                          const formattedValue = roundTimeToQuarters(value);
                          updateStartTime(timesheetReport, formattedValue);
                        }}
                      />
                    </TableCell>
                    <TableCell style={{ borderBottom: 'none' }}>
                      <TextField
                        type='time'
                        variant='standard'
                        fullWidth
                        size='small'
                        value={timesheetReport.finishTime || ''}
                        disabled={timesheetReport.dayOff}
                        onChange={e => {
                          const { value } = e.target;
                          updateEndTime(timesheetReport, value);
                        }}
                        onBlur={e => {
                          const { value } = e.target;
                          const formattedValue = roundTimeToQuarters(value);
                          updateEndTime(timesheetReport, formattedValue);
                        }}
                      />
                    </TableCell>
                    <TableCell style={{ borderBottom: 'none' }}>
                      <TextField
                        type='text'
                        disabled
                        variant='standard'
                        fullWidth
                        size='small'
                        value={
                          isTimeFilled &&
                          convertDecimalToTime(timesheetReport.hours)
                        }
                      />
                    </TableCell>
                    <TableCell style={{ borderBottom: 'none' }}>
                      <TextField
                        type='text'
                        value={timesheetReport.breakHoursFormatted}
                        disabled
                        variant='standard'
                        fullWidth
                        size='small'
                      />
                    </TableCell>
                    <TableCell style={{ borderBottom: 'none' }}>
                      <TextField
                        type='text'
                        value={isTimeFilled && timesheetReport.total}
                        disabled
                        variant='standard'
                        fullWidth
                        size='small'
                      />
                    </TableCell>
                    <TableCell
                      style={{
                        borderBottom: 'none',
                        minWidth: '131px',
                      }}
                    >
                      <FormControlLabel
                        sx={{ minWidth: 'fit-content', mb: -1.5 }}
                        control={
                          <Checkbox
                            checked={timesheetReport.dayOff}
                            name='show'
                            onClick={() => {
                              if (!timesheetReport.dayOff === false)
                                setGlobalDayOff(false);

                              updateTimesheetReport({
                                ...timesheetReport,
                                ...resetValues,
                                dayOff: !timesheetReport.dayOff,
                              });
                            }}
                          />
                        }
                        label='Day off'
                      />
                    </TableCell>
                    <TableCell style={{ borderBottom: 'none' }}>
                      <IconButton
                        color='primary'
                        aria-label='Copy this timesheet to all operatives'
                        title='Copy this timesheet to all operatives'
                        component='label'
                        sx={{ mb: -1 }}
                        onClick={() =>
                          copyToAllTimesheetReports(timesheetReport)
                        }
                      >
                        <FileCopy />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default TimesheetReportDesktopForm;
