import DashboardTitle from '../../../components/atoms/DashboardTitle';
import QuotesTable from '../../../components/molecules/QuotesTable';
import useQuotes from '../../../hooks/useQuotes';

function Contracts() {
  const { quotes, getAllquotes, deleteQuote, updateQuote } = useQuotes({
    onlyContracts: true,
  });

  return (
    <>
      <DashboardTitle title='All Contracts' />

      <QuotesTable
        quotes={quotes}
        getAllquotes={getAllquotes}
        deleteQuote={deleteQuote}
        updateQuote={updateQuote}
        onlyContracts
      />
    </>
  );
}

export default Contracts;
