import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DashboardTitle from '../../../components/atoms/DashboardTitle';
import BuildingDetailsDialog from '../../../components/molecules/BuildingDetailsDialog';
import { useDialog } from '../../../contexts/DialogContext';
import BuildingsTable from '../../../components/molecules/BuildingsTable';
import useBuildings from '../../../hooks/useBuildings';

function DashboardBuildings() {
  const { showDialog } = useDialog();
  const { getAllBuildings, buildings } = useBuildings();

  return (
    <>
      <DashboardTitle title='Buildings' />
      <Button
        variant='contained'
        endIcon={<AddIcon />}
        sx={{ my: 2 }}
        onClick={() =>
          showDialog({
            componentToRender: (
              <BuildingDetailsDialog getAllBuildings={getAllBuildings} />
            ),
          })
        }
      >
        Register Building
      </Button>
      <BuildingsTable
        buildings={buildings}
        getAllBuildings={getAllBuildings}
      />
    </>
  );
}

export default DashboardBuildings;
