import { Button, SxProps, Theme } from '@mui/material';
import { JSXElementConstructor, ReactElement, ReactNode } from 'react';
import { useDialog } from '../../../contexts/DialogContext';

interface ButtonDialogProps {
  component: ReactElement<any, string | JSXElementConstructor<any>>;
  label: string;
  sx?: SxProps<Theme> | undefined;
  endIcon?: ReactNode;
}

export default function ButtonDialog({
  component,
  label,
  sx,
  endIcon,
}: ButtonDialogProps) {
  const { showDialog } = useDialog();
  return (
    <Button
      variant='contained'
      onClick={() =>
        showDialog({
          componentToRender: component,
        })
      }
      sx={sx}
      endIcon={endIcon}
    >
      {label}
    </Button>
  );
}
