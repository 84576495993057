import React, { useState, FormEvent } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Grid,
  FormControl,
  Box,
  TextField,
  Autocomplete,
} from '@mui/material';
import { toast } from 'material-react-toastify';
import isEmpty from '../../../utils/hasEmptyProperties';
import { ClientOptionProps } from '../../../types/ClientProps';
import DateField from '../../atoms/DateField';
import { BuildingOptionProps } from '../../../types/BuildingProps';
import { TeamOptionProps } from '../../../types/TeamProps';
import { UserOptionProps } from '../../../types/UserProps';
import useClients from '../../../hooks/useClients';
import useBuildings from '../../../hooks/useBuildings';
import useTeams from '../../../hooks/useTeams';
import useUsers from '../../../hooks/useUsers';
import { ReportFilterProps } from '../../../types/ReportFilterProps';
import useJobBriefs from '../../../hooks/useJobBriefs';
import useQuotes from '../../../hooks/useQuotes';

const initialValues: ReportFilterProps = {
  startDate: new Date(),
  endDate: new Date(),
  contractId: 0,
  clientId: 0,
  jobBriefId: 0,
  teamId: 0,
  supervisorId: 0,
  buildingId: 0,
  serviceId: 0,
  unitType: '',
};

interface ReportFormProps {
  handleSubmit: Function;
  requiredFields?: (
    | 'client'
    | 'building'
    | 'team'
    | 'supervisor'
    | 'contract'
    | 'jobBrief'
  )[];
}

function ReportForm({ handleSubmit, requiredFields }: ReportFormProps) {
  const [reportData, setReportData] =
    useState<ReportFilterProps>(initialValues);

  const noEmptyFields = isEmpty([reportData?.startDate, reportData?.endDate]);
  //  ok
  const { clients } = useClients();
  const { buildings } = useBuildings();
  const { teams } = useTeams();
  const { users } = useUsers();
  const { quotes } = useQuotes({ onlyContracts: true });
  const { jobBriefs } = useJobBriefs();

  const [clientOption, setClientOption] = useState<ClientOptionProps | null>();
  const [buildingOption, setBuildingOption] =
    useState<BuildingOptionProps | null>();
  const [teamOption, setTeamOption] = useState<TeamOptionProps | null>();
  const [supervisorOption, setSupervisorOption] =
    useState<UserOptionProps | null>();
  const [contractOption, setContractOption] = useState<{ id: number } | null>();
  useState<UserOptionProps | null>();
  const [jobBriefOption, setJobBriefOption] = useState<{ id: number } | null>();

  const filteredBuildings = buildings.filter(building =>
    clientOption ? building.clientId === clientOption.id : building,
  );
  const supervisors = users.filter(
    user => user.role === 'supervisor' || user.role === 'contractor',
  );
  const filteredSupervisors = supervisors.filter(supervisor =>
    teamOption ? supervisor.teamId === teamOption.id : supervisor,
  );

  const filteredJobBriefs = jobBriefs.filter(jobBrief =>
    contractOption ? jobBrief.quoteId === contractOption.id : jobBrief,
  );

  const compatibleDates = reportData.startDate! <= reportData.endDate!;

  const canSubmit = noEmptyFields && compatibleDates;

  const clearFilters = () => {
    setClientOption(null);
    setBuildingOption(null);
    setTeamOption(null);
    setSupervisorOption(null);
    setContractOption(null);
    setJobBriefOption(null);
    setReportData({
      ...reportData,
      clientId: 0,
      buildingId: 0,
      teamId: 0,
      supervisorId: 0,
      jobBriefId: 0,
      contractId: 0,
    });
  };

  return (
    <Card elevation={3}>
      <CardHeader
        sx={{
          bgcolor: 'secondary.main',
          color: 'white',
          px: 3,
          py: 2,
        }}
        titleTypographyProps={{ variant: 'h6', fontWeight: 'normal' }}
        title={
          <Grid
            container
            direction='row'
            alignItems='center'
            gap={1}
          >
            Generate report
          </Grid>
        }
      />
      <CardContent sx={{ p: 3 }}>
        <form
          onSubmit={async (e: FormEvent) => {
            e.preventDefault();

            const fieldsStatus = (requiredFields || []).map(field => ({
              label: field,
              notFilled:
                reportData[`${field}Id`] === initialValues[`${field}Id`],
            }));

            const allRequiredFieldsAreFilled =
              requiredFields &&
              requiredFields.length > 0 &&
              fieldsStatus.some(field => field.notFilled);

            if (allRequiredFieldsAreFilled)
              toast.error(
                `${
                  fieldsStatus.find(f => f.notFilled)?.label
                } is a required field`,
              );
            else await handleSubmit(reportData);
          }}
        >
          <FormControl fullWidth>
            <Autocomplete
              options={clients || []}
              value={clientOption || null}
              onChange={(e, value) => {
                if (value?.id && value.reference) {
                  setBuildingOption(null);
                  setClientOption({
                    id: value.id,
                    reference: value.reference,
                  });
                  setReportData({
                    ...reportData,
                    clientId: value.id,
                  });
                }
              }}
              fullWidth
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={option => option.reference}
              renderInput={params => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  sx={{ mb: 2 }}
                  margin='dense'
                  variant='standard'
                  label='Client'
                />
              )}
            />
            <Autocomplete
              options={filteredBuildings || []}
              value={buildingOption || null}
              onChange={(e, value) => {
                if (value?.id && value.name) {
                  setBuildingOption({
                    id: value.id,
                    name: value.name,
                  });
                  setReportData({
                    ...reportData,
                    buildingId: value.id,
                  });
                }
              }}
              fullWidth
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={option => option.name}
              renderInput={params => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  sx={{ mb: 2 }}
                  margin='dense'
                  variant='standard'
                  label='Building'
                />
              )}
            />
            <Autocomplete
              options={(teams as TeamOptionProps[]) || []}
              value={teamOption || null}
              onChange={(e, value) => {
                if (value?.id && value.name) {
                  setSupervisorOption(null);
                  setTeamOption({
                    id: value.id,
                    name: value.name,
                  });
                  setReportData({
                    ...reportData,
                    teamId: value.id,
                  });
                }
              }}
              fullWidth
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={option => option.name}
              renderInput={params => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  sx={{ mb: 2 }}
                  margin='dense'
                  variant='standard'
                  label='Team'
                />
              )}
            />
            <Autocomplete
              options={filteredSupervisors || []}
              value={supervisorOption || null}
              onChange={(e, value) => {
                if (value?.id && value.firstName) {
                  setSupervisorOption({
                    id: value.id,
                    firstName: value.firstName,
                  });
                  setReportData({
                    ...reportData,
                    supervisorId: value.id,
                  });
                }
              }}
              fullWidth
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={option => option.firstName}
              renderInput={params => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  sx={{ mb: 2 }}
                  margin='dense'
                  variant='standard'
                  label='Supervisor'
                />
              )}
            />
            <Autocomplete
              options={quotes || []}
              value={contractOption || null}
              onChange={(e, value) => {
                if (value?.id) {
                  setJobBriefOption(null);
                  setContractOption({
                    id: value.id,
                  });
                  setReportData({
                    ...reportData,
                    contractId: value.id,
                  });
                }
              }}
              fullWidth
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={option => option.id.toString()}
              renderInput={params => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  sx={{ mb: 3 }}
                  margin='dense'
                  variant='standard'
                  label='Contract'
                />
              )}
            />
            <Autocomplete
              options={filteredJobBriefs || []}
              value={jobBriefOption || null}
              onChange={(e, value) => {
                if (value?.id) {
                  setJobBriefOption({
                    id: value.id,
                  });
                  setReportData({
                    ...reportData,
                    jobBriefId: value.id,
                  });
                }
              }}
              fullWidth
              isOptionEqualToValue={(option, value) => option.id === value.id}
              getOptionLabel={option => option.id.toString()}
              renderInput={params => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  sx={{ mb: 3 }}
                  margin='dense'
                  variant='standard'
                  label='Job Brief'
                />
              )}
            />
            <Button
              type='button'
              variant='contained'
              onClick={clearFilters}
              sx={{
                maxWidth: '250px',
                mr: 'auto',
                mb: 3,
                boxShadow: 3,
              }}
            >
              Clear filters
            </Button>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <DateField
                label='From'
                value={reportData.startDate}
                onChange={value =>
                  setReportData({
                    ...reportData,
                    startDate: new Date(value!),
                  })
                }
                sx={{ width: '100%', mr: 6 }}
              />
              <DateField
                label='To'
                value={reportData.endDate}
                minDate={reportData.startDate}
                onChange={value =>
                  setReportData({
                    ...reportData,
                    endDate: new Date(value!),
                  })
                }
                sx={{ width: '100%' }}
              />
            </Box>
            <Button
              type='submit'
              variant='contained'
              disabled={!canSubmit}
              sx={{ mt: 3, boxShadow: 3 }}
            >
              Generate
            </Button>
          </FormControl>
        </form>
      </CardContent>
    </Card>
  );
}

export default ReportForm;
