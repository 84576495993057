import {
  Autocomplete,
  TableContainer,
  TableHead,
  TableCell,
  TableBody,
  TextField,
  Table,
  TableRow,
  Box,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { useState } from 'react';
import { TimesheetReportProps } from '../../../types/TimesheetReportProps';
import { UserOptionProps, UserProps } from '../../../types/UserProps';
import convertDecimalToTime from '../../../utils/convertDecimalToTime';
import roundTimeToQuarters from '../../../utils/roundTimeToQuarters';
import getMinimumBreakHours from '../../../utils/getMinimumHoursBreak';
import getTimeBetweenTimeStrings from '../../../utils/getTimeBetweenTimeStrings';

interface TimesheetReportMobileFormProps {
  operatives: UserProps[];
  updateTimesheetReport: Function;
  timesheetReports: TimesheetReportProps[];
  setGlobalDayOff: Function;
}

function TimesheetReportMobileForm({
  operatives,
  updateTimesheetReport,
  timesheetReports,
  setGlobalDayOff,
}: TimesheetReportMobileFormProps) {
  const [operativeOption, setOperativeOption] =
    useState<UserOptionProps | null>(null);

  const currentTimesheet = timesheetReports.find(
    timesheetReport => timesheetReport.userId === operativeOption?.id,
  );

  const resetValues = {
    startTime: '',
    finishTime: '',
    hours: 0,
    breakHours: 0,
    breakHoursFormatted: '',
    total: 0,
  };

  const updateStartTime = (
    timesheetReport: TimesheetReportProps,
    value: string,
  ) => {
    // calculate hours
    const { decimal: hours } = getTimeBetweenTimeStrings(
      value,
      timesheetReport.finishTime,
    );
    const breakHours = getMinimumBreakHours(hours);
    const total = Number(hours.toFixed(1)) - breakHours;

    updateTimesheetReport({
      ...timesheetReport,
      startTime: value,
      hours,
      breakHours,
      breakHoursFormatted: breakHours.toString().replace(/,/g, '.'),
      total,
    });
  };

  const updateEndTime = (
    timesheetReport: TimesheetReportProps,
    value: string,
  ) => {
    const { decimal: hours } = getTimeBetweenTimeStrings(
      timesheetReport.startTime,
      value,
    );
    const breakHours = getMinimumBreakHours(hours);
    const total = Number(hours.toFixed(1)) - breakHours;

    updateTimesheetReport({
      ...timesheetReport,
      finishTime: value,
      hours,
      breakHours,
      breakHoursFormatted: breakHours.toString().replace(/,/g, '.'),
      total,
    });
  };
  return (
    <>
      <Autocomplete
        options={operatives || []}
        value={operativeOption! || null}
        disableClearable
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={option => {
          const displayName = `${option.referenceNumber} - ${option.firstName} ${option.lastName}`;
          return displayName;
        }}
        onChange={(e, value) => {
          if (value?.id && value.firstName) {
            setOperativeOption({
              id: value.id,
              referenceNumber: value.referenceNumber,
              firstName: value.firstName,
              lastName: value.lastName,
            });
          }
        }}
        renderInput={params => (
          <TextField
            {...params}
            sx={{ mb: 2 }}
            margin='dense'
            variant='standard'
            label='Operative'
          />
        )}
      />
      {currentTimesheet && (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <TextField
              type='time'
              InputLabelProps={{ shrink: true }}
              variant='standard'
              fullWidth
              size='small'
              disabled={currentTimesheet.dayOff}
              value={currentTimesheet.startTime || ''}
              label='Start time'
              onChange={e => {
                const { value } = e.target;
                updateStartTime(currentTimesheet, value);
              }}
              onBlur={e => {
                const { value } = e.target;
                const formattedValue = roundTimeToQuarters(value);
                updateStartTime(currentTimesheet, formattedValue);
              }}
            />
            <TextField
              type='time'
              InputLabelProps={{ shrink: true }}
              variant='standard'
              fullWidth
              size='small'
              value={currentTimesheet.finishTime || ''}
              disabled={currentTimesheet.dayOff}
              sx={{ ml: 5, mb: 2 }}
              label='Finish time'
              onChange={e => {
                const { value } = e.target;
                updateEndTime(currentTimesheet, value);
              }}
              onBlur={e => {
                const { value } = e.target;
                const formattedValue = roundTimeToQuarters(value);
                updateEndTime(currentTimesheet, formattedValue);
              }}
            />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <TextField
              type='text'
              value={currentTimesheet.breakHoursFormatted}
              disabled
              label='Break hours'
              sx={{ mb: 2 }}
              variant='standard'
              fullWidth
              size='small'
            />
            <FormControlLabel
              sx={{ minWidth: 'fit-content', ml: 5, mb: -1.5 }}
              control={
                <Checkbox
                  checked={currentTimesheet.dayOff}
                  name='show'
                  onClick={() => {
                    if (!currentTimesheet.dayOff === false)
                      setGlobalDayOff(false);

                    updateTimesheetReport({
                      ...currentTimesheet,
                      ...resetValues,
                      dayOff: !currentTimesheet.dayOff,
                    });
                  }}
                />
              }
              label='Day off'
            />
          </Box>
        </>
      )}
      <TableContainer>
        <Table
          sx={{ width: '100%' }}
          size='medium'
        >
          <TableHead>
            <TableRow>
              <TableCell>Operative</TableCell>
              <TableCell align='left'>Hours</TableCell>
              <TableCell align='left'>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {timesheetReports &&
              timesheetReports
                .sort((a, b) => Number(a?.userId) - Number(b?.userId))
                .map(timesheetReport => {
                  const isTimeFilled =
                    timesheetReport.startTime && timesheetReport.finishTime;

                  return (
                    <TableRow
                      key={timesheetReport.id}
                      selected={timesheetReport.userId === operativeOption?.id}
                      hover
                      onClick={() => {
                        setOperativeOption({
                          id: timesheetReport.userId,
                          referenceNumber:
                            timesheetReport.user?.referenceNumber!,
                          firstName: timesheetReport.user?.firstName!,
                          lastName: timesheetReport.user?.lastName!,
                        });
                      }}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        cursor: 'pointer',
                      }}
                    >
                      <TableCell
                        component='th'
                        scope='timesheetReport'
                      >
                        {timesheetReport.user?.firstName}
                      </TableCell>
                      <TableCell align='left'>
                        {isTimeFilled
                          ? convertDecimalToTime(timesheetReport.hours)
                          : ''}
                      </TableCell>
                      <TableCell align='left'>
                        {isTimeFilled ? timesheetReport.total : ''}
                      </TableCell>
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default TimesheetReportMobileForm;
