import { Box } from '@mui/material';
import {
  Add,
  DirectionsCar,
  Engineering,
  ShortText,
  AccessTimeFilled,
  CheckCircle,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import DashboardTitle from '../../../components/atoms/DashboardTitle';
import SquaredButton from '../../../components/atoms/SquaredButton';
import ProductivityWeeklyTable from '../../../components/molecules/ProductivityWeeklyTable';
import { useUser } from '../../../contexts/UserContext';

function DashboardOverview() {
  const navigate = useNavigate();
  const { user } = useUser();
  const isSupervisor =
    user?.role === 'supervisor' || user?.role === 'contractor';
  const isContractor = user?.role === 'contractor';

  return (
    <>
      <DashboardTitle title='Dashboard' />
      <Box
        sx={{
          display: 'grid',
          alignItems: 'center',
          justifyContent: 'center',
          gap: 2,
          gridTemplateColumns: 'repeat(auto-fill, 150px)',
          mb: 6,
        }}
      >
        <SquaredButton
          icon={<ShortText />}
          label={isSupervisor ? 'See your Job Briefs' : 'Job Briefs'}
          onClick={() => navigate('/dashboard/jobBriefs')}
        />
        <SquaredButton
          icon={<AccessTimeFilled />}
          label={isSupervisor ? 'See your Timesheets' : 'Timesheets'}
          onClick={() => navigate('/dashboard/timesheets')}
        />
        {!isContractor && (
          <SquaredButton
            icon={<DirectionsCar />}
            label='Driver timesheets'
            onClick={() => navigate('/dashboard/driverTimesheets')}
          />
        )}
        <SquaredButton
          icon={<CheckCircle />}
          label={isSupervisor ? 'See your EOJs' : 'EOJs'}
          onClick={() => navigate('/dashboard/eojs')}
        />
        {isSupervisor && (
          <>
            <SquaredButton
              icon={<Engineering />}
              label='See your Operatives'
              onClick={() => navigate('/dashboard/operatives/me')}
            />
            <SquaredButton
              icon={
                <>
                  <AccessTimeFilled />
                  <Add />
                </>
              }
              label='Add timesheet'
              onClick={() => navigate('/dashboard/timesheets/add')}
            />
            {!isContractor && (
              <SquaredButton
                icon={
                  <>
                    <DirectionsCar />
                    <Add />
                  </>
                }
                label='Add driver timesheet'
                onClick={() => navigate('/dashboard/driverTimesheets/add')}
              />
            )}
          </>
        )}
      </Box>
      {!isSupervisor && <ProductivityWeeklyTable />}
    </>
  );
}

export default DashboardOverview;
