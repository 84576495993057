const regexPatterns = {
  alphanumericAcceptEmpty: /^[a-zA-Z0-9]*$/,
  alphanumeric: /^[a-zA-Z\u00C0-\u00FF0-9_]+$/,
  spacedAlphanumeric: /^(?!\s*$)[-a-zA-Z0-9_:,.' ']{1,}$/,
  numeric: /^-?\d+(\.\d+)?$/,
  email: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
  password: /^[a-zA-Z0-9!@#$%^&*]{6,20}$/,
  phone: /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/,
};

export default regexPatterns;
