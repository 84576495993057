import { useEffect, useState } from 'react';
import api from '../services/api';

export interface SupervisorProps {
  firstName: string;
  lastName?: string;
  referenceNumber?: string;
  id: number;
  teamId?: number;
}

const useSupervisors = () => {
  const [supervisors, setSupervisors] = useState([] as SupervisorProps[]);

  const getAllSupervisors = async () => {
    const response = await api.get('/users/supervisor');
    if (response.status === 200) {
      const { users } = response.data;
      setSupervisors(users as SupervisorProps[]);
    }
  };

  useEffect(() => {
    getAllSupervisors();
  }, []);

  return {
    supervisors,
    getAllSupervisors,
  };
};
export default useSupervisors;
