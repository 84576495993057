import { useEffect, useState } from 'react';
import api from '../services/api';
import { BillingProps } from '../types/BillingProps';

const useBillings = () => {
  const [billings, setBillings] = useState([] as BillingProps[]);

  const getAllBillings = async () => {
    const response = await api.get('/billings');
    if (response.status === 200) {
      setBillings(response.data.billings);
    }
  };

  useEffect(() => {
    getAllBillings();
  }, []);

  return {
    billings,
    getAllBillings,
  };
};
export default useBillings;
