import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid';
import { DeleteForever, Edit, FormatQuote } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import DashboardTable from '../../atoms/DashboardTable';
import { QuoteProps } from '../../../types/QuoteProps';
import { useDialog } from '../../../contexts/DialogContext';
import QuotesDialog from '../QuotesDialog';
import { CONTRACT_STATUS, QUOTES_STATUS } from '../../../utils/constants';

interface VisitsTableProps {
  quotes: QuoteProps[];
  deleteQuote: Function;
  updateQuote: Function;
  getAllquotes: Function;
  onlyContracts?: boolean;
}

function QuotesTable({
  quotes,
  deleteQuote,
  getAllquotes,
  updateQuote,
  onlyContracts,
}: VisitsTableProps) {
  const navigate = useNavigate();
  const { showDialog } = useDialog();

  const handleDeleteQuote = async (id: number) => {
    await deleteQuote(id);
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      type: 'string',
      maxWidth: 80,
      align: 'center',
      headerAlign: 'center',
      filterable: false,
    },
    {
      field: 'referenceNumber',
      headerName: 'Reference Number',
      width: 230,
      filterable: true,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 200,
      filterable: true,
    },
    {
      field: 'totalValue',
      headerName: 'Total Value',
      width: 200,
      filterable: true,
    },
    {
      field: 'client',
      headerName: 'Client',
      width: 200,
      filterable: true,
      renderCell: (params: GridRenderCellParams) =>
        params.row.client.reference || '',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      align: 'center',
      headerAlign: 'center',
      width: 80,
      filterable: false,
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          icon={<FormatQuote />}
          label='See details'
          onClick={() => navigate(`/dashboard/quotes/${params.row.id}`)}
          showInMenu
        />,
        <GridActionsCellItem
          icon={<Edit />}
          label='Edit'
          onClick={() =>
            showDialog({
              componentToRender: (
                <QuotesDialog
                  getAllquotes={getAllquotes}
                  quote={params.row}
                  updateQuote={updateQuote}
                  onlyContracts={onlyContracts}
                />
              ),
            })
          }
        />,
        <GridActionsCellItem
          sx={{ color: 'error.main' }}
          icon={<DeleteForever sx={{ color: 'error.main' }} />}
          label='Delete'
          onClick={() => handleDeleteQuote(params.row.id)}
          showInMenu
        />,
      ],
    },
  ];

  if (onlyContracts) {
    columns.splice(-1, 0, {
      field: 'contractStatus',
      headerName: 'Contract Status',
      flex: 1,
      filterable: true,
      type: 'singleSelect',
      valueOptions: CONTRACT_STATUS,
    });
  } else {
    columns.splice(-1, 0, {
      field: 'status',
      headerName: 'Quote Status',
      flex: 1,
      filterable: true,
      type: 'singleSelect',
      valueOptions: QUOTES_STATUS,
    });
  }

  return (
    <DashboardTable
      columns={columns}
      rows={quotes}
    />
  );
}

export default QuotesTable;
