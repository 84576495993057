import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import useTasks from '../../../hooks/useTasks';
import DashboardTable from '../../atoms/DashboardTable';

function ServicesTable() {
  const { tasks } = useTasks();

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      type: 'string',
      maxWidth: 80,
      align: 'center',
      headerAlign: 'center',
      filterable: false,
    },
    {
      field: 'type',
      headerName: 'Service type',
      flex: 1,
      filterable: true,
      renderCell: (params: GridRenderCellParams) =>
        params.row.service ? params.row.service.name : null,
    },
    {
      field: 'name',
      headerName: 'Task name',
      flex: 1,
    },
  ];

  return (
    <DashboardTable
      getRowId={Service => Service.id}
      columns={columns}
      rows={tasks}
    />
  );
}

export default ServicesTable;
