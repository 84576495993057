import {
  Alert,
  Autocomplete,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { useCallback, useState } from 'react';
import { toast } from 'material-react-toastify';
import api from '../../../services/api';
import { useDialog } from '../../../contexts/DialogContext';
import isEmpty from '../../../utils/hasEmptyProperties';
import { VisitProps } from '../../../types/VisitProps';
import useTeams from '../../../hooks/useTeams';
import formatDate from '../../../utils/formatDate';

interface AssignTeamDialogProps {
  onAssignTeam: Function;
  visits: VisitProps[];
}

export default function AssignTeamDialog({
  onAssignTeam,
  visits,
}: AssignTeamDialogProps) {
  const { teams } = useTeams();
  const { toggleDialog } = useDialog();
  const [teamId, setTeamId] = useState<number>();
  const [visit, setVisit] = useState<VisitProps>();

  const isAlreadyAssined = visit && teamId === visit.teamId;

  const canSubmit = isEmpty([teamId, visit]) && !isAlreadyAssined;

  const handleSubmit = useCallback(async () => {
    if (!visit) return;
    const data = {
      teamId,
      visitId: visit.id,
    };

    const response = await api.post('/visit/assignTeam', data);
    if (response.status === 200) {
      toast.success('Team assigned successfully!');
      onAssignTeam();
      toggleDialog();
    }
  }, [onAssignTeam, teamId, toggleDialog, visit]);

  return (
    <>
      <DialogTitle>Assign team</DialogTitle>
      <Alert
        severity='warning'
        icon={false}
        sx={{ backgroundColor: 'warning.light' }}
      >
        If a team is highlighted in yellow it means that it is already assigned
      </Alert>
      <DialogContent
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '500px',
          flexDirection: 'column',
        }}
      >
        <Autocomplete
          filterSelectedOptions
          id='tags-standard'
          options={visits}
          onChange={(event, value) => {
            setVisit(value as VisitProps);
          }}
          sx={{ my: 2, width: '100%' }}
          getOptionLabel={option =>
            `ID ${option.id} - ${formatDate(option.startDate)} to ${formatDate(
              option.finishDate,
            )}`
          }
          renderInput={params => (
            <TextField
              {...params}
              variant='standard'
              label='Select a visit'
              placeholder='Visits'
            />
          )}
        />
        <Autocomplete
          filterSelectedOptions
          id='tags-standard'
          options={teams}
          onChange={(event, value) => {
            setTeamId(value?.id);
          }}
          sx={{ my: 2, width: '100%' }}
          getOptionLabel={option => option.name}
          renderInput={params => (
            <TextField
              {...params}
              variant='standard'
              label='Select a team'
              placeholder='Teams'
            />
          )}
          renderOption={(props, option) => (
            <Box
              component='li'
              sx={{
                backgroundColor:
                  option.id === visit?.teamId ? 'warning.light' : '',
              }}
              {...props}
            >
              {option.name}
            </Box>
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ color: 'error.main' }}
          onClick={() => toggleDialog()}
        >
          Cancel
        </Button>
        <Button
          disabled={!canSubmit}
          onClick={() => handleSubmit()}
        >
          Assign
        </Button>
      </DialogActions>
    </>
  );
}
