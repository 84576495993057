import React from 'react';
import { Container, Toolbar, Typography } from '@mui/material';

function Footer() {
  return (
    <Container maxWidth='lg'>
      <Toolbar />
      <Typography
        variant='body2'
        color='text.secondary'
        align='center'
      >
        Copyright © HPS {new Date().getFullYear()}
      </Typography>
    </Container>
  );
}

export default Footer;
