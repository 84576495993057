import {
  GridColDef,
  GridRenderCellParams,
  DataGridProps,
} from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { PayrollReportProps } from '../../../types/PayrollReportProps';
import convertDecimalToTime from '../../../utils/convertDecimalToTime';
import DashboardTable from '../../atoms/DashboardTable';
import ExportTableToolbar from '../../atoms/ExportTableToolbar';

interface ProgressReportTableProps {
  payrollReports: PayrollReportProps[];
  showComments: boolean;
}

function PayrollReportTable({
  payrollReports,
  showComments,
}: ProgressReportTableProps) {
  const columns: GridColDef[] = [
    {
      field: 'referenceNumber',
      headerName: 'User Reference Number',
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      filterable: true,
    },
    {
      field: 'payRate',
      headerName: 'Payrate',
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      filterable: true,
      renderCell: (params: GridRenderCellParams) => params.row.payRate,
    },
    {
      field: 'totalHours',
      headerName: 'Total Hours',
      align: 'center',
      headerAlign: 'center',
      width: 125,
      filterable: false,
      renderCell: (params: GridRenderCellParams) =>
        convertDecimalToTime(params.row.totalHours).replace('h', ''),
    },
  ];

  const [filteredColumns, setFilteredColumns] = useState(columns);

  useEffect(() => {
    if (!showComments)
      setFilteredColumns(
        filteredColumns.filter(item => item.headerName !== 'Comment'),
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardTable
      getRowId={row => row.referenceNumber}
      columns={filteredColumns}
      rows={payrollReports}
      slots={{
        toolbar: ExportTableToolbar as DataGridProps['slots'] as any,
      }}
    />
  );
}

export default PayrollReportTable;
