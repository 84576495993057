import { Box, Card, CardContent, CardHeader } from '@mui/material';
import { forwardRef, ReactNode } from 'react';
import DashboardTitle from '../atoms/DashboardTitle';

interface DetailsProps {
  title: string;
  name: string;
  subTitle: string;
  children: ReactNode;
}

function Details({ title, name, subTitle, children }: DetailsProps) {
  return (
    <>
      <DashboardTitle
        title={title}
        hasBackButton
      />
      <Card>
        <CardHeader
          title={name}
          subheader={subTitle}
        />
        <CardContent sx={{ display: 'flex' }}>
          <Box sx={{ width: '100%' }}>{children}</Box>
        </CardContent>
      </Card>
    </>
  );
}

export default forwardRef(Details);
