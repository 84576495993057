import { Dialog } from '@mui/material';
import {
  createContext,
  useState,
  useContext,
  useMemo,
  ReactElement,
  useCallback,
} from 'react';

interface DialogProps {
  componentToRender: ReactElement;
  onClose?: Function;
}

interface UserContextProps {
  open: boolean;
  toggleDialog: Function;
  // eslint-disable-next-line no-unused-vars
  showDialog: (params: DialogProps) => void;
}

interface UserProviderProps {
  children: ReactElement;
}

const DialogContext = createContext({} as UserContextProps);

function UserProvider({ children }: UserProviderProps) {
  const [open, setOpen] = useState(false);
  const [component, setComponent] = useState<ReactElement>(<div />);
  const toggleDialog = useCallback(() => setOpen(!open), [open]);
  const [close, setClose] = useState<Function | null>();

  const showDialog = useCallback(
    ({ componentToRender, onClose }: DialogProps) => {
      if (onClose) setClose(() => onClose);
      toggleDialog();
      setComponent(componentToRender);
    },
    [toggleDialog],
  );

  const values = useMemo(
    () => ({
      open,
      toggleDialog,
      showDialog,
    }),
    [open, toggleDialog, showDialog],
  );

  return (
    <DialogContext.Provider value={values}>
      {children}
      <Dialog
        open={open}
        onClose={() => {
          if (close) {
            close();
            setClose(null);
          }
          toggleDialog();
        }}
        maxWidth='xl'
      >
        {component}
      </Dialog>
    </DialogContext.Provider>
  );
}

export const useDialog = () => useContext(DialogContext);
export default UserProvider;
