import { useEffect, useState } from 'react';
import api from '../services/api';
import { ClientProps } from '../types/ClientProps';

const useClients = () => {
  const [clients, setClients] = useState([] as ClientProps[]);

  const getAllClients = async () => {
    const response = await api.get('/clients');
    if (response.status === 200) {
      setClients(response.data.clients);
    }
  };

  const createClient = async (clientDetails: ClientProps) => {
    const response = await api.post('/clients', clientDetails);
    if (response.status === 200) {
      setClients([...clients, clientDetails]);
    }
  };

  useEffect(() => {
    getAllClients();
  }, []);

  return {
    clients,
    setClients,
    getAllClients,
    createClient,
  };
};
export default useClients;
