import { useEffect, useState } from 'react';
import { toast } from 'material-react-toastify';
import api from '../services/api';
import hasDatePastHours from '../utils/hasDatePastHours';
import getErrorMessage, {
  ResponseErrorMessage,
} from '../utils/getErrorMessage';

export interface InviteProps {
  email: string;
  hashLink: string;
  role: string;
  id: number;
  createdAt: string;
  status: string;
}

const useInvites = () => {
  const [invites, setInvites] = useState([] as InviteProps[]);

  const getAllInvites = async () => {
    const response = await api.get('/invites');
    const invitesData = response.data.invites;

    const parsedInvites = await Promise.all(
      invitesData.map(async (invite: InviteProps, index: number) => {
        if (hasDatePastHours(invite.createdAt, 48)) {
          const deleteResponse = await api.delete(`/invite/${invite.hashLink}`);
          if (deleteResponse.status === 200) invitesData.splice(index, 1);
        } else if (hasDatePastHours(invite.createdAt, 24)) {
          // eslint-disable-next-line no-param-reassign
          invite.status = 'rejected';
        }
      }),
    );

    if (parsedInvites) setInvites(invitesData);
  };

  useEffect(() => {
    getAllInvites();
  }, []);

  const createInvite = async ({ role, email }: any) => {
    try {
      const response = await api.post('user/invite', { email, role });

      if (response.status === 200) {
        getAllInvites();

        toast.success('Sent invite successfully!');
        return;
      }
    } catch (error) {
      toast.dismiss();
      toast.error(getErrorMessage(error as ResponseErrorMessage));
    }
  };

  return {
    invites,
    getAllInvites,
    createInvite,
  };
};
export default useInvites;
