import React, { useState } from 'react';
import {
  DialogContent,
  Button,
  DialogActions,
  TextField,
  Box,
  IconButton,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { toast } from 'material-react-toastify';
import { useDialog } from '../../../contexts/DialogContext';
import { ServiceProps } from '../../../types/ServiceProps';
import api from '../../../services/api';
import isEmpty from '../../../utils/hasEmptyProperties';

interface ServicesManagerDialogProps {
  services: ServiceProps[];
  getAllServices: Function;
}

const initialValues: ServiceProps = {
  id: 0,
  name: '',
  show: false,
};

function ServicesManagerDialog({
  services,
  getAllServices,
}: ServicesManagerDialogProps) {
  const { toggleDialog } = useDialog();

  const [servicesList, setServicesList] = useState<ServiceProps[]>(services);
  const [serviceData, setServiceData] = useState<ServiceProps>(initialValues);
  const [editingIds, setEditingIds] = useState<number[]>([]);

  const noEmptyFields = isEmpty([serviceData.name]);
  const canSubmit = noEmptyFields;

  const handleCreateService = async () => {
    const response = await api.post('/service', serviceData);

    if (response.status === 200) {
      toast.success('Service registered successfully!');
      const { id } = response.data.service;
      setServiceData(initialValues);
      setServicesList([...servicesList, { ...serviceData, id }]);
    }
  };

  const handleEditService = async (service: ServiceProps) => {
    const response = await api.put('/service', service);

    if (response.status === 200) {
      setServicesList(
        servicesList.map(current =>
          current.id === service.id ? service : current,
        ),
      );
    }
  };

  const handleDeleteService = async (id: number) => {
    const response = await api.delete('/service', {
      data: { id },
    });

    if (response.status === 200) {
      toast.success(`Service deleted successfully!`);
      setServicesList(currentList =>
        currentList.filter(service => service.id !== id),
      );
    }
  };

  return (
    <>
      <DialogContent
        sx={{
          width: '600px',
        }}
      >
        <Box
          sx={{
            mt: 1,
          }}
        >
          <TextField
            label='New service type'
            value={serviceData.name}
            fullWidth
            sx={{ maxWidth: '306px', mr: 3 }}
            size='small'
            onChange={e =>
              setServiceData({
                ...serviceData,
                name: e.target.value,
              })
            }
          />
          <FormControlLabel
            sx={{ minWidth: 'fit-content' }}
            control={
              <Checkbox
                name='show'
                checked={serviceData.show}
                onChange={e =>
                  setServiceData({
                    ...serviceData,
                    show: e.target.checked,
                  })
                }
              />
            }
            label='Always show'
          />
          <IconButton
            color='success'
            aria-label='Add service type'
            title='Add service type'
            component='label'
            disabled={!canSubmit}
            onClick={handleCreateService}
          >
            <AddIcon />
          </IconButton>
        </Box>
        <>
          {servicesList
            .sort((a, b) => Number(a?.id) - Number(b?.id))
            .map((service: ServiceProps) => {
              const isEditing = !!editingIds.find(id => id === service.id);
              const actionName = isEditing ? 'Save' : 'Edit';

              return (
                <Box
                  key={service.id}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    mt: 1,
                  }}
                >
                  <TextField
                    value={service.name}
                    variant='standard'
                    disabled={!isEditing}
                    onChange={e =>
                      setServicesList(
                        servicesList.map(current =>
                          current.id === service.id
                            ? { ...service, name: e.target.value }
                            : current,
                        ),
                      )
                    }
                    fullWidth
                    size='small'
                    sx={{ mr: 3 }}
                  />
                  <FormControlLabel
                    sx={{ minWidth: 'fit-content' }}
                    control={
                      <Checkbox
                        checked={service.show}
                        name='show'
                        onClick={() =>
                          handleEditService({ ...service, show: !service.show })
                        }
                      />
                    }
                    label='Always show'
                  />
                  <IconButton
                    color={isEditing ? 'success' : 'primary'}
                    aria-label={`${actionName} service type`}
                    title={`${actionName} service type`}
                    component='label'
                    onClick={() => {
                      if (!isEditing) {
                        const { id } = service;
                        if (id) setEditingIds([...editingIds, id]);
                      } else {
                        setEditingIds(currentIds =>
                          currentIds.filter(id => id !== service.id),
                        );
                        handleEditService(service);
                      }
                    }}
                  >
                    {isEditing ? <SaveIcon /> : <EditIcon />}
                  </IconButton>
                  <IconButton
                    color='error'
                    aria-label='Delete service type'
                    title='Delete service type'
                    component='label'
                    onClick={() => handleDeleteService(service.id!)}
                  >
                    <DeleteForeverIcon />
                  </IconButton>
                </Box>
              );
            })}
        </>
      </DialogContent>
      <DialogActions>
        <Button
          type='button'
          onClick={() => {
            getAllServices();
            toggleDialog();
          }}
        >
          Done
        </Button>
      </DialogActions>
    </>
  );
}

export default ServicesManagerDialog;
