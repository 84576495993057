import { Groups } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import { useParams } from 'react-router-dom';
import DashboardTitle from '../../../../components/atoms/DashboardTitle';
import OperativesTable from '../../../../components/molecules/OperativesTable';
import AssignOperativeDialog from '../../../../components/molecules/AssignOperativeDialog';
import api from '../../../../services/api';
import { TeamProps } from '../../../../types/TeamProps';
import { useDialog } from '../../../../contexts/DialogContext';

function TeamDetails() {
  const { showDialog } = useDialog();
  const { id } = useParams();
  const [team, setTeam] = useState({
    id: 0,
    name: '',
    supervisorName: '',
    supervisorId: 0,
    users: [],
  } as TeamProps);

  const getTeamById = async (teamId: number) => {
    const response = await api.get(`/team/${teamId}`);
    if (response.status === 200) {
      setTeam(response.data.team);
    }
  };

  const informationsToDisplay = [
    {
      label: 'Supervisor',
      value:
        team.users!.find(user => user.id === team.supervisorId)?.firstName ||
        null,
    },
  ];

  const operatives = team.users!.filter(user => user.role === 'operative');

  useEffect(() => {
    if (id) {
      getTeamById(parseInt(id, 10));
    }
  }, [id]);

  return (
    <>
      <DashboardTitle
        title='Team Details'
        hasBackButton
      />
      <Card sx={{ mb: 3 }}>
        <CardHeader
          title={`${team.name}`}
          subheader={`Here you can see all details about ${team.name}`}
        />
        <CardContent sx={{ display: 'flex' }}>
          <Box sx={{ width: '100%' }}>
            <Groups />
            {informationsToDisplay.map(teamInfo => (
              <>
                <Typography
                  variant='h5'
                  mt={2}
                  display='flex'
                  alignItems='center'
                >
                  {`${teamInfo.label}: `}
                  <Typography
                    variant='h6'
                    fontWeight={300}
                    sx={{ ml: 1 }}
                  >
                    {teamInfo.value}
                  </Typography>
                </Typography>
                <Divider />
              </>
            ))}
          </Box>
        </CardContent>
      </Card>
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography
          variant='h5'
          sx={{ mb: 2 }}
        >
          Operatives
        </Typography>
        <Button
          variant='contained'
          endIcon={<AssignmentIndIcon />}
          onClick={() =>
            showDialog({
              componentToRender: (
                <AssignOperativeDialog
                  team={team}
                  refreshTeam={() => getTeamById(team.id)}
                />
              ),
            })
          }
          sx={{ mb: 2 }}
        >
          Assign Operative(s)
        </Button>
      </Box>
      {operatives && (
        <OperativesTable
          operatives={operatives}
          refreshTeam={() => getTeamById(team.id)}
        />
      )}
    </>
  );
}

export default TeamDetails;
