import {
  Alert,
  Autocomplete,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { toast } from 'material-react-toastify';
import api from '../../../services/api';
import { useDialog } from '../../../contexts/DialogContext';
import isEmpty from '../../../utils/hasEmptyProperties';
import { TeamProps } from '../../../types/TeamProps';

interface AssignOperativeDialogProps {
  team: TeamProps;
  refreshTeam: Function;
}

interface OperativeProps {
  firstName: string;
  lastName: string;
  referenceNumber: string;
  id: number | null;
  teamId: number | null;
}

export default function AssignOperativeDialog({
  team,
  refreshTeam,
}: AssignOperativeDialogProps) {
  const { toggleDialog } = useDialog();
  const [selectedOperatives, setSelectedOperatives] =
    useState<OperativeProps[]>();
  const [operatives, setOperatives] = useState<OperativeProps[]>([
    {
      firstName: '',
      lastName: '',
      referenceNumber: '',
      id: null,
      teamId: null,
    },
  ]);

  const canSubmit = isEmpty([selectedOperatives?.length]);

  const getOperatives = async () => {
    const response = await api.get('/users/operative');
    if (response.status === 200) {
      const { users } = response.data;

      const parsedOperatives = await Promise.all(
        users.map(async (operative: OperativeProps) => ({
          firstName: operative.firstName,
          lastName: operative.lastName,
          referenceNumber: operative.referenceNumber,
          id: operative.id,
          teamId: operative.teamId,
        })),
      );

      setOperatives(parsedOperatives as OperativeProps[]);
    }
  };

  useEffect(() => {
    getOperatives();
  }, []);

  async function handleSubmit() {
    const operativesIds: number[] = [];

    selectedOperatives!.forEach(operativeData => {
      operativesIds.push(operativeData.id as number);
    });

    const data = {
      operativesIds,
      teamId: team.id,
    };

    const response = await api.post('/team/assignOperatives', data);
    if (response.status === 200) {
      toast.success('Operative(s) assigned successfully!');
      refreshTeam();
      toggleDialog();
    }
  }

  return (
    <>
      <DialogTitle sx={{ width: '450px' }}>
        Assign operatives to your team
      </DialogTitle>
      <Alert
        severity='warning'
        icon={false}
        sx={{ backgroundColor: 'warning.light' }}
      >
        Operatives highlighted in yellow already have a team!
      </Alert>
      <DialogContent
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Autocomplete
          multiple
          options={operatives || undefined}
          fullWidth
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={option => `${option.firstName} ${option.lastName}`}
          onChange={(event, value) => setSelectedOperatives(value)}
          renderOption={(props, option) => (
            <Box
              component='li'
              sx={{
                backgroundColor: option.teamId !== null ? 'warning.light' : '',
              }}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
            >
              {`${option.referenceNumber} - ${option.firstName} ${option.lastName}`}
            </Box>
          )}
          renderInput={params => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              margin='dense'
              variant='standard'
              label='Operative(s)'
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ color: 'error.main' }}
          onClick={() => toggleDialog()}
        >
          Cancel
        </Button>
        <Button
          disabled={!canSubmit}
          onClick={() => handleSubmit()}
        >
          Assign
        </Button>
      </DialogActions>
    </>
  );
}
