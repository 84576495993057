import {
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
} from '@mui/x-data-grid';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import RefreshIcon from '@mui/icons-material/Refresh';
import { toast } from 'material-react-toastify';
import { InviteProps } from '../../../hooks/useInvites';
import StatusChip from '../../atoms/StatusChip';
import api from '../../../services/api';
import DashboardTable from '../../atoms/DashboardTable';

interface InvitesTableProps {
  invites: InviteProps[];
  getAllInvites: Function;
}

export default function InvitesTable({
  invites,
  getAllInvites,
}: InvitesTableProps) {
  const handleDeleteInvite = async (row: any) => {
    if (row.status === 'rejected') {
      const response = await api.delete(`/invite/${row.hashLink}`);

      if (response.status === 200) {
        toast.success(`Invite deleted successfully!`);
        getAllInvites();
      }
    } else {
      toast.info(`Delete option is only available for rejected invites!`);
    }
  };
  const handleResendInvite = async (row: any) => {
    const response = await api.post(`/invite/resend/${row.hashLink}`);
    if (response.status === 200) {
      toast.success(`Invite successfully resent!`);
      getAllInvites();
      return;
    }
    toast.error('Something went wrong. Please try again!');
  };

  const showDeleteButton = (row: any) => (
    <GridActionsCellItem
      sx={{ color: 'error.main' }}
      icon={<DeleteForeverIcon sx={{ color: 'error.main' }} />}
      label='Delete'
      onClick={() => handleDeleteInvite(row)}
      showInMenu
    />
  );

  const showResendInvite = (row: any) =>
    row.status === 'rejected' ? (
      <GridActionsCellItem
        icon={<RefreshIcon />}
        label='Resend Invite'
        onClick={() => handleResendInvite(row)}
        showInMenu
      />
    ) : (
      <div />
    );

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      type: 'string',
      maxWidth: 100,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'email',
      headerName: 'Email',
      type: 'string',
      width: 100,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'createdAt',
      headerName: 'Sent Date',
      type: 'string',
      maxWidth: 200,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: params => {
        const date = new Date(params.value);
        return date.toLocaleString();
      },
    },
    {
      field: 'hashLink',
      headerName: 'Hash Link',
      type: 'string',
      width: 400,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'status',
      headerName: 'Status',
      type: 'string',
      minWidth: 120,
      align: 'center',
      headerAlign: 'center',
      renderCell: params => <StatusChip status={params.value} />,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      align: 'center',
      headerAlign: 'center',
      width: 90,
      filterable: false,
      getActions: (params: GridRowParams) => [
        showDeleteButton(params.row),
        showResendInvite(params.row),
      ],
    },
  ];

  return (
    <DashboardTable
      getRowId={invite => invite.id}
      columns={columns}
      rows={invites}
    />
  );
}
