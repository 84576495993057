const ROLES = ['admin', 'supervisor', 'contractor', 'operative'];
const QUOTES_STATUS = ['Proposed to a Client', 'Draft', 'Accepted'];
const JOB_BRIEFS_STATUS = [
  'Unassigned',
  'Assigned',
  'In Progress',
  'Submitted for review',
  'Completed',
  'Closed',
];

const CONTRACT_STATUS = [
  'Unassigned',
  'Assigned',
  'In Progress',
  'Submitted for review',
  'Completed',
];
export { ROLES, QUOTES_STATUS, JOB_BRIEFS_STATUS, CONTRACT_STATUS };
