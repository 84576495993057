import { Person } from '@mui/icons-material';
import { Badge, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';

function UserMenu() {
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isUserMenuOpen, setOpenUserMenu] = useState(false);

  const toggleUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(!isUserMenuOpen ? event.currentTarget : null);
    setOpenUserMenu(!isUserMenuOpen);
  };

  const handleUserLogout = () => {
    localStorage.removeItem('HPS:token');
    navigate('/login');
  };

  return (
    <>
      <IconButton
        color='inherit'
        onClick={toggleUserMenu}
        aria-controls='user-menu'
        aria-haspopup='true'
        aria-expanded={isUserMenuOpen}
      >
        <Badge color='secondary'>
          <Person />
        </Badge>
      </IconButton>
      <Menu
        id='user-menu'
        anchorEl={anchorEl}
        open={isUserMenuOpen}
        onClick={toggleUserMenu}
      >
        <MenuItem
          component={RouterLink}
          to='/dashboard/profile'
        >
          <Typography
            variant='body2'
            sx={{ mx: 1, color: 'inherit', textDecoration: 'none' }}
          >
            Profile
          </Typography>
        </MenuItem>
        <MenuItem onClick={handleUserLogout}>
          <Typography
            variant='body2'
            sx={{ mx: 1, color: 'darkRed' }}
          >
            Logout
          </Typography>
        </MenuItem>
      </Menu>
    </>
  );
}

export default UserMenu;
