import DashboardTitle from '../../../components/atoms/DashboardTitle';
import InviteForm from '../../../components/molecules/InviteForm';
import InvitesTable from '../../../components/molecules/InvitesTable';
import useInvites from '../../../hooks/useInvites';

function Invite() {
  const { invites, getAllInvites, createInvite } = useInvites();

  return (
    <>
      <DashboardTitle
        title='Invite'
        hasBackButton
      />
      <InviteForm createInvite={createInvite} />
      <InvitesTable
        invites={invites}
        getAllInvites={getAllInvites}
      />
    </>
  );
}

export default Invite;
