import { ModeRounded } from '@mui/icons-material';
import { Box, Divider, Typography } from '@mui/material';
import { toast } from 'material-react-toastify';
import { useEffect, useState } from 'react';
import api from '../../../services/api';
import { QuoteProps } from '../../../types/QuoteProps';
import DataList from '../DataList';
import VisitsList from '../VisitsList';

interface ContractPDFProps {
  contractId: number;
}

function ClientSignature() {
  return (
    <Box
      sx={{
        width: '100%',
        mt: 8,
      }}
    >
      <ModeRounded />
      <Divider />
      <Typography
        variant='subtitle2'
        sx={{ textAlign: 'center' }}
      >
        Client Signature
      </Typography>
    </Box>
  );
}

export default function ContractPDF({ contractId }: ContractPDFProps) {
  const [contract, setContract] = useState({} as QuoteProps);
  const getContract = async () => {
    try {
      const response = await api.get(`/quote/contract/${contractId}}`);
      setContract(response.data.quote);
    } catch (error) {
      toast.error('Something went wrong!');
    }
  };

  const informationsToDisplay = [
    {
      label: 'City',
      value: contract.city,
    },
    {
      label: 'Email',
      value: contract.email,
    },
    {
      label: 'Reference Number',
      value: contract.referenceNumber,
    },
    {
      label: 'Total Value',
      value: contract.totalValue,
    },
    {
      label: 'Billing Entity',
      value: contract.billing?.companyName || 'No billing entity selected',
    },
  ];

  const clientDetails = [
    {
      label: 'Client reference',
      value: contract.client?.reference || '',
    },
    {
      label: 'Head office address',
      value: contract.client?.headOfficeAddress || '',
    },
    {
      label: 'Company name',
      value: contract.client?.companyName || '',
    },
    {
      label: 'Head office phone',
      value: contract.client?.headOfficePhone || '',
    },
    {
      label: 'Head office email',
      value: contract.client?.headOfficeEmail || '',
    },
  ];

  const totalVisitsValue = contract.visits
    ? contract.visits.reduce((sumTotal, visit) => {
        if (visit.visitTasks) {
          return (
            visit.visitTasks.reduce(
              (sum, item) => Number(item.price) * Number(item.time) + sum,
              0,
            ) + sumTotal
          );
        }
        return 0 + sumTotal;
      }, 0)
    : 'No visits';

  useEffect(() => {
    getContract();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      sx={{
        width: '80%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: 'auto',
      }}
      className='pdf-page'
    >
      <img
        src='/Logo_HPS-Black.svg'
        alt='Hps logo'
        width={80}
        height={80}
      />
      <Typography variant='h3'>Contract</Typography>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'initial',
          margin: 'auto',
        }}
      >
        <DataList data={informationsToDisplay} />
      </Box>
      <Typography
        variant='h6'
        mt={2}
      >
        Client informations
      </Typography>

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'initial',
          margin: 'auto',
        }}
      >
        <DataList data={clientDetails} />
      </Box>

      <Typography
        variant='h6'
        mt={2}
      >
        Visits
      </Typography>

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'initial',
          margin: 'auto',
        }}
      >
        {contract.visits && <VisitsList visits={contract.visits} />}
      </Box>

      <Typography
        variant='subtitle2'
        mt={3}
      >
        Total: {totalVisitsValue}
      </Typography>

      <ClientSignature />
    </Box>
  );
}
