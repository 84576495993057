import React from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardTitle from '../../../../components/atoms/DashboardTitle';
import ReportForm from '../../../../components/molecules/ReportForm';
import api from '../../../../services/api';
import { ReportFilterProps } from '../../../../types/ReportFilterProps';
import { PayrollReportProps } from '../../../../types/PayrollReportProps';

function DashboardPayrollReport() {
  const navigate = useNavigate();

  const handleSubmit = async (reportData: ReportFilterProps) => {
    const response = await api.post('/payrollReports', reportData);

    if (response.status === 200) {
      const { payrollReports } = response.data;
      navigate('/dashboard/payrollReport/details', {
        state: payrollReports as PayrollReportProps[],
      });
    }
  };

  return (
    <>
      <DashboardTitle
        title='Payroll Report'
        hasBackButton
      />
      <ReportForm handleSubmit={handleSubmit} />
    </>
  );
}

export default DashboardPayrollReport;
