import {
  DialogContent,
  Button,
  DialogActions,
  DialogTitle,
} from '@mui/material';
import { ReactNode, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useDialog } from '../../../contexts/DialogContext';

interface QuoteDialogProps {
  documentTitle: string;
  component: ReactNode;
}

function PDFDialog({ component, documentTitle }: QuoteDialogProps) {
  const { toggleDialog } = useDialog();

  const detailsRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => detailsRef.current,
    documentTitle,
  });

  return (
    <>
      <DialogTitle>See Document Preview</DialogTitle>
      <DialogContent
        ref={detailsRef}
        sx={{ width: '80vw' }}
      >
        {component}
      </DialogContent>
      <DialogActions>
        <Button
          type='button'
          sx={{ color: 'error.main' }}
          onClick={() => toggleDialog()}
        >
          Cancel
        </Button>
        <Button onClick={handlePrint}>Generate PDF</Button>
      </DialogActions>
    </>
  );
}

export default PDFDialog;
