import {
  Box,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState, useMemo } from 'react';
import { VisitProps } from '../../../types/VisitProps';
import { ProgressReportProps } from '../../../types/ProgressReportProps';
import useTimesheets from '../../../hooks/useTimesheets';
import { VisitTaskProps } from '../../../types/VisitTaskProps';

interface ProgressReportFormProps {
  visit: VisitProps;
  jobBriefId: number;
  handleProgressReports: Function;
  // eslint-disable-next-line
  setShowModal: (showModal: boolean) => void;
}

const initialValues: ProgressReportProps = {
  id: 0,
  completedUnits: 0,
  timesheetId: 0,
  visitTaskId: 0,
};

function ProgressReportForm({
  visit,
  jobBriefId,
  handleProgressReports,
  setShowModal,
}: ProgressReportFormProps) {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  const isTablet = useMediaQuery(useTheme().breakpoints.down('lg'));

  const { timesheets } = useTimesheets({ bySupervisor: false });
  const { visitTasks } = visit;

  const [progressReports, setProgressReports] = useState<ProgressReportProps[]>(
    [],
  );

  const filteredTimesheets = useMemo(
    () =>
      timesheets.filter(
        timesheet =>
          timesheet.jobBriefId === jobBriefId && timesheet.visitId === visit.id,
      ),
    [timesheets, jobBriefId, visit.id],
  );

  useEffect(() => {
    const tasksToReport: ProgressReportProps[] = !visitTasks
      ? []
      : visitTasks.map(visitTask => ({
          ...initialValues,
          visitTaskId: visitTask.id,
        }));

    setProgressReports(tasksToReport);
  }, [visitTasks]);

  useEffect(() => {
    const tasksToReport: ProgressReportProps[] = [];

    visitTasks?.forEach(visitTask => {
      tasksToReport.push({
        ...initialValues,
        visitTaskId: visitTask.id,
      });
    });

    handleProgressReports(tasksToReport);
  }, [visitTasks]);

  const updateProgressReport = (
    currentTask: ProgressReportProps,
    completedUnits: number,
  ) => {
    const updatedProgressReports = progressReports.map(current =>
      current === currentTask
        ? {
            ...current,
            completedUnits,
          }
        : current,
    );

    const filteredProgressReports = updatedProgressReports.filter(
      current => current.completedUnits >= 0,
    );

    setProgressReports(updatedProgressReports);
    handleProgressReports(filteredProgressReports);
  };

  return (
    <Box sx={{ flexGrow: 1, mt: 3, overflow: 'auto' }}>
      <Grid
        container
        direction='row'
        alignItems='center'
        columns={5}
        spacing={3}
        style={{
          width: (() => {
            if (isMobile) return '190%';
            if (isTablet) return '140%';
            return '100%';
          })(),
        }}
      >
        <Grid
          item
          xs={1}
        />
        <Grid
          item
          xs={1}
        >
          <Typography variant='body2'>Completed Today</Typography>
        </Grid>
        <Grid
          item
          xs={1}
        >
          <Typography variant='body2'>Available Units</Typography>
        </Grid>
        <Grid
          item
          xs={1}
        >
          <Typography variant='body2'>Completed Units</Typography>
        </Grid>
        <Grid
          item
          xs={1}
        >
          <Typography variant='body2'>Total Units</Typography>
        </Grid>
        {visitTasks &&
          visitTasks
            .sort((a, b) => Number(a?.id) - Number(b?.id))
            .map((visitTask: VisitTaskProps) => {
              let reportedTasks: ProgressReportProps[] = [];

              filteredTimesheets.forEach(timesheet => {
                const timesheetReportedTasks =
                  timesheet.progressReports!.filter(
                    progressReport =>
                      progressReport.visitTaskId === visitTask.id,
                  );

                reportedTasks = reportedTasks.concat(timesheetReportedTasks);
              });

              let previouslyCompletedUnits = 0;
              reportedTasks?.forEach(element => {
                previouslyCompletedUnits += element.completedUnits;
              });

              const currentTask =
                progressReports.find(
                  current => current.visitTaskId === visitTask.id,
                ) || initialValues;

              const completedUnits =
                previouslyCompletedUnits + currentTask.completedUnits;
              const totalUnits = visitTask.units || 0;
              const completedUnitsTotal = completedUnits;
              const availableUnits = totalUnits - completedUnitsTotal;

              setShowModal(availableUnits < 0);

              return (
                <React.Fragment key={`task-${visitTask.id}`}>
                  <Grid
                    item
                    xs={1}
                  >
                    <Typography variant='subtitle2'>
                      {visitTask.name}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={1}
                  >
                    <TextField
                      type='number'
                      variant='standard'
                      inputProps={{
                        min: 0,
                      }}
                      value={currentTask.completedUnits}
                      fullWidth
                      size='small'
                      onChange={e =>
                        updateProgressReport(
                          currentTask,
                          Number(e.target.value),
                        )
                      }
                    />
                  </Grid>
                  <Grid
                    item
                    xs={1}
                  >
                    <TextField
                      variant='standard'
                      disabled
                      fullWidth
                      value={availableUnits}
                      size='small'
                      {...(availableUnits < 0 && {
                        error: true,
                      })}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={1}
                  >
                    <TextField
                      variant='standard'
                      disabled
                      value={completedUnitsTotal}
                      fullWidth
                      size='small'
                    />
                  </Grid>
                  <Grid
                    item
                    xs={1}
                  >
                    <TextField
                      variant='standard'
                      disabled
                      value={totalUnits}
                      fullWidth
                      size='small'
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
      </Grid>
    </Box>
  );
}

export default ProgressReportForm;
