import { AccountBox, Apartment, Add, DeleteForever } from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import { toast } from 'material-react-toastify';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ButtonDialog from '../../../../components/atoms/ButtonDialog';
import DashboardTitle from '../../../../components/atoms/DashboardTitle';
import ItemsList from '../../../../components/atoms/ItemsList';
import AddClientServiceDialog from '../../../../components/molecules/AddClientServiceDialog';

import AddClientTaskDialog from '../../../../components/molecules/AddClientTaskDialog';
import ClientTasksTable from '../../../../components/molecules/ClientTasksTable';
import api from '../../../../services/api';
import { ClientProps } from '../../../../types/ClientProps';

function ClientDetails() {
  const id = parseInt(useParams().id!, 10);
  const [client, setClient] = useState({
    id: 0,
    reference: '',
    headOfficeAddress: '',
    companyName: '',
    headOfficeEmail: '',
    headOfficePhone: '',
    Billings: [],
    buildings: [],
  } as ClientProps);

  const getClientById = async (clientId: number) => {
    const response = await api.get(`/client/${clientId}`);
    if (response.status === 200) {
      setClient(response.data.client);
    }
  };

  const handleRemoveServiceFromClient = async (clientServiceId: number) => {
    const response = await api.delete('/client/removeService', {
      data: {
        clientServiceId,
      },
    });

    if (response.status === 200) {
      toast.success('Service removed successfully!');
      getClientById(id);
    }
  };

  const informationsToDisplay = [
    {
      label: 'Head office address',
      value: client.headOfficeAddress,
    },
    {
      label: 'Company Name',
      value: client.companyName,
    },
    {
      label: 'Head office email',
      value: client.headOfficeEmail,
    },
    {
      label: 'Head office phone',
      value: client.headOfficePhone,
    },
  ];
  useEffect(() => {
    if (id) {
      getClientById(id);
    }
  }, [id]);
  return (
    <>
      <DashboardTitle
        title='Client Details'
        hasBackButton
      />
      <Card>
        <CardHeader
          title={`${client.reference}`}
          subheader={`Here you can see all details about ${client.reference}`}
        />
        <CardContent sx={{ display: 'flex' }}>
          <Box sx={{ width: '100%' }}>
            <AccountBox />
            {informationsToDisplay.map(clientInfo => (
              <>
                <Typography
                  variant='h5'
                  mt={2}
                  display='flex'
                  alignItems='center'
                >
                  {`${clientInfo.label}: `}
                  <Typography
                    variant='h6'
                    fontWeight={300}
                    sx={{ ml: 1 }}
                  >
                    {clientInfo.value}
                  </Typography>
                </Typography>
                <Divider />
              </>
            ))}
          </Box>
          {client.buildings && client.buildings?.length > 0 && (
            <ItemsList
              icon={<Apartment sx={{ ml: 1.5 }} />}
              title={`Buildings of ${client.reference}`}
              items={client.buildings?.map(building => building.name)}
            />
          )}
        </CardContent>
      </Card>
      <Box sx={{ width: '50%', display: 'flex' }}>
        <ButtonDialog
          component={
            <AddClientServiceDialog
              client={client}
              refreshClient={() => getClientById(id)}
            />
          }
          label='Add Service'
          sx={{ mt: 4, mr: 2, minWidth: 'fit-content' }}
          endIcon={<Add />}
        />
        <ButtonDialog
          component={
            <AddClientTaskDialog
              client={client}
              refreshClient={() => getClientById(id)}
            />
          }
          label='Add Service Task'
          sx={{ mt: 4, minWidth: 'fit-content' }}
          endIcon={<Add />}
        />
      </Box>
      {client.clientServices &&
        client.clientServices.map(clientService => (
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography
                key={`item-${clientService.id}`}
                variant='h5'
                sx={{ my: 2, mr: 2 }}
              >
                {`${clientService.serviceName} `}
              </Typography>
              <IconButton
                aria-label='Remove service'
                title='Remove service'
                component='label'
                sx={{
                  backgroundColor: 'white',
                  boxShadow: 2,
                  color: 'error.main',
                  borderRadius: '50%',
                  width: '30px',
                  height: '30px',
                }}
                onClick={() => handleRemoveServiceFromClient(clientService.id)}
              >
                <DeleteForever />
              </IconButton>
            </Box>
            {clientService.clientTasks && (
              <ClientTasksTable
                clientTasks={clientService.clientTasks}
                refreshClient={() => getClientById(id)}
              />
            )}
          </>
        ))}
    </>
  );
}

export default ClientDetails;
