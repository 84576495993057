import { Apartment } from '@mui/icons-material';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { toast } from 'material-react-toastify';
import { useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Details from '../../../../components/organisms/Details';
import ButtonDialog from '../../../../components/atoms/ButtonDialog';
import VisitsList from '../../../../components/atoms/VisitsList';
import api from '../../../../services/api';
import { BuildingProps } from '../../../../types/BuildingProps';
import AddBuildingServiceDialog from '../../../../components/molecules/AddBuildingServiceDialog';
import AddBuildingTaskDialog from '../../../../components/molecules/AddBuildingTaskDialog';
import BuildingTasksTable from '../../../../components/molecules/BuildingTasksTable';

const initialValues: BuildingProps = {
  id: 0,
  name: '',
  address: '',
  city: '',
  clientId: 0,
  billingId: 0,
  buildingServices: [],
};

function BuildingDetails() {
  const id = parseInt(useParams().id!, 10);
  const [building, setBuilding] = useState(initialValues);

  const getBuildingById = async (buildingId: number) => {
    const response = await api.get(`/building/${buildingId}`);
    if (response.status === 200) {
      setBuilding(response.data.building);
    }
  };

  const handleRemoveServiceFromBuilding = async (buildingServiceId: number) => {
    const response = await api.delete('/building/removeService', {
      data: {
        buildingServiceId,
      },
    });

    if (response.status === 200) {
      toast.success('Service removed successfully!');
      getBuildingById(id);
    }
  };

  const informationsToDisplay = [
    {
      label: 'Address',
      value: building.address,
    },
    {
      label: 'City',
      value: building.city,
    },
    {
      label: 'Building phone number',
      value: building.phone,
    },
    {
      label: 'Building email',
      value: building.email,
    },
    {
      label: 'Client',
      value: building.client?.reference,
    },
    {
      label: 'Billing',
      value: building.billing?.companyName,
    },
  ];

  useEffect(() => {
    getBuildingById(id);
  }, [id]);

  return (
    <>
      <Details
        title='Building Details'
        subTitle={`Here you can see all details about ${building.name}`}
        name={`${building.name}`}
      >
        <Box sx={{ width: '100%', display: 'flex' }}>
          <Box sx={{ width: '100%' }}>
            <Apartment />
            {informationsToDisplay.map(buildingInfo => (
              <>
                <Typography
                  key={`item-${buildingInfo.label}`}
                  variant='h5'
                  mt={2}
                  display='flex'
                  alignItems='center'
                >
                  {`${buildingInfo.label}: `}
                  <Typography
                    variant='h6'
                    fontWeight={300}
                    sx={{ ml: 1 }}
                  >
                    {buildingInfo.value}
                  </Typography>
                </Typography>
                <Divider />
              </>
            ))}
          </Box>
          <Box sx={{ width: '100%', height: 250, mb: 4 }}>
            <VisitsList
              title='Visits related to this building'
              visits={building.visits || []}
            />
          </Box>
        </Box>
      </Details>
      <Box sx={{ width: '50%', display: 'flex' }}>
        <ButtonDialog
          component={
            <AddBuildingServiceDialog
              building={building}
              refreshBuilding={() => getBuildingById(id)}
            />
          }
          label='Add Service'
          sx={{ mt: 4, mr: 2, minWidth: 'fit-content' }}
          endIcon={<AddIcon />}
        />
        <ButtonDialog
          component={
            <AddBuildingTaskDialog
              building={building}
              refreshBuilding={() => getBuildingById(id)}
            />
          }
          label='Add Service Task'
          sx={{ mt: 4, minWidth: 'fit-content' }}
          endIcon={<AddIcon />}
        />
      </Box>
      {building.buildingServices &&
        building.buildingServices.map(buildingService => (
          <>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Typography
                key={`item-${buildingService.id}`}
                variant='h5'
                sx={{ my: 2, mr: 2 }}
              >
                {`${buildingService.serviceName} `}
              </Typography>
              <IconButton
                aria-label='Remove service'
                title='Remove service'
                component='label'
                sx={{
                  backgroundColor: 'white',
                  boxShadow: 2,
                  color: 'error.main',
                  borderRadius: '50%',
                  width: '30px',
                  height: '30px',
                }}
                onClick={() =>
                  handleRemoveServiceFromBuilding(buildingService.id)
                }
              >
                <DeleteForeverIcon />
              </IconButton>
            </Box>
            {buildingService.buildingTasks && (
              <BuildingTasksTable
                buildingTasks={buildingService.buildingTasks}
                refreshBuilding={() => getBuildingById(id)}
              />
            )}
          </>
        ))}
    </>
  );
}

export default BuildingDetails;
