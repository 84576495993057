import { Button, DialogActions, DialogContent, TextField } from '@mui/material';
import { toast } from 'material-react-toastify';
import { useState, FormEvent } from 'react';
import { useDialog } from '../../../contexts/DialogContext';
import api from '../../../services/api';
import { EntityTaskProps } from '../../../types/EntityTaskProps';
import isEmpty from '../../../utils/hasEmptyProperties';
import TimeField from '../../atoms/TimeField';

interface ClientTaskCreateProps {
  clientTask: EntityTaskProps;
  refreshClient: Function;
}

const initialValues: EntityTaskProps = {
  id: 0,
  name: '',
  price: 0,
  time: 0,
};

function ClientTaskDialog({
  clientTask,
  refreshClient,
}: ClientTaskCreateProps) {
  const [clientTaskData, setClientTaskData] = useState(
    clientTask || initialValues,
  );

  const { toggleDialog } = useDialog();

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();

    const response = await api.put('/clientTask', clientTaskData);

    if (response.status === 200) {
      toast.success('Task updated sucessfully!');
      refreshClient();
    }

    toggleDialog();
  }

  const noEmptyFields = isEmpty([
    clientTaskData?.name,
    clientTaskData?.price,
    clientTaskData?.time,
  ]);

  const hasUpdatedFields =
    JSON.stringify(clientTaskData) !== JSON.stringify(clientTask);

  const canSubmit = hasUpdatedFields && noEmptyFields;

  return (
    <form onSubmit={handleSubmit}>
      <DialogContent sx={{ width: '100%', maxWidth: '450px' }}>
        <TextField
          label='Name'
          value={clientTaskData.name}
          variant='standard'
          sx={{ mb: 2 }}
          fullWidth
          onChange={e =>
            setClientTaskData({
              ...clientTaskData,
              name: e.target.value,
            })
          }
        />
        <TextField
          label='Price'
          value={clientTaskData.price || ''}
          variant='standard'
          type='number'
          sx={{ mb: 2 }}
          fullWidth
          onChange={e =>
            setClientTaskData({
              ...clientTaskData,
              price: parseFloat(e.target.value),
            })
          }
        />
        <TimeField
          label='Execution Time'
          value={clientTaskData.time}
          sx={{ width: '100%', mb: -0.5 }}
          onChange={e =>
            setClientTaskData({
              ...clientTaskData,
              time: Number(parseFloat(e.target.value).toFixed(2)),
            })
          }
        />
      </DialogContent>
      <DialogActions>
        <Button
          color='error'
          type='button'
          onClick={() => toggleDialog()}
        >
          Close
        </Button>
        <Button
          type='submit'
          disabled={!canSubmit}
        >
          Update
        </Button>
      </DialogActions>
    </form>
  );
}

export default ClientTaskDialog;
