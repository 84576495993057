import { Chip } from '@mui/material';

const statusDictionary: {
  [key: string]: 'error' | 'info' | 'success' | 'warning';
} = {
  pending: 'warning',
  visualized: 'info',
  accepted: 'success',
  rejected: 'error',
};

export default function StatusChip({ status }: { status: string }) {
  return (
    <Chip
      variant='outlined'
      color={statusDictionary[status] || 'error'}
      label={status}
    />
  );
}
