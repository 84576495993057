import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'material-react-toastify';
import { UserProps } from '../../../types/UserProps';
import api from '../../../services/api';
import DashboardTable from '../../atoms/DashboardTable';

interface OperativesTableProps {
  operatives: UserProps[];
  refreshTeam: Function;
}

function OperativesTable({ operatives, refreshTeam }: OperativesTableProps) {
  const handleRemoveOperative = async (operativeId: number) => {
    const response = await api.put('/team/removeOperative', { operativeId });

    if (response.status === 200) {
      toast.success('Operative removed successfully!');
      refreshTeam();
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      type: 'string',
      maxWidth: 80,
      align: 'center',
      headerAlign: 'center',
      filterable: false,
    },
    {
      field: 'referenceNumber',
      headerName: 'Ref. Number',
      type: 'string',
      width: 120,
      align: 'center',
      headerAlign: 'center',
      filterable: true,
    },
    /* {
      field: 'username',
      headerName: 'Username',
      flex: 1,
      filterable: true,
    }, */
    {
      field: 'firstName',
      headerName: 'Name',
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const cell = `${params.row.firstName} ${params.row.lastName}`;
        return cell;
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      align: 'center',
      headerAlign: 'center',
      width: 80,
      filterable: false,
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          sx={{ color: 'error.main' }}
          icon={<CloseIcon sx={{ color: 'error.main' }} />}
          label='Remove from team'
          onClick={() => handleRemoveOperative(params.row.id)}
          showInMenu
        />,
      ],
    },
  ];

  return (
    <DashboardTable
      getRowId={Operative => Operative.id}
      columns={columns}
      rows={operatives}
    />
  );
}

export default OperativesTable;
