import { Paper, Typography } from '@mui/material';
import { BarDatum, ResponsiveBar } from '@nivo/bar';
import { format } from 'date-fns';
import { ProductivityReportProps } from '../../../types/ProductivityReportProps';

interface LaborMarginBarChartProps {
  productivityReports: ProductivityReportProps[];
}

function LaborMarginBarChart({
  productivityReports,
}: LaborMarginBarChartProps) {
  const data: BarDatum[] = [];

  productivityReports
    .slice()
    .sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);

      return dateA > dateB ? 1 : -1;
    })
    .forEach(report => {
      const timesheetReportsLength = report.timesheetReports
        ? report.timesheetReports.length
        : 0;
      const totalPerTimesheet = report.timesheetReports
        ? report.timesheetReports.reduce(
            (accumulator, object) => Number(accumulator) + object.total,
            0,
          )
        : 0;

      const poundsPerTimesheetReport =
        timesheetReportsLength * totalPerTimesheet!;

      data.push({
        date: format(new Date(report.createdAt), 'dd/MM/yy HH:mm'),
        laborMargin: report.jobBrief.quote
          ? report.jobBrief.quote.totalValue - poundsPerTimesheetReport
          : 0,
      });
    });

  return (
    <>
      <Typography
        variant='h5'
        sx={{ mt: 3 }}
      >
        Labor Margin in pounds
      </Typography>
      <Paper
        sx={{ mt: 3, height: '500px', backgroundColor: 'white', boxShadow: 3 }}
      >
        <ResponsiveBar
          data={data}
          keys={['laborMargin']}
          indexBy='date'
          margin={{ top: 50, right: 130, bottom: 50, left: 70 }}
          padding={0.3}
          valueScale={{ type: 'linear' }}
          indexScale={{ type: 'band', round: true }}
          colors={{ scheme: 'category10' }}
          axisBottom={{
            legendPosition: 'middle',
            legendOffset: 32,
            legend: 'Time scale',
          }}
          axisLeft={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: 'Pounds',
            legendPosition: 'middle',
            legendOffset: -40,
          }}
          legends={[
            {
              dataFrom: 'keys',
              anchor: 'bottom-right',
              direction: 'column',
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemOpacity: 1,
                  },
                },
              ],
            },
          ]}
          role='application'
        />
      </Paper>
    </>
  );
}

export default LaborMarginBarChart;
