import React, { useState } from 'react';
import {
  IconButton,
  Toolbar,
  Divider,
  Typography,
  List,
  Box,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Menu as MenuIcon, ChevronLeft } from '@mui/icons-material';
import { AppBar, Drawer } from './styles';
import DrawerItem from '../../atoms/DrawerItem';
import UserMenu from '../UserMenu';
import { PagesProps } from '../../../types/PagesProps';

function DesktopMenuBar({ pages }: PagesProps) {
  const navigate = useNavigate();
  const [isDrawerOpen, setOpenDrawer] = useState(false);

  const toggleDrawer = () => {
    setOpenDrawer(!isDrawerOpen);
  };

  return (
    <Box display='flex'>
      <AppBar
        position='fixed'
        open={isDrawerOpen}
      >
        <Toolbar
          sx={{
            pr: '24px',
          }}
        >
          <IconButton
            edge='start'
            color='inherit'
            aria-label='Open drawer'
            onClick={toggleDrawer}
            sx={{
              marginRight: '36px',
              ...(isDrawerOpen && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component='h1'
            variant='h6'
            noWrap
            sx={{ flexGrow: 1, cursor: 'pointer' }}
            onClick={() => navigate('/login')}
          >
            HPS
          </Typography>
          <UserMenu />
        </Toolbar>
      </AppBar>
      <Drawer
        variant='permanent'
        open={isDrawerOpen}
      >
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: [1],
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronLeft />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component='nav'>
          {pages.map(drawerItem => (
            <DrawerItem
              key={drawerItem.title}
              title={drawerItem.title}
              href={drawerItem.href}
            >
              {drawerItem.icon}
            </DrawerItem>
          ))}
        </List>
      </Drawer>
    </Box>
  );
}

export default DesktopMenuBar;
