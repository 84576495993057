/* eslint-disable no-unused-vars */
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { SxProps, Theme } from '@mui/material';

interface DateFieldProps {
  label?: string;
  value?: Date | null;
  minDate?: Date | null;
  maxDate?: Date | null;
  onChange?: (value: Date | null) => void;
  sx?: SxProps<Theme>;
}

function DateField({
  label,
  value,
  minDate,
  maxDate,
  onChange,
  sx,
}: DateFieldProps) {
  return (
    <DesktopDatePicker
      format='dd/MM/yyyy'
      label={label}
      value={value}
      minDate={minDate || undefined}
      maxDate={maxDate || undefined}
      onChange={onChange!}
      sx={sx}
    />
  );
}

export default DateField;
