import { useEffect, useState } from 'react';
import api from '../services/api';
import { TeamProps } from '../types/TeamProps';

const useTeams = () => {
  const [teams, setTeams] = useState([] as TeamProps[]);

  const getAllTeams = async () => {
    const response = await api.get('/teams');
    if (response.status === 200) {
      const { data } = response;
      const parsedTeams = data.teams.map((team: TeamProps) => {
        const isSupervisor = team.users?.find(
          user => user.id === team.supervisorId,
        );
        if (isSupervisor) {
          return {
            ...team,
            supervisorName: `${isSupervisor.firstName} ${isSupervisor.lastName}`,
          };
        }
        return team;
      });
      setTeams(parsedTeams);
    }
  };

  useEffect(() => {
    getAllTeams();
  }, []);

  return {
    teams,
    getAllTeams,
  };
};
export default useTeams;
