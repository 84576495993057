import { Edit, DeleteForever } from '@mui/icons-material';
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid';
import { toast } from 'material-react-toastify';
import convertDecimalToTime from '../../../utils/convertDecimalToTime';
import DashboardTable from '../../atoms/DashboardTable';
import { EntityTaskProps } from '../../../types/EntityTaskProps';
import { useDialog } from '../../../contexts/DialogContext';
import ClientTaskDetailsDialog from '../ClientTaskDetailsDialog';
import api from '../../../services/api';

interface ClientTasksTableProps {
  clientTasks: EntityTaskProps[];
  refreshClient: Function;
}

function ClientTasksTable({
  clientTasks,
  refreshClient,
}: ClientTasksTableProps) {
  const { showDialog } = useDialog();

  const handleDeleteClientTask = async (id: number) => {
    const response = await api.delete('/clientTask', {
      data: { id },
    });

    if (response.status === 200) {
      toast.success('Task removed successfully!');
      refreshClient();
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      type: 'string',
      maxWidth: 80,
      align: 'center',
      headerAlign: 'center',
      filterable: false,
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 230,
      filterable: true,
    },

    {
      field: 'price',
      headerName: 'Price',
      width: 230,
      filterable: true,
      renderCell: (params: GridRenderCellParams) =>
        params.row.price ? params.row.price : 'No price set',
    },
    {
      field: 'time',
      headerName: 'Execution time',
      width: 100,
      flex: 1,
      renderCell: (params: GridRenderCellParams) => {
        const { time } = params.row;
        return time ? convertDecimalToTime(time) : 'No time set';
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      align: 'center',
      headerAlign: 'center',
      width: 80,
      filterable: false,
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          icon={<Edit />}
          label='Edit'
          onClick={() =>
            showDialog({
              componentToRender: (
                <ClientTaskDetailsDialog
                  clientTask={params.row}
                  refreshClient={refreshClient}
                />
              ),
            })
          }
        />,
        <GridActionsCellItem
          sx={{ color: 'error.main' }}
          icon={<DeleteForever sx={{ color: 'error.main' }} />}
          label='Remove task'
          onClick={() => handleDeleteClientTask(params.row.id)}
          showInMenu
        />,
      ],
    },
  ];

  return (
    <DashboardTable
      getRowId={ClientTask => ClientTask.id}
      columns={columns}
      rows={clientTasks}
    />
  );
}

export default ClientTasksTable;
