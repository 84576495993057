import {
  GridColDef,
  GridRenderCellParams,
  DataGridProps,
} from '@mui/x-data-grid';
import { ProductivityReportProps } from '../../../types/ProductivityReportProps';
import { UserProps } from '../../../types/UserProps';
import formatDate from '../../../utils/formatDate';
import DashboardTable from '../../atoms/DashboardTable';
import ExportTableToolbar from '../../atoms/ExportTableToolbar';

interface ProductivityReportTableProps {
  productivityReports: ProductivityReportProps[];
}

function ProductivityReportTable({
  productivityReports,
}: ProductivityReportTableProps) {
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Job Brief',
      headerAlign: 'center',
      width: 108,
      align: 'center',
      filterable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { jobBrief } = params.row;
        return jobBrief.id;
      },
    },
    {
      field: 'quoteId',
      headerName: 'Contract',
      headerAlign: 'center',
      width: 78,
      align: 'center',
      filterable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { quote } = params.row.jobBrief;
        return quote.id;
      },
    },
    {
      field: 'visitId',
      type: 'date',
      headerName: 'Visit',
      headerAlign: 'center',
      align: 'center',
      width: 144,
      filterable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { startDate } = params.row.visit;
        return startDate;
      },
      valueFormatter: params => formatDate(params),
    },
    {
      field: 'serviceName',
      headerName: 'Service',
      headerAlign: 'center',
      align: 'center',
      flex: 1,
      filterable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { serviceName } = params.row.visit;
        return serviceName;
      },
    },
    {
      field: 'totalValue',
      headerName: 'Revenue',
      align: 'center',
      headerAlign: 'center',
      width: 75,
      filterable: false,
      renderCell: (params: GridRenderCellParams) => {
        const { totalValue } = params.row.jobBrief.quote;
        return totalValue;
      },
    },
    {
      field: 'clientId',
      headerName: 'Client',
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      filterable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { client } = params.row.visit.building;
        return client.reference;
      },
    },
    {
      field: 'buildingId',
      headerName: 'Building',
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      filterable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { building } = params.row.visit;
        return building.name;
      },
    },
    {
      field: 'teamId',
      headerName: 'Team',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      filterable: true,
      renderCell: (params: GridRenderCellParams) =>
        params.row.visit.team ? params.row.visit.team.name : 'No team assigned',
    },
    {
      field: 'supervisorId',
      headerName: 'Supervisor',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      filterable: true,
      renderCell: (params: GridRenderCellParams) => {
        const { team } = params.row.visit;

        const supervisor = team.users.find(
          (user: UserProps) => user.id === team.supervisorId,
        );

        return supervisor.firstName;
      },
    },
    {
      field: 'createdAt',
      headerName: 'Date',
      type: 'date',
      align: 'center',
      headerAlign: 'center',
      width: 144,
      filterable: true,
      valueFormatter: params => formatDate(params),
    },
  ];

  return (
    <DashboardTable
      getRowId={Visit => Visit.id}
      columns={columns}
      rows={productivityReports}
      slots={{
        toolbar: ExportTableToolbar as DataGridProps['slots'] as any,
      }}
    />
  );
}

export default ProductivityReportTable;
