import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { toast } from 'material-react-toastify';
import { useState, ChangeEvent, FormEvent } from 'react';
import { useDialog } from '../../../contexts/DialogContext';
import api from '../../../services/api';
import { ClientProps } from '../../../types/ClientProps';
import regexPatterns from '../../../utils/regexPatterns';

interface ClientCreateProps {
  getAllClients: Function;
  client?: ClientProps;
}

const initialValues: ClientProps = {
  id: 0,
  reference: '',
  headOfficeAddress: '',
  companyName: '',
  headOfficeEmail: '',
  headOfficePhone: '',
};

function ClientDetailsDialog({ getAllClients, client }: ClientCreateProps) {
  const [clientData, setClientData] = useState(client || initialValues);

  type ObjectKey = keyof typeof clientData;

  const inputs = [
    {
      name: 'reference',
      type: 'text',
      label: 'Client reference',
      message: "Client reference shouldn't include any special character!",
      pattern: regexPatterns.spacedAlphanumeric,
      sx: { mb: 2 },
    },
    {
      name: 'headOfficeAddress',
      type: 'text',
      label: 'Head office address',
      message: "Head office address shouldn't include any special character!",
      pattern: regexPatterns.spacedAlphanumeric,
      sx: { mb: 2 },
    },
    {
      name: 'companyName',
      type: 'text',
      label: 'Company Name',
      message: "Company Name shouldn't include any special character!",
      pattern: regexPatterns.spacedAlphanumeric,
      sx: { mb: 2 },
    },
    {
      name: 'headOfficeEmail',
      type: 'email',
      label: 'Head office email (Optional)',
      message: 'Invalid email address!',
      pattern: regexPatterns.email,
      optional: 1,
      sx: { mb: 2 },
    },
    {
      name: 'headOfficePhone',
      type: 'text',
      label: 'Head office phone (Optional)',
      message: 'Invalid phone!',
      pattern: regexPatterns.phone,
      optional: 1,
    },
  ];

  const isFormInvalid = inputs.some(input => {
    const inputName = input.name as ObjectKey;
    const inputPattern = clientData[inputName]
      ? clientData[inputName]!.toString()
      : '';
    if (input.optional) {
      if (inputPattern.length > 0) return !input.pattern.test(inputPattern);
      return null;
    }
    return !input.pattern.test(inputPattern);
  });

  const hasUpdatedFields =
    JSON.stringify(clientData) !== JSON.stringify(client);

  const canSubmit = client
    ? hasUpdatedFields && !isFormInvalid
    : !isFormInvalid;

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setClientData({
      ...clientData,
      [e.target.name]: e.target.value ? e.target.value : null,
    });
  };

  const { toggleDialog } = useDialog();

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();

    const response = client
      ? await api.put('/client', clientData)
      : await api.post('/client', clientData);

    if (response.status === 200) {
      toast.success(`Client ${client ? 'updated' : 'registered'} sucessfully!`);
      getAllClients();
    }

    toggleDialog();
  }

  return (
    <form onSubmit={handleSubmit}>
      {!client && <DialogTitle>Register client</DialogTitle>}
      <DialogContent sx={{ width: '100%', maxWidth: '450px' }}>
        {inputs.map(input => {
          const inputName = input.name as ObjectKey;
          const inputValue = clientData[inputName]
            ? clientData[inputName]!.toString()
            : '';
          const hasError =
            !!clientData[inputName] && !input.pattern.test(inputValue);
          const errorMessage = hasError ? input.message : '';

          return (
            <TextField
              key={input.name}
              value={inputValue}
              size='small'
              variant='standard'
              fullWidth
              onChange={onChange}
              error={hasError}
              helperText={errorMessage}
              {...input}
            />
          );
        })}
      </DialogContent>
      <DialogActions>
        <Button
          color='error'
          type='button'
          onClick={() => toggleDialog()}
        >
          Close
        </Button>
        <Button
          type='submit'
          disabled={!canSubmit}
        >
          {client ? 'Update' : 'Create'}
        </Button>
      </DialogActions>
    </form>
  );
}

export default ClientDetailsDialog;
