import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import DashboardTitle from '../../../components/atoms/DashboardTitle';
import BillingsTable from '../../../components/molecules/BillingsTable';

function DashboardBillings() {
  const navigate = useNavigate();

  return (
    <>
      <DashboardTitle title='Billings' />
      <Button
        variant='contained'
        endIcon={<AddIcon />}
        sx={{ my: 2 }}
        onClick={() => navigate('/dashboard/billings/add')}
      >
        Register Billing
      </Button>
      <BillingsTable />
    </>
  );
}

export default DashboardBillings;
