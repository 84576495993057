import React, { useState, FormEvent } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Grid,
  TextField,
  Autocomplete,
  FormControl,
  Box,
  IconButton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { toast } from 'material-react-toastify';
import isEmpty from '../../../utils/hasEmptyProperties';
import useServices from '../../../hooks/useServices';
import { ServiceProps } from '../../../types/ServiceProps';
import { TaskProps } from '../../../types/TaskProps';
import api from '../../../services/api';
import { useDialog } from '../../../contexts/DialogContext';
import ServicesManagerDialog from '../ServicesManagerDialog';

const initialValues: TaskProps = {
  id: 0,
  name: '',
  serviceId: 0,
};

function ServiceTaskForm() {
  const { showDialog } = useDialog();
  const { services, getAllServices } = useServices({ unhiddenOnly: false });
  const navigate = useNavigate();

  const parsedServices = services.map((service: ServiceProps) => ({
    id: service.id!,
    name: service.name,
  }));

  const [taskData, setTaskData] = useState<TaskProps>(initialValues);

  const noEmptyFields = isEmpty([taskData?.serviceId, taskData?.name]);

  const canSubmit = noEmptyFields;

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const response = await api.post('/task', taskData);

    if (response.status === 200) {
      toast.success('Task registered successfully!');
      navigate('/dashboard/services');
    }
  };

  return (
    <Card elevation={3}>
      <CardHeader
        sx={{
          bgcolor: 'secondary.main',
          color: 'white',
          px: 3,
          py: 2,
        }}
        titleTypographyProps={{ variant: 'h6', fontWeight: 'normal' }}
        title={
          <Grid
            container
            direction='row'
            alignItems='center'
            gap={1}
          >
            Add task
          </Grid>
        }
      />
      <CardContent sx={{ p: 3 }}>
        <form onSubmit={handleSubmit}>
          <FormControl fullWidth>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Autocomplete
                key={JSON.stringify(services)}
                options={parsedServices || undefined}
                fullWidth
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(e, value) =>
                  setTaskData({ ...taskData, serviceId: value?.id as number })
                }
                getOptionLabel={option => option.name}
                renderInput={params => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    fullWidth
                    margin='dense'
                    variant='standard'
                    label='Service type'
                    sx={{ mb: 2 }}
                  />
                )}
              />
              <IconButton
                color='primary'
                sx={{
                  minWidth: 'fit-content',
                  my: 2,
                  ml: 2,
                }}
                onClick={() =>
                  showDialog({
                    componentToRender: (
                      <ServicesManagerDialog
                        services={services}
                        getAllServices={getAllServices}
                      />
                    ),
                    onClose: getAllServices,
                  })
                }
              >
                <EditIcon />
              </IconButton>
            </Box>
            <TextField
              label='Task name'
              variant='standard'
              sx={{ mb: 2 }}
              fullWidth
              onChange={e =>
                setTaskData({
                  ...taskData,
                  name: e.target.value,
                })
              }
            />
            <Button
              type='submit'
              variant='contained'
              disabled={!canSubmit}
              sx={{ mt: 3, bgcolor: 'secondary.main', boxShadow: 3 }}
            >
              Submit
            </Button>
          </FormControl>
        </form>
      </CardContent>
    </Card>
  );
}

export default ServiceTaskForm;
