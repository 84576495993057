import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DashboardTitle from '../../../components/atoms/DashboardTitle';
import ClientsTable from '../../../components/molecules/ClientsTable';
import ClientDetailsDialog from '../../../components/molecules/ClientDetailsDialog';
import { useDialog } from '../../../contexts/DialogContext';
import useClients from '../../../hooks/useClients';

function Clients() {
  const { showDialog } = useDialog();
  const { getAllClients, clients } = useClients();

  return (
    <>
      <DashboardTitle title='Clients' />
      <Button
        variant='contained'
        endIcon={<AddIcon />}
        sx={{ my: 2 }}
        onClick={() =>
          showDialog({
            componentToRender: (
              <ClientDetailsDialog getAllClients={getAllClients} />
            ),
          })
        }
      >
        Register Client
      </Button>
      <ClientsTable
        clients={clients}
        getAllClients={getAllClients}
      />
    </>
  );
}

export default Clients;
