import { useEffect, useState } from 'react';
import api from '../services/api';
import { DriverTimesheetProps } from '../types/DriverTimesheetProps';

const useDriverTimesheets = () => {
  const [driverTimesheets, setDriverTimesheets] = useState(
    [] as DriverTimesheetProps[],
  );

  const getAllDriverTimesheets = async () => {
    const response = await api.get('/driverTimesheets');
    if (response.status === 200) {
      setDriverTimesheets(response.data.driverTimesheets);
    }
  };

  useEffect(() => {
    getAllDriverTimesheets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    driverTimesheets,
    getAllDriverTimesheets,
  };
};
export default useDriverTimesheets;
