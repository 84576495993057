import { Divider, Grid, Typography } from '@mui/material';

interface DataListProps {
  data: { label: string; value: string | number | undefined }[];
}
export default function DataList({ data }: DataListProps) {
  return (
    <Grid
      container
      columns={16}
      sx={{
        mt: 0.5,
        border: 1,
        borderColor: 'primary.main',
        borderWidth: 2,
        borderRadius: 2,
        '.MuiGrid-item': {
          p: 1.5,
        },
      }}
    >
      {data.map(clientInfo => (
        <Grid
          item
          xs={8}
        >
          <Typography
            variant='h5'
            fontSize={14}
            display='flex'
            alignItems='center'
            className='hey'
          >
            {`${clientInfo.label}: `}
            <Typography
              variant='subtitle2'
              fontWeight={300}
              sx={{ ml: 1 }}
            >
              {clientInfo.value}
            </Typography>
          </Typography>
          <Divider />
        </Grid>
      ))}
    </Grid>
  );
}
