import { Paper, Typography } from '@mui/material';
import { ResponsiveLine } from '@nivo/line';
import { format } from 'date-fns';
import { ProductivityReportProps } from '../../../types/ProductivityReportProps';

interface LaborMarginLineChartProps {
  productivityReports: ProductivityReportProps[];
}

function LaborMarginLineChart({
  productivityReports,
}: LaborMarginLineChartProps) {
  const data: { x: string; y: number }[] = [];

  let showMaxValue = false;

  productivityReports
    .slice()
    .sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);

      return dateA > dateB ? 1 : -1;
    })
    .forEach(report => {
      const totalPerTimesheet = report.timesheetReports
        ? report.timesheetReports.reduce(
            (accumulator, object) => Number(accumulator) + object.total,
            0,
          )
        : 0;

      showMaxValue = totalPerTimesheet > report.jobBrief!.quote!.totalValue;

      return data.push({
        x: format(new Date(report.createdAt), 'yyyy-MM-dd HH:mm'),
        y: report.jobBrief.quote
          ? (totalPerTimesheet / report.jobBrief.quote.totalValue) * 100
          : 0,
      });
    });

  return (
    <>
      <Typography
        variant='h5'
        sx={{ mt: 3 }}
      >
        Labor Margin in percents
      </Typography>
      <Paper
        sx={{ mt: 3, height: '500px', backgroundColor: 'white', boxShadow: 3 }}
      >
        <ResponsiveLine
          data={[{ id: 'laborMarginData', data }]}
          margin={{ top: 50, right: 50, bottom: 50, left: 60 }}
          colors={{ scheme: 'accent' }}
          xScale={{
            type: 'time',
            format: '%Y-%m-%d %H:%M',
            precision: 'minute',
          }}
          useMesh
          xFormat='time:%d/%m/%Y'
          yScale={{
            stacked: false,
            max: showMaxValue ? 100 : 'auto',
            min: 0,
            type: 'linear',
          }}
          axisLeft={{
            legend: 'Percentage Scale',
            legendOffset: 12,
            format: v => `${v}%`,
          }}
          axisBottom={{
            format: '%d/%m/%Y',
            legend: 'Time scale',
          }}
          pointSize={16}
          pointBorderWidth={1}
          pointBorderColor={{
            from: 'color',
            modifiers: [['darker', 0.3]],
          }}
          enableSlices={false}
        />
      </Paper>
    </>
  );
}

export default LaborMarginLineChart;
