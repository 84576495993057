import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import {
  DialogContent,
  TextField,
  Button,
  DialogActions,
  Autocomplete,
  DialogTitle,
} from '@mui/material';
import { toast } from 'material-react-toastify';
import api from '../../../services/api';
import { BuildingProps } from '../../../types/BuildingProps';
import { ClientOptionProps } from '../../../types/ClientProps';
import { BillingOptionProps } from '../../../types/BillingProps';
import { useDialog } from '../../../contexts/DialogContext';
import isEmpty from '../../../utils/hasEmptyProperties';
import useClients from '../../../hooks/useClients';
import regexPatterns from '../../../utils/regexPatterns';
import { InputProps } from '../../../types/InputProps';

interface BuildingDetailsDialogProps {
  getAllBuildings: Function;
  building?: BuildingProps;
}

const initialValues: BuildingProps = {
  id: 0,
  name: '',
  address: '',
  city: '',
  phone: '',
  email: '',
  clientId: 0,
  billingId: null,
};

function BuildingDetailsDialog({
  getAllBuildings,
  building,
}: BuildingDetailsDialogProps) {
  const { toggleDialog } = useDialog();
  const { clients } = useClients();

  const inputs: InputProps[] = [
    {
      name: 'name',
      type: 'text',
      label: 'Name',
      message: "Name shouldn't include any special character!",
      pattern: regexPatterns.spacedAlphanumeric,
      sx: { mb: 2 },
    },
    {
      name: 'address',
      type: 'text',
      label: 'Address',
      message: "Address shouldn't include any special character!",
      pattern: regexPatterns.spacedAlphanumeric,
      sx: { mb: 2 },
    },
    {
      name: 'city',
      type: 'text',
      label: 'City',
      message: "City shouldn't include any special character!",
      pattern: regexPatterns.spacedAlphanumeric,
      sx: { mb: 2 },
    },
    {
      name: 'phone',
      type: 'text',
      label: 'Building phone number (Optional)',
      message: 'Invalid phone!',
      pattern: regexPatterns.phone,
      optional: 1,
      sx: { mb: 2 },
    },
    {
      name: 'email',
      type: 'email',
      label: 'Building email (Optional)',
      message: 'Invalid email address!',
      pattern: regexPatterns.email,
      optional: 1,
      sx: { mb: 1 },
    },
  ];

  const [buildingData, setBuildingData] = useState<BuildingProps>(
    building || initialValues,
  );

  type ObjectKey = keyof typeof buildingData;

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setBuildingData({
      ...buildingData,
      [e.target.name]: e.target.value ? e.target.value : null,
    });
  };

  const [clientOption, setClientOption] = useState<ClientOptionProps>();
  const [billings, setBillings] = useState<BillingOptionProps[]>();
  const [billingOption, setBillingOption] = useState<BillingOptionProps | null>(
    null,
  );

  useEffect(() => {
    setBillings(
      clients.find(client => client.id === clientOption?.id)?.billings,
    );
  }, [clientOption?.id, clients]);

  useEffect(() => {
    if (building?.client)
      setClientOption({
        id: building?.clientId,
        reference: building?.client.reference,
      });
  }, [building?.client, building?.clientId]);

  useEffect(() => {
    if (building?.billing)
      setBillingOption({
        id: building?.billingId,
        companyName: building?.billing.companyName,
      });
  }, [building?.billing, building?.billingId]);

  const isFormInvalid = inputs.some(input => {
    const inputName = input.name as ObjectKey;
    const inputPattern = buildingData[inputName]
      ? buildingData[inputName]!.toString()
      : '';
    if (input.optional) {
      if (inputPattern.length > 0) return !input.pattern.test(inputPattern);
      return null;
    }
    return !input.pattern.test(inputPattern);
  });

  const noEmptyFields = isEmpty([
    buildingData?.name,
    buildingData?.address,
    buildingData?.city,
    clientOption?.id,
  ]);

  const hasUpdatedFields =
    JSON.stringify(buildingData) !== JSON.stringify(building);

  const canSubmit = building
    ? hasUpdatedFields && noEmptyFields && !isFormInvalid
    : noEmptyFields && !isFormInvalid;

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    const response = building
      ? await api.put('/building', buildingData)
      : await api.post('/building', buildingData);

    if (response.status === 200) {
      toast.success(
        `Building ${building ? 'updated' : 'registered'} sucessfully!`,
      );
      getAllBuildings();
    }

    toggleDialog();
  };

  return (
    <form onSubmit={handleSubmit}>
      {!building && <DialogTitle>Register building</DialogTitle>}
      <DialogContent sx={{ width: '100%', maxWidth: '450px' }}>
        {inputs.map(input => {
          const inputName = input.name as ObjectKey;
          const inputValue = buildingData[inputName]
            ? buildingData[inputName]!.toString()
            : '';
          const hasError =
            !!buildingData[inputName] && !input.pattern.test(inputValue);
          const errorMessage = hasError ? input.message : '';

          return (
            <TextField
              key={input.name}
              value={buildingData[inputName] || ''}
              size='small'
              variant='standard'
              fullWidth
              onChange={onChange}
              error={hasError}
              helperText={errorMessage}
              {...input}
            />
          );
        })}
        <Autocomplete
          options={clients || []}
          value={clientOption || null}
          onChange={(e, value) => {
            if (value?.id && value.reference) {
              setClientOption({ id: value.id, reference: value.reference });
              setBuildingData({
                ...buildingData,
                clientId: value.id,
                billingId: null,
              });
              setBillingOption(null);
            }
          }}
          fullWidth
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={option => option.reference}
          renderInput={params => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              margin='dense'
              variant='standard'
              label='Client'
            />
          )}
        />
        <Autocomplete
          options={billings || []}
          value={billingOption || null}
          onChange={(e, value) => {
            if (value?.id && value.companyName) {
              setBillingOption({
                id: value.id,
                companyName: value.companyName,
              });
              setBuildingData({
                ...buildingData,
                billingId: value.id,
              });
            }
          }}
          fullWidth
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={option => option.companyName}
          renderInput={params => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              sx={{ mt: 1.7, mb: 2 }}
              margin='dense'
              variant='standard'
              label='Billing'
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button
          type='button'
          sx={{ color: 'error.main' }}
          onClick={() => toggleDialog()}
        >
          Cancel
        </Button>
        <Button
          type='submit'
          disabled={!canSubmit}
        >
          {building ? 'Update' : 'Register'}
        </Button>
      </DialogActions>
    </form>
  );
}

export default BuildingDetailsDialog;
