import React from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardTitle from '../../../../components/atoms/DashboardTitle';
import ReportForm from '../../../../components/molecules/ReportForm';
import api from '../../../../services/api';
import { ReportFilterProps } from '../../../../types/ReportFilterProps';
import { TimesheetReportProps } from '../../../../types/TimesheetReportProps';

function DashboardTimesheetReport() {
  const navigate = useNavigate();

  const handleSubmit = async (reportData: ReportFilterProps) => {
    const response = await api.post('/timesheetReports', reportData);

    if (response.status === 200) {
      const { timesheetReports } = response.data;
      navigate('/dashboard/timesheetReport/details', {
        state: timesheetReports as TimesheetReportProps[],
      });
    }
  };

  return (
    <>
      <DashboardTitle
        title='Timesheet Report'
        hasBackButton
      />
      <ReportForm
        requiredFields={['client']}
        handleSubmit={handleSubmit}
      />
    </>
  );
}

export default DashboardTimesheetReport;
