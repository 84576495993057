import React, { useState, FormEvent } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Grid,
  TextField,
} from '@mui/material';
import LockResetIcon from '@mui/icons-material/LockReset';
import { useNavigate } from 'react-router-dom';
import { toast } from 'material-react-toastify';
import api from '../../services/api';
import regexPatterns from '../../utils/regexPatterns';
import getErrorMessage, {
  ResponseErrorMessage,
} from '../../utils/getErrorMessage';

function ForgotPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');

  const input = {
    autoFocus: true,
    name: 'email',
    type: 'email',
    label: 'Enter your email',
    message: 'Invalid email address!',
    pattern: regexPatterns.email,
    sx: { mb: 2 },
  };

  const isFormInvalid = !input.pattern.test(email);
  const hasError = !!email && isFormInvalid;

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    toast.dismiss();

    try {
      const response = await api.post('/forgotPassword', { email });

      if (response.status === 200) {
        toast.success('Reset password request sent to your email!');
        navigate('/login');
      }
    } catch (error) {
      toast.dismiss();
      toast.error(getErrorMessage(error as ResponseErrorMessage));
    }
  };

  return (
    <Card
      sx={{ maxWidth: 400, width: '100%', mx: 'auto', mt: 7 }}
      elevation={3}
    >
      <CardHeader
        sx={{
          bgcolor: 'secondary.main',
          color: 'white',
          px: 3,
          py: 2,
        }}
        titleTypographyProps={{ variant: 'h6', fontWeight: 'normal' }}
        title={
          <Grid
            container
            direction='row'
            alignItems='center'
            gap={1}
          >
            <LockResetIcon />
            Recover password
          </Grid>
        }
      />
      <CardContent sx={{ p: 3 }}>
        <form onSubmit={handleSubmit}>
          <TextField
            value={email}
            size='small'
            variant='standard'
            onChange={e => setEmail(e.target.value)}
            error={hasError}
            helperText={hasError ? input.message : ''}
            fullWidth
            {...input}
          />
          <Button
            type='submit'
            variant='contained'
            disabled={isFormInvalid}
            sx={{ mb: 2, bgcolor: 'secondary.main', boxShadow: 3 }}
            fullWidth
          >
            Recover
          </Button>
        </form>
      </CardContent>
    </Card>
  );
}

export default ForgotPassword;
