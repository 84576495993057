import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { ToastContainer } from 'material-react-toastify';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import customTheme from './styles/customTheme';
import Routes from './Routes';
import 'material-react-toastify/dist/ReactToastify.css';
import UserProvider from './contexts/UserContext';
import DialogContext from './contexts/DialogContext';

function App() {
  return (
    <div className='App'>
      <BrowserRouter>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <UserProvider>
            <ThemeProvider theme={customTheme}>
              <DialogContext>
                <Routes />
              </DialogContext>
              <ToastContainer
                position='top-center'
                autoClose={4000}
              />
            </ThemeProvider>
          </UserProvider>
        </LocalizationProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
