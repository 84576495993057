import { Place } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { VisitProps } from '../../../types/VisitProps';
import formatDate from '../../../utils/formatDate';
import ItemsList from '../ItemsList';

interface VisitsListProps {
  visits: VisitProps[];
  title: string;
}

export default function VisitsList({ visits, title }: VisitsListProps) {
  const navigate = useNavigate();

  const visitItems =
    visits.map(visit => ({
      id: visit.id,
      date: visit.startDate
        ? formatDate(visit.startDate)
        : 'Visit without date',
    })) || [];
  return (
    <ItemsList
      icon={<Place sx={{ ml: 1.5 }} />}
      title={title}
      items={visitItems.map(visit => visit.date)}
      onClickItem={(date: string) => {
        const item = visitItems.find(visit => visit.date === date);
        if (!item) return;
        navigate(`/dashboard/visits/${item.id}`);
      }}
    />
  );
}
