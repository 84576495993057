import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import ApartmentIcon from '@mui/icons-material/Apartment';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useNavigate } from 'react-router-dom';
import { toast } from 'material-react-toastify';
import { BuildingProps } from '../../../types/BuildingProps';
import { useDialog } from '../../../contexts/DialogContext';
import BuildingDetailsDialog from '../BuildingDetailsDialog';
import api from '../../../services/api';
import DashboardTable from '../../atoms/DashboardTable';

interface BuildingsTableProps {
  buildings: BuildingProps[];
  getAllBuildings: Function;
}

function BuildingsTable({ buildings, getAllBuildings }: BuildingsTableProps) {
  const { showDialog } = useDialog();
  const navigate = useNavigate();

  const handleDeleteBuilding = async (id: number) => {
    const response = await api.delete('/building', {
      data: { id },
    });

    if (response.status === 200) {
      toast.success(`Building deleted successfully!`);
      getAllBuildings();
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      type: 'string',
      maxWidth: 80,
      align: 'center',
      headerAlign: 'center',
      filterable: false,
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      filterable: true,
    },
    {
      field: 'address',
      headerName: 'Address',
      width: 280,
    },
    {
      field: 'city',
      headerName: 'City',
      width: 160,
    },
    {
      field: 'clientId',
      headerName: 'Client',
      width: 160,
      filterable: true,
      renderCell: (params: GridRenderCellParams) =>
        params.row.client ? params.row.client.reference : null,
    },
    {
      field: 'billingId',
      headerName: 'Billing',
      width: 160,
      filterable: true,
      renderCell: (params: GridRenderCellParams) =>
        params.row.billing ? params.row.billing.companyName : null,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      align: 'center',
      headerAlign: 'center',
      width: 80,
      filterable: false,
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          icon={<EditIcon />}
          label='Edit'
          onClick={() =>
            showDialog({
              componentToRender: (
                <BuildingDetailsDialog
                  getAllBuildings={getAllBuildings}
                  building={params.row}
                />
              ),
            })
          }
        />,
        <GridActionsCellItem
          icon={<ApartmentIcon />}
          label='See details'
          onClick={() => navigate(`/dashboard/buildings/${params.row.id}`)}
          showInMenu
        />,
        <GridActionsCellItem
          sx={{ color: 'error.main' }}
          icon={<DeleteForeverIcon sx={{ color: 'error.main' }} />}
          label='Delete'
          onClick={() => handleDeleteBuilding(params.row.id)}
          showInMenu
        />,
      ],
    },
  ];

  return (
    <DashboardTable
      getRowId={Building => Building.id}
      columns={columns}
      rows={buildings}
    />
  );
}

export default BuildingsTable;
