import { PictureAsPdf } from '@mui/icons-material';
import { Button, useMediaQuery, useTheme } from '@mui/material';
import { ReactNode } from 'react';
import { useDialog } from '../../../contexts/DialogContext';
import PDFDialog from '../../molecules/PDFDialog';

interface PDFButtonProps {
  component: ReactNode;
  documentTitle: string;
  label: string;
}

export default function PDFButton({
  component,
  documentTitle,
  label,
}: PDFButtonProps) {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  const { showDialog } = useDialog();

  return (
    <Button
      variant='contained'
      fullWidth={isMobile}
      onClick={() =>
        showDialog({
          componentToRender: (
            <PDFDialog
              component={component}
              documentTitle={documentTitle}
            />
          ),
        })
      }
      sx={{ my: 2 }}
      endIcon={<PictureAsPdf />}
    >
      {label}
    </Button>
  );
}
