import DashboardTitle from '../../../components/atoms/DashboardTitle';
import RegisterForm from '../../../components/molecules/RegisterForm';

function DashboardRegister() {
  return (
    <>
      <DashboardTitle title='Users' />
      <RegisterForm />
    </>
  );
}

export default DashboardRegister;
