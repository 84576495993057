import { useEffect, useState } from 'react';
import DashboardTitle from '../../../components/atoms/DashboardTitle';
import UsersTable from '../../../components/molecules/UsersTable';
import api from '../../../services/api';

function AllOperatives() {
  const [operatives, setOperatives] = useState([]);
  const getOperativesBySupervisor = async () => {
    const response = await api.get('/users/operative');
    if (response.status === 200) setOperatives(response.data.users);
  };

  useEffect(() => {
    getOperativesBySupervisor();
  }, []);
  return (
    <>
      <DashboardTitle
        title='All operatives'
        hasBackButton
      />
      <UsersTable users={operatives} />
    </>
  );
}

export default AllOperatives;
