import React from 'react';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import formatDate from '../../../utils/formatDate';
import DashboardTable from '../../atoms/DashboardTable';

type Supervisor = {
  referenceNumber: string;
  firstName: string;
  lastName: string;
};
type ProductivityReport = {
  team: string;
  date: string;
  productivity: number;
  timesheetsIds: string[];
  visitsIds: string[];
  supervisors: Supervisor[];
};

interface ProductivityTableProps {
  reports: ProductivityReport[];
}

const columns: GridColDef[] = [
  {
    field: 'date',
    headerName: 'Reference date',
    type: 'string',
    width: 250,
    align: 'center',
    headerAlign: 'center',
    filterable: false,
    renderCell: (params: GridRenderCellParams) => {
      const { row } = params;
      return formatDate(row.date, true);
    },
  },
  {
    field: 'supervisor',
    headerName: 'Supervisor',
    flex: 1,
    filterable: false,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      const { supervisors } = params.row;
      const displayNames = supervisors.map((supervisor: Supervisor) => {
        const { referenceNumber, firstName, lastName } = supervisor;
        return `${referenceNumber} - ${firstName} ${lastName}`;
      });
      return displayNames.join(', ');
    },
  },
  {
    field: 'team',
    headerName: 'Team Name',
    flex: 1,
    filterable: false,
    sortable: false,
  },
  {
    field: 'productivity',
    headerName: 'Productivity',
    width: 200,
    align: 'center',
    headerAlign: 'center',
    filterable: false,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      const { productivity } = params.row;
      if (!productivity || productivity === '-') return 'N/A';
      return `${productivity}%`;
    },
  },
  {
    field: 'timesheetsIds',
    headerName: 'Timesheets',
    align: 'center',
    headerAlign: 'center',
    flex: 1,
    filterable: false,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      const ids = params.row.timesheetsIds as string[];

      return (
        <>
          {ids.map((id, index) => (
            <React.Fragment key={id}>
              <a
                style={{ color: '#263262', textDecoration: 'none' }}
                href={`/dashboard/timesheets/${id}`}
              >{`timesheet ${id}`}</a>
              {index < ids.length - 1 && ', '}
            </React.Fragment>
          ))}
        </>
      );
    },
  },
  {
    field: 'visitsIds',
    headerName: 'Visits',
    align: 'center',
    headerAlign: 'center',
    flex: 1,
    filterable: false,
    sortable: false,
    renderCell: (params: GridRenderCellParams) => {
      const ids = params.row.visitsIds as string[];

      return (
        <>
          {ids.map((id, index) => (
            <React.Fragment key={id}>
              <a
                style={{ color: '#263262', textDecoration: 'none' }}
                href={`/dashboard/visits/${id}`}
              >{`visit ${id}`}</a>
              {index < ids.length - 1 && ', '}
            </React.Fragment>
          ))}
        </>
      );
    },
  },
];

export default function ProductivityTable({ reports }: ProductivityTableProps) {
  return (
    <DashboardTable
      getRowId={row => `${new Date(row.date).getTime()}_${row.team}`}
      sortModel={[{ field: 'date', sort: 'desc' }]}
      columns={columns}
      rows={reports}
      slots={{
        toolbar: () => null,
      }}
    />
  );
}
