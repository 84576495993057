import { toast } from 'material-react-toastify';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import DashboardTitle from '../../../../../components/atoms/DashboardTitle';
import ProgressReportsTable from '../../../../../components/molecules/ProgressReportsTable';
import { ProgressReportProps } from '../../../../../types/ProgressReportProps';

function DashboardProgressReportDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const progressReports = location.state as ProgressReportProps[];

  useEffect(() => {
    toast.dismiss();
    if (!progressReports.length) {
      navigate('/dashboard/progressReport');
      toast.info('No results found!');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DashboardTitle title='Progress Reports' />
      {progressReports && (
        <ProgressReportsTable
          progressReports={progressReports}
          showComments
        />
      )}
    </>
  );
}

export default DashboardProgressReportDetails;
