import { Box, Typography } from '@mui/material';
import { useUser } from '../../../contexts/UserContext';
import useTimesheets from '../../../hooks/useTimesheets';
import { VisitProps } from '../../../types/VisitProps';
import formatDate from '../../../utils/formatDate';
import VisitTasksTable from '../../molecules/VisitTasksTable';
import DataList from '../DataList';

export default function VisitsList({
  visits,
  jobBriefId,
  jobBriefCloseDate,
}: {
  visits: VisitProps[];
  jobBriefId?: number;
  jobBriefCloseDate?: Date;
}) {
  const { timesheets } = useTimesheets({ bySupervisor: false });
  const { user } = useUser();
  const isSupervisor =
    user?.role === 'supervisor' || user?.role === 'contractor';

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'initial',
        margin: 'auto',
      }}
    >
      {visits &&
        visits.map((visit, index) => {
          const filteredTimesheets = timesheets.filter(
            timesheet =>
              timesheet.jobBriefId === jobBriefId &&
              timesheet.visitId === visit.id,
          );

          const visitDetails = [
            {
              label: 'Start Date',
              value: formatDate(visit.startDate, isSupervisor) || '',
            },
            {
              label: 'Finish Date',
              value: jobBriefCloseDate
                ? formatDate(jobBriefCloseDate, isSupervisor)
                : '',
            },
          ];

          return (
            <>
              <Typography
                variant='subtitle2'
                mt={2}
              >
                Visit: {index + 1}
              </Typography>
              <DataList data={visitDetails} />
              <Typography
                variant='subtitle2'
                my={3}
              >
                Service: {visit.serviceName || ''}
              </Typography>
              <VisitTasksTable
                visitTasks={visit.visitTasks || []}
                filteredTimesheets={filteredTimesheets}
              />
            </>
          );
        })}
    </Box>
  );
}
