import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import {
  DialogContent,
  Button,
  DialogActions,
  DialogTitle,
  TextField,
  Autocomplete,
} from '@mui/material';
import { QuoteProps } from '../../../types/QuoteProps';
import { useDialog } from '../../../contexts/DialogContext';
import isEmpty from '../../../utils/hasEmptyProperties';
import useClients from '../../../hooks/useClients';
import { CONTRACT_STATUS, QUOTES_STATUS } from '../../../utils/constants';
import regexPatterns from '../../../utils/regexPatterns';
import { ClientOptionProps } from '../../../types/ClientProps';

interface QuoteDialogProps {
  createQuote?: Function;
  updateQuote?: Function;
  getAllquotes: Function;
  quote?: QuoteProps;
  onlyContracts?: boolean;
}

const initialValues: QuoteProps = {
  id: 0,
  city: '',
  email: '',
  referenceNumber: '',
  totalValue: 0,
  status: '',
  contractStatus: '',
  clientId: 0,
};

function QuotesDialog({
  createQuote,
  quote,
  updateQuote,
  onlyContracts,
}: QuoteDialogProps) {
  const { toggleDialog } = useDialog();
  const { clients } = useClients();

  const inputs = [
    {
      name: 'referenceNumber',
      type: 'text',
      label: 'Reference Number',
      message: 'Reference Number must be numeric!',
      pattern: regexPatterns.numeric,
      sx: { mb: 2 },
    },
    {
      name: 'city',
      type: 'text',
      label: 'City',
      message: "City shouldn't include any special character!",
      pattern: regexPatterns.spacedAlphanumeric,
      sx: { mb: 2 },
    },
    {
      name: 'email',
      type: 'email',
      label: 'Email',
      message: 'Invalid email address!',
      pattern: regexPatterns.email,
      sx: { mb: 2 },
    },
  ];

  const [clientOption, setClientOption] = useState<ClientOptionProps>();
  const [quoteData, setQuoteData] = useState<QuoteProps>(
    quote || initialValues,
  );

  type ObjectKey = keyof typeof quoteData;

  useEffect(() => {
    if (quote?.client)
      setClientOption({
        id: quote?.client?.id,
        reference: quote?.client?.reference,
      });
  }, [quote?.client]);

  const noEmptyFields = isEmpty([quoteData?.totalValue, clientOption?.id]);

  const isFormInvalid = inputs.some(input => {
    const inputName = input.name as ObjectKey;
    const inputPattern = quoteData[inputName]!.toString();
    return !input.pattern.test(inputPattern);
  });

  const hasUpdatedFields = JSON.stringify(quoteData) !== JSON.stringify(quote);

  const canSubmit = quote
    ? hasUpdatedFields && noEmptyFields && !isFormInvalid
    : !isFormInvalid && noEmptyFields;

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setQuoteData({ ...quoteData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (createQuote) await createQuote(quoteData);
    if (quote && updateQuote) await updateQuote(quoteData);
    toggleDialog();
  };

  return (
    <form onSubmit={handleSubmit}>
      <DialogTitle>{quote ? 'Update' : 'Register'} Quote</DialogTitle>
      <DialogContent sx={{ width: '100%', maxWidth: '450px' }}>
        {inputs.map(input => {
          const inputName = input.name as ObjectKey;
          const inputPattern = quoteData[inputName]!.toString();
          const hasError =
            !!quoteData[inputName] && !input.pattern.test(inputPattern);
          const errorMessage = hasError ? input.message : '';

          return (
            <TextField
              key={input.name}
              value={quoteData[inputName]}
              size='small'
              variant='standard'
              fullWidth
              onChange={onChange}
              error={hasError}
              helperText={errorMessage}
              {...input}
            />
          );
        })}
        <TextField
          label='Total Value'
          variant='standard'
          type='number'
          defaultValue='1'
          inputProps={{
            step: '0.05',
            min: '0.05',
          }}
          sx={{ mb: 1.2 }}
          fullWidth
          onChange={e => {
            setQuoteData({
              ...quoteData,
              totalValue: parseFloat(e.target.value),
            });
          }}
          value={quoteData.totalValue}
        />
        {quote && (
          <Autocomplete
            options={onlyContracts ? CONTRACT_STATUS : QUOTES_STATUS}
            onChange={(e, value) => {
              if (onlyContracts) {
                setQuoteData({ ...quoteData, contractStatus: value! });
                return;
              }
              setQuoteData({ ...quoteData, status: value! });
            }}
            fullWidth
            renderInput={params => (
              <TextField
                {...params}
                margin='dense'
                variant='standard'
                label='Status'
              />
            )}
            value={onlyContracts ? quoteData.contractStatus : quoteData.status}
          />
        )}
        <Autocomplete
          options={clients || []}
          value={clientOption || null}
          onChange={(e, value) => {
            if (value?.id && value.reference) {
              setClientOption({ id: value.id, reference: value.reference });
              setQuoteData({
                ...quoteData,
                clientId: value.id,
              });
            }
          }}
          fullWidth
          isOptionEqualToValue={(option, value) => option.id === value.id}
          getOptionLabel={option => option.reference}
          renderInput={params => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              margin='dense'
              variant='standard'
              label='Client'
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button
          type='button'
          sx={{ color: 'error.main' }}
          onClick={() => toggleDialog()}
        >
          Cancel
        </Button>
        <Button
          type='submit'
          disabled={!canSubmit}
        >
          {quote ? 'Update' : 'Create'}
        </Button>
      </DialogActions>
    </form>
  );
}

export default QuotesDialog;
