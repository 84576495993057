import { AccessTimeFilled, Edit, DeleteForever } from '@mui/icons-material';
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid';
import { toast } from 'material-react-toastify';
import { useNavigate } from 'react-router-dom';
import { useDialog } from '../../../contexts/DialogContext';
import api from '../../../services/api';
import { TimesheetProps } from '../../../types/TimesheetProps';
import formatDate from '../../../utils/formatDate';
import DashboardTable from '../../atoms/DashboardTable';
import TimesheetDialog from '../TimesheetDialog';

interface TimesheetsTableProps {
  timesheets: TimesheetProps[];
  getAllTimesheets: Function;
}

function TimesheetsTable({
  timesheets,
  getAllTimesheets,
}: TimesheetsTableProps) {
  const navigate = useNavigate();
  const { showDialog } = useDialog();

  const handleDeleteTimesheet = async (id: number) => {
    const response = await api.delete('/timesheet', {
      data: { id },
    });

    if (response.status === 200) {
      toast.success(`Timesheet deleted successfully!`);
      getAllTimesheets();
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      type: 'string',
      maxWidth: 80,
      align: 'center',
      headerAlign: 'center',
      filterable: false,
    },
    {
      field: 'dateReference',
      headerName: 'Date',
      type: 'date',
      width: 145,
      filterable: true,
      valueFormatter: params => formatDate(params, true),
    },
    {
      field: 'teamName',
      headerName: 'Team Name',
      width: 200,
      align: 'center',
      renderCell: (params: GridRenderCellParams) => {
        const team = params.row.visit?.team?.name;
        return team;
      },
    },
    {
      field: 'referenceNumber',
      headerName: 'Job Brief',
      width: 240,
      align: 'center',
      filterable: true,
      renderCell: (params: GridRenderCellParams) =>
        params.row.jobBrief ? params.row.jobBrief.referenceNumber : null,
    },
    {
      field: 'comment',
      headerName: 'Comment',
      flex: 1,
      filterable: true,
    },

    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      align: 'center',
      headerAlign: 'center',
      width: 80,
      filterable: false,
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          icon={<Edit />}
          label='Edit Timesheet'
          onClick={() =>
            showDialog({
              componentToRender: (
                <TimesheetDialog
                  timesheet={params.row}
                  refreshTimesheets={getAllTimesheets}
                />
              ),
            })
          }
        />,
        <GridActionsCellItem
          icon={<AccessTimeFilled />}
          label='See details'
          showInMenu
          onClick={() => navigate(`/dashboard/timesheets/${params.row.id}`)}
        />,
        <GridActionsCellItem
          sx={{ color: 'error.main' }}
          icon={<DeleteForever sx={{ color: 'error.main' }} />}
          label='Delete'
          onClick={() => handleDeleteTimesheet(params.row.id)}
          showInMenu
        />,
      ],
    },
  ];

  return (
    <DashboardTable
      getRowId={Timesheet => Timesheet.id}
      columns={columns}
      rows={timesheets}
    />
  );
}

export default TimesheetsTable;
