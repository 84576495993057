import { toast } from 'material-react-toastify';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import DashboardTitle from '../../../../../components/atoms/DashboardTitle';
import PayrollReportTable from '../../../../../components/molecules/PayrollReportsTable';
import { PayrollReportProps } from '../../../../../types/PayrollReportProps';

function DashboardDriversPayrollReportDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const payrollReports = location.state as PayrollReportProps[];

  useEffect(() => {
    toast.dismiss();
    if (!payrollReports || !payrollReports.length) {
      navigate('/dashboard/driversPayrollReport');
      toast.info('No results found!');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DashboardTitle title="Driver's payroll report" />
      {payrollReports && (
        <PayrollReportTable
          payrollReports={payrollReports}
          showComments
        />
      )}
    </>
  );
}

export default DashboardDriversPayrollReportDetails;
