import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Divider,
  Link,
  TextField,
  Typography,
} from '@mui/material';
import { toast } from 'material-react-toastify';
import { useEffect, useState } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import ButtonDialog from '../../../../components/atoms/ButtonDialog';
import PDFButton from '../../../../components/atoms/PDFButton';
import VisitsList from '../../../../components/atoms/VisitsList';
import QuoteVisitsDialog from '../../../../components/molecules/QuoteVisitsDialog';
import Details from '../../../../components/organisms/Details';
import ContractPDF from '../../../../components/pdf/ContractPDF';
import QuotePDF from '../../../../components/pdf/QuotePDF';
import api from '../../../../services/api';
import { QuoteProps } from '../../../../types/QuoteProps';

function QuoteDetails() {
  const { id } = useParams();

  const [quote, setQuote] = useState({} as QuoteProps);
  const [billingId, setBillingId] = useState(0);
  const [changeBilling, setChangeBilling] = useState(false);

  const getQuoteById = async (quoteId: number) => {
    const response = await api.get(`/quote/${quoteId}`);
    if (response.status === 200) {
      setQuote(response.data.quote);
    }
  };

  const handleBillingSubmit = async () => {
    const response = await api.post('/quote/addBilling', {
      billingId,
      quoteId: id,
    });
    if (response.status === 200) {
      const feedbackText = changeBilling ? 'updated' : 'added';
      toast.success(`Billing ${feedbackText} successfully to quote!`);
      const billingToAdd = quote.client?.billings?.find(
        billing => billing.id === billingId,
      );
      setQuote({
        ...quote,
        billing: billingToAdd,
      });
      setChangeBilling(false);
      return;
    }
    toast.error('Something went wrong!');
  };

  const handleConvertSubmit = async () => {
    const response = await api.put(`/quote/convert/${id}`, {
      billingId,
      quoteId: id,
    });
    if (response.status === 200) {
      toast.success('Quote was successfully converted!');
      setQuote({
        ...quote,
        isConvertedToContract: true,
      });
      return;
    }
    toast.error('Something went wrong!');
  };

  const status = quote.isConvertedToContract
    ? quote.contractStatus
    : quote.status;

  const informationsToDisplay = [
    {
      label: 'Client Name',
      value: quote.client?.reference,
    },
    {
      label: 'City',
      value: quote.city,
    },
    {
      label: 'Email',
      value: quote.email,
    },
    {
      label: 'Reference Number',
      value: quote.referenceNumber,
    },
    {
      label: 'Total Value',
      value: quote.totalValue,
    },
    {
      label: 'Billing Entity',
      value: quote.billing?.companyName || 'No billing entity selected',
    },
    {
      label: 'Status',
      value: status,
    },
  ];

  const filteredBillings = quote.client?.billings?.filter(
    billing => billing.quoteId === null,
  );

  useEffect(() => {
    if (id) {
      getQuoteById(parseInt(id, 10));
    }
  }, [id]);
  return (
    <Details
      title='Quote Detail'
      name='See details of this quote'
      subTitle='Quote'
    >
      <Box sx={{ width: '100%', display: 'flex' }}>
        <Box sx={{ width: '100%' }}>
          {informationsToDisplay.map(clientInfo => (
            <>
              <Typography
                variant='h5'
                mt={2}
                display='flex'
                alignItems='center'
              >
                {`${clientInfo.label}: `}
                <Typography
                  variant='h6'
                  fontWeight={300}
                  sx={{ ml: 1 }}
                >
                  {clientInfo.value}
                </Typography>
              </Typography>
              <Divider />
            </>
          ))}
        </Box>
        <Box sx={{ width: '100%', height: 50, mb: 4 }}>
          <VisitsList
            title='Visits related to this quote'
            visits={quote.visits || []}
          />
          <ButtonDialog
            component={
              <QuoteVisitsDialog
                quoteId={parseInt(id!, 10)}
                refreshQuote={() => getQuoteById(parseInt(id!, 10))}
              />
            }
            label='Add Visit'
            sx={{ my: 2, ml: 4 }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          width: '50%',
          mt: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {!quote.isConvertedToContract && quote.status === 'Accepted' && (
          <Button
            variant='contained'
            onClick={handleConvertSubmit}
            sx={{ my: 2, width: '80%' }}
          >
            Convert to contract
          </Button>
        )}
        {!changeBilling && !!filteredBillings?.length && (
          <Button
            variant='contained'
            onClick={() => setChangeBilling(true)}
            sx={{ my: 2 }}
          >
            Change Billing
          </Button>
        )}
        {!filteredBillings?.length && (
          <Alert severity='info'>
            It seems that no other billings are available to add,{' '}
            <Link
              component={RouterLink}
              to='/dashboard/billings/add'
            >
              try to register another billing!
            </Link>
          </Alert>
        )}
        {changeBilling && (
          <Box sx={{ width: '100%' }}>
            <Autocomplete
              sx={{ mt: 2 }}
              options={filteredBillings || []}
              getOptionLabel={option => option.companyName}
              renderInput={params => (
                <TextField
                  {...params}
                  label='Billings'
                  placeholder='Billings'
                />
              )}
              onChange={(data, value) => setBillingId(value?.id!)}
            />
            <Button
              variant='contained'
              onClick={handleBillingSubmit}
              sx={{ my: 2, width: '100%' }}
            >
              Add Billing
            </Button>
          </Box>
        )}
        {quote.isConvertedToContract ? (
          <PDFButton
            documentTitle='Contract Document'
            label='Generate Contract Document'
            component={<ContractPDF contractId={parseInt(id || '', 10)} />}
          />
        ) : (
          quote.status === 'Proposed to a Client' && (
            <PDFButton
              documentTitle='Quote Document'
              label='Generate Quote Document'
              component={
                <QuotePDF
                  informationsToDisplay={informationsToDisplay}
                  visits={quote.visits || []}
                />
              }
            />
          )
        )}
      </Box>
    </Details>
  );
}

export default QuoteDetails;
