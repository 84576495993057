import DashboardTitle from '../../../../components/atoms/DashboardTitle';
import BillingForm from '../../../../components/molecules/BillingForm';

function AddBilling() {
  return (
    <>
      <DashboardTitle
        title='Add Billing'
        hasBackButton
      />
      <BillingForm />
    </>
  );
}

export default AddBilling;
