import { Add } from '@mui/icons-material';
import ButtonDialog from '../../../components/atoms/ButtonDialog';
import DashboardTitle from '../../../components/atoms/DashboardTitle';
import QuotesDialog from '../../../components/molecules/QuotesDialog';
import QuotesTable from '../../../components/molecules/QuotesTable';
import useQuotes from '../../../hooks/useQuotes';

function Quotes() {
  const { quotes, createQuote, getAllquotes, deleteQuote, updateQuote } =
    useQuotes({ onlyContracts: false });
  return (
    <>
      <DashboardTitle title='All Quotes' />
      <ButtonDialog
        label='Register Quote'
        endIcon={<Add />}
        sx={{ my: 2 }}
        component={
          <QuotesDialog
            createQuote={createQuote}
            getAllquotes={getAllquotes}
          />
        }
      />
      <QuotesTable
        quotes={quotes}
        getAllquotes={getAllquotes}
        deleteQuote={deleteQuote}
        updateQuote={updateQuote}
      />
    </>
  );
}

export default Quotes;
