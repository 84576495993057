import { Box, Divider, Grid, Typography } from '@mui/material';
import { toast } from 'material-react-toastify';
import { useEffect, useState } from 'react';
import api from '../../../services/api';
import { JobBriefProps } from '../../../types/JobBriefProps';
import VisitsList from '../VisitsList';

interface ContractPDFProps {
  jobBriefId: number;
}

function DataList({
  data,
}: {
  data: { label: string; value: string | number | undefined }[];
}) {
  return (
    <Grid
      container
      columns={16}
      sx={{
        backgroundColor: 'primary.main',
        mt: 0.5,
        color: 'white',
        borderRadius: 2,
        '.MuiGrid-item': {
          p: 1.5,
        },
      }}
    >
      {data.map(clientInfo => (
        <Grid
          item
          xs={8}
        >
          <Typography
            variant='h5'
            fontSize={14}
            display='flex'
            alignItems='center'
            className='hey'
          >
            {`${clientInfo.label}: `}
            <Typography
              variant='subtitle2'
              fontWeight={300}
              sx={{ ml: 1 }}
            >
              {clientInfo.value}
            </Typography>
          </Typography>
          <Divider />
        </Grid>
      ))}
    </Grid>
  );
}

export default function JobBriefPDF({ jobBriefId }: ContractPDFProps) {
  const [jobBrief, setJobBrief] = useState({
    id: 0,
  } as JobBriefProps);

  const getJobBrief = async () => {
    try {
      const response = await api.get(`/jobBrief/${jobBriefId}}`);
      setJobBrief(response.data.jobBrief);
    } catch (error) {
      toast.error('Something went wrong!');
    }
  };

  const informationsToDisplay = [
    {
      label: 'Client Name',
      value: jobBrief.clientDetails?.name || '',
    },
    {
      label: 'Building Name',
      value: jobBrief.building?.name || '',
    },
    {
      label: 'Address',
      value: jobBrief.building?.address || '',
    },
    {
      label: 'Supervisors',
      value: jobBrief.supervisors
        ?.map(supervisor => supervisor.firstName)
        .join(' ,'),
    },
  ];

  useEffect(() => {
    getJobBrief();
  }, []);

  return (
    <Box
      sx={{
        width: '80%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: 'auto',
      }}
      className='pdf-page'
    >
      <img
        src='/Logo_HPS-Black.svg'
        alt='Hps logo'
        width={80}
        height={80}
      />
      <Typography variant='h3'>End of Job</Typography>
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'initial',
          margin: 'auto',
        }}
      >
        <DataList data={informationsToDisplay} />
      </Box>
      <Typography
        variant='h6'
        mt={2}
      >
        Visits
      </Typography>

      <VisitsList
        jobBriefId={Number(jobBrief.id)}
        jobBriefCloseDate={jobBrief.updatedAt}
        visits={jobBrief.visits || []}
      />
    </Box>
  );
}
