import { useEffect, useState } from 'react';
import { toast } from 'material-react-toastify';
import api from '../services/api';
import { VisitProps } from '../types/VisitProps';

const useVisits = () => {
  const [visits, setVisits] = useState([] as VisitProps[]);

  const getAllVisits = async () => {
    const response = await api.get('/visits');
    if (response.status === 200) {
      setVisits(response.data.visits);
    }
  };

  useEffect(() => {
    getAllVisits();
  }, []);

  const createVisit = async (visitDetails: VisitProps) => {
    const response = await api.post('/visit', visitDetails);
    if (response.status === 200) {
      const { id } = response.data.visit;
      setVisits([...visits, { ...visitDetails, id }]);
      toast.success('Visit registered successfully!');
    }
  };

  return {
    visits,
    getAllVisits,
    createVisit,
  };
};
export default useVisits;
