import React, { useState, FormEvent, useEffect, ChangeEvent } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardContent,
  Grid,
  TextField,
} from '@mui/material';
import LockResetIcon from '@mui/icons-material/LockReset';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'material-react-toastify';
import api from '../../services/api';
import regexPatterns from '../../utils/regexPatterns';
import getErrorMessage, {
  ResponseErrorMessage,
} from '../../utils/getErrorMessage';

function PasswordReset() {
  const { passwordResetHash } = useParams();
  const navigate = useNavigate();

  const [values, setValues] = useState({
    password: '',
    confirmedPassword: '',
  });

  type ObjectKey = keyof typeof values;

  const inputs = [
    {
      autoFocus: true,
      name: 'password',
      type: 'password',
      label: 'New password',
      message: 'Password must be 6-20 characters',
      pattern: regexPatterns.password,
      sx: { mb: 2 },
    },
    {
      name: 'confirmedPassword',
      type: 'password',
      label: 'Confirm password',
      message: 'Password must be 6-20 characters',
      pattern: regexPatterns.password,
      sx: { mb: 2 },
    },
  ];

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const verifyPasswordResetHash = async () => {
    try {
      await api.get(`/verifyPasswordResetHash/${passwordResetHash}`);
    } catch (error) {
      toast.dismiss();
      toast.error(getErrorMessage(error as ResponseErrorMessage));
      navigate('/login');
    }
  };

  useEffect(() => {
    verifyPasswordResetHash();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const equalPasswords = values.password !== values.confirmedPassword;

  const isFormInvalid = inputs.some(input => {
    const inputName = input.name as ObjectKey;
    return !input.pattern.test(values[inputName]) || equalPasswords;
  });

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    try {
      const { password } = values;

      const response = await api.post(`/passwordReset/${passwordResetHash}`, {
        password,
      });
      toast.dismiss();

      if (response.status === 200) {
        toast.success('Your password has been changed successfully!');
        navigate('/login');
      }
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 404) {
        const { message } = error.response.data as { message: string };
        toast.error(message);
      }
    }
  };

  return (
    <Card
      sx={{ maxWidth: 400, width: '100%', mx: 'auto', mt: 7 }}
      elevation={3}
    >
      <CardHeader
        sx={{
          bgcolor: 'secondary.main',
          color: 'white',
          px: 3,
          py: 2,
        }}
        titleTypographyProps={{ variant: 'h6', fontWeight: 'normal' }}
        title={
          <Grid
            container
            direction='row'
            alignItems='center'
            gap={1}
          >
            <LockResetIcon />
            Reset password
          </Grid>
        }
      />
      <CardContent sx={{ p: 3 }}>
        <form onSubmit={handleSubmit}>
          {inputs.map(input => {
            const inputName = input.name as ObjectKey;
            const hasError =
              !!values[inputName] && !input.pattern.test(values[inputName]);
            const errorMessage = hasError ? input.message : '';

            return (
              <TextField
                key={input.name}
                value={values[inputName]}
                size='small'
                variant='standard'
                fullWidth
                onChange={onChange}
                error={hasError}
                helperText={errorMessage}
                {...input}
              />
            );
          })}
          <Button
            type='submit'
            variant='contained'
            disabled={isFormInvalid}
            sx={{ mb: 2, bgcolor: 'secondary.main', boxShadow: 3 }}
            fullWidth
          >
            Reset
          </Button>
        </form>
      </CardContent>
    </Card>
  );
}

export default PasswordReset;
