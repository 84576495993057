import { toast } from 'material-react-toastify';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import DashboardTitle from '../../../../components/atoms/DashboardTitle';
import LaborMarginBarChart from '../../../../components/molecules/LaborMarginBarChart';
import LaborMarginLineChart from '../../../../components/molecules/LaborMarginLineChart';
import ProductivityReportsTable from '../../../../components/molecules/ProductivityReportsTable';
import { ProductivityReportProps } from '../../../../types/ProductivityReportProps';

function DashboardProductivityDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const productivityReports =
    (location.state as ProductivityReportProps[]) || [];

  useEffect(() => {
    toast.dismiss();
    if (!productivityReports.length) {
      navigate('/dashboard/productivity');
      toast.info('No results found!');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <DashboardTitle title='Productivity Report' />
      {productivityReports.length && (
        <>
          <ProductivityReportsTable productivityReports={productivityReports} />
          <LaborMarginBarChart productivityReports={productivityReports} />
          <LaborMarginLineChart productivityReports={productivityReports} />
        </>
      )}
    </>
  );
}

export default DashboardProductivityDetails;
