import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { CssBaseline, Box, Container } from '@mui/material';
import {
  Badge as BadgeIcon,
  Dashboard,
  PointOfSale,
  Work,
  Apartment,
  Groups,
  Place,
  HomeRepairService,
  FormatQuote,
  ShortText,
  CheckCircle,
  Gavel,
  AccessTimeFilled,
  DirectionsCar,
  BarChart,
} from '@mui/icons-material';
import Toolbar from '@mui/material/Toolbar';
import Footer from '../../layout/Footer';
import DesktopMenuBar from '../../layout/DesktopMenuBar';
import { useUser } from '../../../contexts/UserContext';
import api from '../../../services/api';

const iconSpacing = 0.5;

const pages = [
  {
    title: 'Dashboard',
    icon: <Dashboard sx={{ ml: iconSpacing }} />,
    href: '/dashboard',
  },
  {
    title: 'Users',
    icon: <BadgeIcon sx={{ ml: iconSpacing }} />,
    href: '/dashboard/users',
  },
  {
    title: 'Teams',
    icon: <Groups sx={{ ml: iconSpacing }} />,
    href: '/dashboard/teams',
  },
  {
    title: 'Clients',
    icon: <Work sx={{ ml: iconSpacing }} />,
    href: '/dashboard/clients',
  },
  {
    title: 'Buildings',
    icon: <Apartment sx={{ ml: iconSpacing }} />,
    href: '/dashboard/buildings',
  },
  {
    title: 'Billings',
    icon: <PointOfSale sx={{ ml: iconSpacing }} />,
    href: '/dashboard/billings',
  },
  {
    title: 'Visits',
    icon: <Place sx={{ ml: iconSpacing }} />,
    href: '/dashboard/visits',
  },
  {
    title: 'Services',
    icon: <HomeRepairService sx={{ ml: iconSpacing }} />,
    href: '/dashboard/services',
  },
  {
    title: 'Quotes',
    icon: <FormatQuote sx={{ ml: iconSpacing }} />,
    href: '/dashboard/quotes',
  },
  {
    title: 'Contracts',
    icon: <Gavel sx={{ ml: iconSpacing }} />,
    href: '/dashboard/contracts',
  },
  {
    title: 'Job Briefs',
    icon: <ShortText sx={{ ml: iconSpacing }} />,
    href: '/dashboard/jobBriefs',
  },
  {
    title: 'EOJ List',
    icon: <CheckCircle sx={{ ml: iconSpacing }} />,
    href: '/dashboard/eojs',
  },
  {
    title: 'Timesheets',
    icon: <AccessTimeFilled sx={{ ml: iconSpacing }} />,
    href: '/dashboard/timesheets',
  },
  {
    title: 'Driver Timesheets',
    icon: <DirectionsCar sx={{ ml: iconSpacing }} />,
    href: '/dashboard/driverTimesheets',
  },
  {
    title: 'Productivity',
    icon: <BarChart sx={{ ml: iconSpacing }} />,
    href: '/dashboard/productivity',
  },
];

function AdminDashboard() {
  const { user, setUser } = useUser();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleUser = async () => {
      const token = localStorage.getItem('HPS:token');
      if (!token) {
        setUser(null);
        navigate('/login');
        return;
      }
      if (!user) {
        const response = await api.get('/user');
        if (response.status === 200) setUser(response.data.user);
      }
    };
    handleUser();
  }, [navigate, user, location.pathname, setUser]);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <DesktopMenuBar pages={pages} />
      <Box
        component='main'
        sx={{
          color: 'black',
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Container
          maxWidth={false}
          sx={{ mt: 4, mb: 4, width: '90%' }}
        >
          <Outlet />
        </Container>
        <Footer />
      </Box>
    </Box>
  );
}

export default AdminDashboard;
