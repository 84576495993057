import { Box, Divider, Typography } from '@mui/material';
import { VisitProps } from '../../../types/VisitProps';
import VisitsList from '../../atoms/VisitsList';
import Details from '../../organisms/Details';

interface QuotePDFProps {
  informationsToDisplay: (
    | {
        label: string;
        value: string | undefined;
      }
    | {
        label: string;
        value: number;
      }
  )[];
  visits: VisitProps[];
}

export default function QuotePDF({
  visits,
  informationsToDisplay,
}: QuotePDFProps) {
  return (
    <Details
      name='Quote Document'
      subTitle='PDF Generated'
      title='Quotes Details'
    >
      <Box sx={{ width: '100%', display: 'flex' }}>
        <Box sx={{ width: '100%' }}>
          {informationsToDisplay.map(clientInfo => (
            <>
              <Typography
                variant='h5'
                mt={2}
                display='flex'
                alignItems='center'
              >
                {`${clientInfo.label}: `}
                <Typography
                  variant='h6'
                  fontWeight={300}
                  sx={{ ml: 1 }}
                >
                  {clientInfo.value}
                </Typography>
              </Typography>
              <Divider />
            </>
          ))}
        </Box>
        <Box sx={{ width: '100%', height: 50 }}>
          <VisitsList
            title='Visits related to this quote'
            visits={visits}
          />
        </Box>
      </Box>
    </Details>
  );
}
