import { Edit, Groups } from '@mui/icons-material';
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { useDialog } from '../../../contexts/DialogContext';
import { TeamProps } from '../../../types/TeamProps';

import DashboardTable from '../../atoms/DashboardTable';
import TeamDetailsDialog from '../TeamDetailsDialog';

function TeamsTable({
  teams,
  getAllTeams,
}: {
  teams: TeamProps[];
  getAllTeams: Function;
}) {
  const { showDialog } = useDialog();
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      type: 'string',
      maxWidth: 80,
      align: 'center',
      headerAlign: 'center',
      filterable: false,
    },
    {
      field: 'name',
      headerName: 'Team',
      type: 'string',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      filterable: true,
      renderCell: (params: GridRenderCellParams) => params.row.name,
    },
    {
      field: 'supervisorName',
      headerName: 'Supervisor',
      type: 'string',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      filterable: true,
      renderCell: (params: GridRenderCellParams) =>
        params.row.supervisorName ? params.row.supervisorName : 'Not assigned',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      align: 'center',
      headerAlign: 'center',
      width: 80,
      filterable: false,
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          icon={<Edit />}
          label='Edit'
          onClick={() =>
            showDialog({
              componentToRender: (
                <TeamDetailsDialog
                  team={params.row}
                  getAllTeams={getAllTeams}
                />
              ),
            })
          }
        />,
        <GridActionsCellItem
          icon={<Groups />}
          label='See details'
          onClick={() => navigate(`/dashboard/teams/${params.row.id}`)}
          showInMenu
        />,
      ],
    },
  ];

  return (
    <DashboardTable
      getRowId={Team => Team.id}
      columns={columns}
      rows={teams}
    />
  );
}

export default TeamsTable;
