import { TextField, Box, Typography, SxProps, Theme } from '@mui/material';
import { ChangeEventHandler } from 'react';
import convertDecimalToTime from '../../../utils/convertDecimalToTime';

interface TimeFieldProps {
  label?: string;
  value?: number | null;
  onChange?:
    | ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
    | undefined;
  sx?: SxProps<Theme>;
}

function TimeField({ label, value, onChange, sx }: TimeFieldProps) {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        mb: 2,
        ...sx,
      }}
    >
      <TextField
        label={label}
        variant='standard'
        type='number'
        value={value || ''}
        fullWidth
        inputProps={{
          min: '0.05',
          step: '.01',
        }}
        onChange={onChange}
      />
      <Typography
        variant='subtitle1'
        sx={{
          minWidth: '60px',
          mx: 5,
          mb: -2.5,
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {convertDecimalToTime(value!)}
      </Typography>
    </Box>
  );
}

export default TimeField;
