import React from 'react';
import DashboardTitle from '../../../../components/atoms/DashboardTitle';
import TimesheetForm from '../../../../components/molecules/TimesheetForm';

function AddTimesheet() {
  return (
    <>
      <DashboardTitle
        title='Add timesheet'
        hasBackButton
      />
      <TimesheetForm />
    </>
  );
}

export default AddTimesheet;
