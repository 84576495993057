import { useEffect, useState } from 'react';
import api from '../services/api';
import { TaskProps } from '../types/TaskProps';

const useTasks = () => {
  const [tasks, setTasks] = useState([] as TaskProps[]);

  const getAllTasks = async () => {
    const response = await api.get('/tasks');
    if (response.status === 200) {
      setTasks(response.data.tasks);
    }
  };

  useEffect(() => {
    getAllTasks();
  }, []);

  return {
    tasks,
    getAllTasks,
  };
};
export default useTasks;
