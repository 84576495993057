import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Chip } from '@mui/material';
import { TeamProps } from '../../../types/TeamProps';

interface TeamCardProps {
  team: TeamProps;
}

export default function TeamCard({ team }: TeamCardProps) {
  const supervisor = team.users!.filter(
    user => user.role === 'supervisor' || user.role === 'contractor',
  )[0];
  const operatives = team.users!.filter(user => user.role === 'operative');
  const hasOperatives = operatives.length > 0;

  return (
    <Box sx={{ minWidth: 275 }}>
      <Card variant='outlined'>
        <CardContent>
          <Typography
            sx={{ fontSize: 14 }}
            color='text.secondary'
            gutterBottom
          >
            Assigned team:
          </Typography>
          <Typography
            variant='h5'
            component='div'
          >
            {team.name}
          </Typography>
          <Typography color='text.secondary'>{supervisor.firstName}</Typography>
          {hasOperatives && (
            <>
              <Typography
                sx={{ fontSize: 14, mt: 1.5 }}
                color='text.secondary'
                gutterBottom
              >
                Operatives:
              </Typography>
              <Box>
                {operatives.map((operative, index) => (
                  <Chip
                    key={`item-${operative.username}`}
                    label={operative.firstName}
                    sx={{ ml: index > 0 ? 1 : 0 }}
                  />
                ))}
              </Box>
            </>
          )}
        </CardContent>
      </Card>
    </Box>
  );
}
