import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import DashboardTitle from '../../../components/atoms/DashboardTitle';
import ServicesTable from '../../../components/molecules/ServicesTable';

function DashboardServices() {
  const navigate = useNavigate();

  return (
    <>
      <DashboardTitle title='Services' />
      <Button
        variant='contained'
        endIcon={<AddIcon />}
        sx={{ my: 2 }}
        onClick={() => navigate('/dashboard/task')}
      >
        Add Task
      </Button>
      <ServicesTable />
    </>
  );
}

export default DashboardServices;
