import { DeleteForever } from '@mui/icons-material';
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid';
import { toast } from 'material-react-toastify';
import api from '../../../services/api';
import { DriverTimesheetProps } from '../../../types/DriverTimesheetProps';
import convertDecimalToTime from '../../../utils/convertDecimalToTime';
import DashboardTable from '../../atoms/DashboardTable';

interface DriverTimesheetsTableProps {
  driverTimesheets: DriverTimesheetProps[];
  getAllDriverTimesheets: Function;
}

function DriverTimesheetsTable({
  driverTimesheets,
  getAllDriverTimesheets,
}: DriverTimesheetsTableProps) {
  const handleDeleteDriverTimesheet = async (id: number) => {
    const response = await api.delete('/driverTimesheet', {
      data: { id },
    });

    if (response.status === 200) {
      toast.success(`Driver timesheet deleted successfully!`);
      getAllDriverTimesheets();
    }
  };

  const removeSecondsFromTime = (time: string) =>
    time.substring(0, time.lastIndexOf(':'));

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      type: 'string',
      maxWidth: 80,
      align: 'center',
      headerAlign: 'center',
      filterable: false,
    },
    {
      field: 'user',
      headerName: 'Driver',
      flex: 1,
      filterable: true,
      renderCell: (params: GridRenderCellParams) =>
        params.row.user ? `${params.row.user.referenceNumber} - ${params.row.user.firstName} ${params.row.user.lastName}` : 'No driver',
    },
    {
      field: 'startTime',
      headerName: 'Start time',
      align: 'center',
      headerAlign: 'center',
      width: 100,
      filterable: false,
      renderCell: (params: GridRenderCellParams) => {
        const { startTime } = params.row;
        return removeSecondsFromTime(startTime);
      },
    },
    {
      field: 'finishTime',
      headerName: 'Finish time',
      align: 'center',
      headerAlign: 'center',
      width: 100,
      filterable: false,
      renderCell: (params: GridRenderCellParams) => {
        const { finishTime } = params.row;
        return removeSecondsFromTime(finishTime);
      },
    },
    {
      field: 'hours',
      headerName: 'Hours',
      align: 'center',
      headerAlign: 'center',
      width: 100,
      filterable: false,
      renderCell: (params: GridRenderCellParams) => {
        const { hours } = params.row;
        return convertDecimalToTime(hours);
      },
    },
    {
      field: 'vehicleReg',
      headerName: 'Vehicle Reg.',
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      filterable: true,
    },
    {
      field: 'from',
      headerName: 'From',
      flex: 1,
      filterable: true,
    },
    {
      field: 'to',
      headerName: 'To',
      flex: 1,
      filterable: true,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      align: 'center',
      headerAlign: 'center',
      width: 80,
      filterable: false,
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          sx={{ color: 'error.main' }}
          icon={<DeleteForever sx={{ color: 'error.main' }} />}
          label='Delete'
          onClick={() => handleDeleteDriverTimesheet(params.row.id)}
          showInMenu
        />,
      ],
    },
  ];

  return (
    <DashboardTable
      getRowId={DriverTimesheet => DriverTimesheet.id}
      columns={columns}
      rows={driverTimesheets}
    />
  );
}

export default DriverTimesheetsTable;
