import {
  Autocomplete,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { toast } from 'material-react-toastify';
import React, { useEffect, useState } from 'react';
import { useDialog } from '../../../contexts/DialogContext';
import useServices from '../../../hooks/useServices';
import api from '../../../services/api';
import isEmpty from '../../../utils/hasEmptyProperties';
import { ServiceOptionProps } from '../../../types/ServiceProps';
import { ClientProps } from '../../../types/ClientProps';
import TimeField from '../../atoms/TimeField';
import { EntityTaskProps } from '../../../types/EntityTaskProps';

interface AddClientServiceProps {
  client: ClientProps;
  refreshClient: Function;
}

export default function AddClientServiceDialog({
  client,
  refreshClient,
}: AddClientServiceProps) {
  const { toggleDialog } = useDialog();
  const { services } = useServices({ unhiddenOnly: true });

  const [clientTasks, setClientTasks] = useState<EntityTaskProps[]>([]);
  const [serviceOption, setServiceOption] = useState<ServiceOptionProps | null>(
    null,
  );

  const hasFilledTasks = clientTasks
    ? !clientTasks.find(clientTask => !clientTask.price || !clientTask.time)
    : false;

  const noEmptyFields = isEmpty([serviceOption?.id, clientTasks.length]);

  const canSubmit = noEmptyFields && hasFilledTasks;

  const handleSubmit = async () => {
    const response = await api.post('/client/addService', {
      clientId: client.id,
      serviceId: serviceOption!.id,
      serviceName: serviceOption!.name,
      clientTasks,
    });

    if (response.status === 200) {
      toast.success('Service added to client successfully!');
      refreshClient();
    }

    toggleDialog();
  };

  const filteredServices = services.filter(
    service =>
      !client.clientServices?.some(
        clientService => clientService.serviceId === service.id,
      ) && service.tasks!.length,
  );

  const selectedService = services?.find(item => item.id === serviceOption?.id);

  useEffect(() => {
    if (selectedService?.tasks) {
      const tasks = selectedService?.tasks.map(obj => ({
        id: obj.id,
        name: obj.name,
        price: 0,
        time: 0,
      }));

      setClientTasks(tasks);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedService]);

  return (
    <>
      <DialogTitle>Add service</DialogTitle>
      <DialogContent
        sx={{ width: '100%', maxWidth: '900px', minWidth: '400px' }}
      >
        <Autocomplete
          sx={{ mt: 2 }}
          options={filteredServices}
          value={serviceOption}
          onChange={(e, value) => {
            if (value?.id && value.name) {
              setServiceOption({ id: value.id, name: value.name });
            } else {
              setServiceOption(null);
            }
          }}
          getOptionLabel={option => option.name || ''}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={params => (
            <TextField
              {...params}
              label='Service'
              placeholder='Service'
              fullWidth
            />
          )}
        />
        {clientTasks && clientTasks.length > 0 && (
          <Box sx={{ flexGrow: 1, mt: 4 }}>
            <Grid
              container
              direction='row'
              alignItems='center'
              columns={4}
              spacing={3}
              style={{ width: '100%' }}
            >
              <Grid
                item
                xs={1}
              >
                <Typography variant='subtitle2'>Task name</Typography>
              </Grid>
              <Grid
                item
                xs={1.4}
              >
                <Typography variant='subtitle2'>Price</Typography>
              </Grid>
              <Grid
                item
                xs={1.6}
              >
                <Typography variant='subtitle2'>Execution time</Typography>
              </Grid>
              {clientTasks &&
                clientTasks
                  .sort((a, b) => Number(a?.id) - Number(b?.id))
                  .map(clientTask => (
                    <React.Fragment key={`task-${clientTask.name}`}>
                      <Grid
                        item
                        xs={1}
                      >
                        <Typography variant='subtitle1'>
                          {clientTask.name}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        xs={1.4}
                      >
                        <TextField
                          variant='standard'
                          fullWidth
                          size='small'
                          onChange={e =>
                            setClientTasks(
                              clientTasks!.map(item =>
                                item.id === clientTask.id
                                  ? {
                                      ...item,
                                      price: parseFloat(e.target.value),
                                    }
                                  : item,
                              ),
                            )
                          }
                        />
                      </Grid>
                      <Grid
                        item
                        xs={1.6}
                        sx={{ mb: -1.6 }}
                      >
                        <TimeField
                          sx={{ width: '100%' }}
                          value={clientTask.time}
                          onChange={e =>
                            setClientTasks(
                              clientTasks!.map(item =>
                                item.id === clientTask.id
                                  ? {
                                      ...item,
                                      time: Number(
                                        parseFloat(e.target.value).toFixed(2),
                                      ),
                                    }
                                  : item,
                              ),
                            )
                          }
                        />
                      </Grid>
                    </React.Fragment>
                  ))}
            </Grid>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          type='button'
          sx={{ color: 'error.main' }}
          onClick={() => toggleDialog()}
        >
          Cancel
        </Button>
        <Button
          onClick={() => handleSubmit()}
          disabled={!canSubmit}
        >
          Save
        </Button>
      </DialogActions>
    </>
  );
}
