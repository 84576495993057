import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { Close, ShortText } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { toast } from 'material-react-toastify';
import DashboardTable from '../../atoms/DashboardTable';
import { JOB_BRIEFS_STATUS } from '../../../utils/constants';
import { JobBriefProps } from '../../../types/JobBriefProps';
import api from '../../../services/api';
import { useUser } from '../../../contexts/UserContext';

function JobBriefsTable() {
  const navigate = useNavigate();
  const { user } = useUser();
  const isSupervisor =
    user?.role === 'supervisor' || user?.role === 'contractor';
  const [jobBriefs, setJobBriefs] = useState([] as JobBriefProps[]);
  const [isLoading, setIsLoading] = useState(false);

  const supervisorsOptions = jobBriefs.map(jobBrief => {
    if (jobBrief.supervisors?.length === 1)
      return `${jobBrief.supervisors[0].firstName} ${jobBrief.supervisors[0].lastName}`;
    return 'Multiple supervisors';
  });

  const getJobBriefs = async () => {
    setIsLoading(true);
    let endpoint = '/jobBriefs';
    if (isSupervisor) {
      endpoint += `/bySupervisor`;
    }
    const response = await api.get(endpoint);
    if (response.status === 200) {
      setJobBriefs(response.data.jobBriefs);
    }
    setIsLoading(false);
  };

  const handleClose = async (id: number) => {
    const response = await api.put(`/jobBrief/close/${id}`);
    if (response.status === 200) {
      getJobBriefs();
      toast.success('Job Brief closed successfully!');
      return;
    }
    toast.error('Something went wrong!');
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      type: 'string',
      maxWidth: 80,
      align: 'center',
      headerAlign: 'center',
      filterable: false,
    },
    {
      field: 'referenceNumber',
      headerName: 'Ref. Number',
      type: 'string',
      width: 160,
      align: 'center',
      headerAlign: 'center',
      filterable: true,
    },
    {
      field: 'Building',
      headerName: 'Building',
      flex: 1,
      filterable: true,
      renderCell: (params: GridRenderCellParams) =>
        params.row.building ? params.row.building.name : 'No Building',
    },
    {
      field: 'supervisor',
      headerName: 'Supervisor',
      width: 200,
      filterable: true,
      type: 'singleSelect',
      valueOptions: [...new Set(supervisorsOptions)],
      renderCell: ({ row }: GridRenderCellParams) => {
        const assignedSupervisors = row.supervisors.length;
        if (assignedSupervisors === 0) {
          return 'No supervisor assigned';
        }

        if (assignedSupervisors === 1) {
          return `${row.supervisors[0].firstName} ${row.supervisors[0].lastName}`;
        }

        return 'Multiple supervisors';
      },
    },
    {
      field: 'status',
      headerName: 'Job Brief Status',
      width: 160,
      filterable: true,
      type: 'singleSelect',
      valueOptions: JOB_BRIEFS_STATUS,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      align: 'center',
      headerAlign: 'center',
      width: 80,
      filterable: false,
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          icon={<ShortText />}
          label='See details'
          onClick={() => navigate(`/dashboard/jobBriefs/${params.row.id}`)}
          showInMenu
        />,

        params.row.status === 'In Progress' ? (
          <GridActionsCellItem
            icon={<Close sx={{ color: 'error.main' }} />}
            label='Close Job Brief'
            onClick={() => handleClose(params.row.id)}
            showInMenu
          />
        ) : (
          <div />
        ),
      ],
    },
  ];

  useEffect(() => {
    getJobBriefs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardTable
      loading={isLoading}
      columns={columns}
      rows={jobBriefs || []}
    />
  );
}

export default JobBriefsTable;
