import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { Assessment, Add } from '@mui/icons-material';
import DashboardTitle from '../../../components/atoms/DashboardTitle';
import TimesheetsTable from '../../../components/molecules/TimesheetsTable';
import { useUser } from '../../../contexts/UserContext';
import useTimesheets from '../../../hooks/useTimesheets';

function DashboardTimesheets() {
  const navigate = useNavigate();
  const { user } = useUser();

  const isAdmin = user?.role === 'admin';
  const isSupervisor =
    user?.role === 'supervisor' || user?.role === 'contractor';

  const { timesheets, getAllTimesheets } = useTimesheets({
    bySupervisor: isSupervisor,
  });

  return (
    <>
      <DashboardTitle title='Timesheets' />
      {isAdmin && (
        <>
          <Button
            variant='contained'
            endIcon={<Assessment />}
            onClick={() => navigate('/dashboard/timesheetReport')}
            sx={{ my: 2, mr: 4 }}
          >
            Generate Timesheet Report
          </Button>
          <Button
            variant='contained'
            endIcon={<Assessment />}
            onClick={() => navigate('/dashboard/progressReport')}
            sx={{ my: 2, mr: 4 }}
          >
            Generate Progress Report
          </Button>
          <Button
            variant='contained'
            endIcon={<Assessment />}
            onClick={() => navigate('/dashboard/payrollReport')}
            sx={{ my: 2, mr: 4 }}
          >
            Generate Payroll Report
          </Button>
          <Button
            variant='contained'
            endIcon={<Assessment />}
            onClick={() => navigate('/dashboard/driversPayrollReport')}
            sx={{ my: 2 }}
          >
            Generate Drivers Payroll Report
          </Button>
        </>
      )}
      {isSupervisor && (
        <Button
          variant='contained'
          endIcon={<Add />}
          onClick={() => navigate('/dashboard/timesheets/add')}
          sx={{ my: 2, mr: 4 }}
        >
          Add timesheet
        </Button>
      )}
      <TimesheetsTable
        timesheets={timesheets}
        getAllTimesheets={getAllTimesheets}
      />
    </>
  );
}

export default DashboardTimesheets;
