import { useEffect, useState } from 'react';
import { toast } from 'material-react-toastify';
import api from '../services/api';
import { BuildingProps } from '../types/BuildingProps';

const useBuildings = () => {
  const [buildings, setBuildings] = useState([] as BuildingProps[]);

  const getAllBuildings = async () => {
    const response = await api.get('/buildings');
    if (response.status === 200) {
      setBuildings(response.data.buildings);
    }
  };

  useEffect(() => {
    getAllBuildings();
  }, []);

  const createBuilding = async (buildingDetails: BuildingProps) => {
    const response = await api.post('/building', buildingDetails);
    if (response.status === 200) {
      const { id } = response.data.building;
      setBuildings([...buildings, { ...buildingDetails, id }]);
      toast.success('Building registered successfully!');
    }
  };

  return {
    buildings,
    getAllBuildings,
    createBuilding,
  };
};
export default useBuildings;
