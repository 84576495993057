import React, { useEffect } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { CssBaseline, Box, Container, Typography, AppBar } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import Footer from '../../layout/Footer';
import { useUser } from '../../../contexts/UserContext';
import api from '../../../services/api';

function Auth() {
  const { user, setUser } = useUser();
  const navigate = useNavigate();
  const { hashLink } = useParams();

  const handleUser = async () => {
    if (hashLink) localStorage.removeItem('HPS:token');

    const token = localStorage.getItem('HPS:token');
    if (token) {
      if (!user) {
        const response = await api.get('/user');
        if (response.status === 200) {
          setUser(response.data.user);
          navigate('/dashboard');
        }
      } else {
        navigate('/dashboard');
      }
    }
  };

  useEffect(() => {
    handleUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, setUser, user]);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position='fixed'>
        <Toolbar
          sx={{
            pr: '24px',
          }}
        >
          <Typography
            component='h1'
            variant='h6'
            noWrap
            sx={{ flexGrow: 1, cursor: 'pointer' }}
            onClick={() => navigate('/login')}
          >
            HPS
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component='main'
        sx={{
          color: 'black',
          flexGrow: 1,
          height: '100vh',
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Container
          maxWidth='lg'
          sx={{ mt: 4, mb: 4 }}
        >
          <Outlet />
        </Container>
        <Footer />
      </Box>
    </Box>
  );
}

export default Auth;
