export interface ResponseErrorMessage {
  response: {
    data: {
      error: string;
    };
  };
}

const getErrorMessage = (error: ResponseErrorMessage) =>
  error.response.data.error;

export default getErrorMessage;
