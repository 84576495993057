import {
  Alert,
  Link,
  Autocomplete,
  Box,
  Button,
  Paper,
  TextField,
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { toast } from 'material-react-toastify';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import useClients from '../../../hooks/useClients';
import ListField from '../../atoms/ListField';
import formatDate from '../../../utils/formatDate';
import api from '../../../services/api';
import { VisitProps } from '../../../types/VisitProps';
import { JobBriefProps } from '../../../types/JobBriefProps';
import useSupervisors from '../../../hooks/useSupervisors';

export default function JobBriefForm() {
  const { clients } = useClients();
  const { supervisors } = useSupervisors();
  const [jobBrief, setJobBrief] = useState({} as JobBriefProps);

  let visits: VisitProps[] | undefined;
  const client = clients.find(item => item.id === jobBrief.clientId);
  if (client) {
    const building = client?.buildings?.find(
      item => item.id === jobBrief.buildingId,
    );
    visits = building?.visits?.filter(visit => visit.jobBriefId === null);
  }

  const navigate = useNavigate();
  async function handleSubmit() {
    try {
      const response = await api.post('/jobBrief', jobBrief);
      if (response.status === 200) {
        toast.success('Job Brief saved successfully!');
        navigate('/dashboard/jobBriefs');
      }
    } catch (error) {
      toast.error('Something went wrong!');
    }
  }

  return (
    <Paper
      sx={{
        py: 2,
        px: 20,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ display: 'flex', width: '100%' }} />
      <ListField
        label='Clients'
        options={clients}
        onChange={event =>
          setJobBrief({
            ...jobBrief,
            clientId: event.target.value,
          })
        }
      />

      {client && (
        <ListField
          label='Buildings'
          options={client?.buildings || []}
          onChange={event =>
            setJobBrief({
              ...jobBrief,
              buildingId: event.target.value,
            })
          }
        />
      )}

      {visits !== undefined && !visits.length && (
        <Alert severity='info'>
          It seems that there are no more available visits on this building to
          associate, try to add another visit{' '}
          <Link
            component={RouterLink}
            to='/dashboard/visits/add'
          >
            here
          </Link>
          .
        </Alert>
      )}

      {!!visits?.length && (
        <>
          <Autocomplete
            multiple
            filterSelectedOptions
            id='tags-standard'
            options={visits}
            onChange={(event, value) => {
              setJobBrief({
                ...jobBrief,
                visitIds: value.map(visit => visit.id),
              });
            }}
            sx={{ my: 2 }}
            getOptionLabel={option =>
              `ID ${option.id} - ${formatDate(
                option.startDate,
              )} to ${formatDate(option.finishDate)}`
            }
            renderInput={params => (
              <TextField
                {...params}
                variant='standard'
                label='Visits to add'
                placeholder='Add visits'
              />
            )}
          />
          <Autocomplete
            multiple
            filterSelectedOptions
            id='tags-standard'
            options={supervisors}
            onChange={(event, value) => {
              setJobBrief({
                ...jobBrief,
                supervisorsId: value.map(supervisor => supervisor.id),
                teamsId: value.map(supervisor => supervisor.teamId!),
              });
            }}
            sx={{ my: 2 }}
            getOptionLabel={option => {
              const nameToDisplay = `${option?.firstName} ${option?.lastName}`;
              return nameToDisplay;
            }}
            renderInput={params => (
              <TextField
                {...params}
                variant='standard'
                label='Supervisors to add'
                placeholder='Add supervisors'
              />
            )}
          />

          <TextField
            label='Add Comments'
            multiline
            variant='standard'
            onChange={event => {
              setJobBrief({
                ...jobBrief,
                comment: event.target.value,
              });
            }}
          />
        </>
      )}
      <Button
        variant='contained'
        endIcon={<SendIcon />}
        sx={{ mx: 'auto', mt: 3 }}
        onClick={() => handleSubmit()}
      >
        Create Job Brief
      </Button>
    </Paper>
  );
}
