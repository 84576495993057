import { DeleteForever, Edit, AccountBox } from '@mui/icons-material';
import {
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
} from '@mui/x-data-grid';
import { toast } from 'material-react-toastify';
import { useNavigate } from 'react-router-dom';
import { useDialog } from '../../../contexts/DialogContext';
import api from '../../../services/api';
import { ClientProps } from '../../../types/ClientProps';
import ClientDetailsDialog from '../ClientDetailsDialog';
import DashboardTable from '../../atoms/DashboardTable';

interface ClientsTableProps {
  clients: ClientProps[];
  getAllClients: Function;
}

export default function ClientsTable({
  clients,
  getAllClients,
}: ClientsTableProps) {
  const { showDialog } = useDialog();
  const navigate = useNavigate();

  const handleDeleteClient = async (id: number) => {
    const response = await api.delete('/client', {
      data: { id },
    });
    if (response.status === 200) {
      toast.success('Client deleted successfully!');
      getAllClients();
    }
  };
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      type: 'string',
      maxWidth: 80,

      align: 'center',
      headerAlign: 'center',
      filterable: false,
    },
    {
      field: 'reference',
      headerName: 'Client reference',
      width: 150,
    },
    {
      field: 'headOfficeAddress',
      headerName: 'Head office address',
      flex: 1,
    },
    {
      field: 'headOfficePhone',
      headerName: 'Head office phone',
      type: 'string',
      width: 150,
    },
    {
      field: 'headOfficeEmail',
      headerName: 'Head office email',
      width: 204,
      filterable: false,
    },
    {
      field: 'companyName',
      headerName: 'Company Name',
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      align: 'center',
      headerAlign: 'center',
      width: 80,
      filterable: false,
      getActions: (params: GridRowParams) => [
        <GridActionsCellItem
          icon={<Edit />}
          label='Edit'
          onClick={() =>
            showDialog({
              componentToRender: (
                <ClientDetailsDialog
                  getAllClients={getAllClients}
                  client={params.row}
                />
              ),
            })
          }
        />,
        <GridActionsCellItem
          icon={<AccountBox />}
          label='See details'
          onClick={() => navigate(`/dashboard/clients/${params.row.id}`)}
          showInMenu
        />,
        <GridActionsCellItem
          sx={{ color: 'error.main' }}
          icon={<DeleteForever sx={{ color: 'error.main' }} />}
          label='Delete'
          onClick={() => handleDeleteClient(params.row.id)}
          showInMenu
        />,
      ],
    },
  ];
  return (
    <DashboardTable
      getRowId={User => User.id}
      columns={columns}
      rows={clients}
    />
  );
}
