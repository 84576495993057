import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { CssBaseline, Box, Container } from '@mui/material';
import {
  Engineering,
  ShortText,
  AccessTimeFilled,
  CheckCircle,
  DirectionsCar,
} from '@mui/icons-material';
import Toolbar from '@mui/material/Toolbar';
import Footer from '../../layout/Footer';
import { useUser } from '../../../contexts/UserContext';
import api from '../../../services/api';
import MobileMenuBar from '../../layout/MobileMenuBar';

function MobileDashboard() {
  const { user, setUser } = useUser();
  const navigate = useNavigate();
  const location = useLocation();

  const isSupervisor =
    user?.role === 'supervisor' || user?.role === 'contractor';
  const isContractor = user?.role === 'contractor';

  const pages = [
    {
      title: 'Job Briefs',
      icon: <ShortText />,
      href: '/dashboard/jobBriefs',
    },
    {
      title: 'Timesheets',
      icon: <AccessTimeFilled />,
      href: '/dashboard/timesheets',
    },
    ...(isContractor
      ? []
      : [
          {
            title: 'Driver Timesheets',
            icon: <DirectionsCar />,
            href: '/dashboard/driverTimesheets',
          },
        ]),
    {
      title: 'EOJ List',
      icon: <CheckCircle />,
      href: '/dashboard/eojs',
    },
    ...(isSupervisor
      ? [
          {
            title: 'Operatives',
            icon: <Engineering />,
            href: '/dashboard/operatives/me',
          },
        ]
      : []),
  ];

  useEffect(() => {
    const handleUser = async () => {
      const token = localStorage.getItem('HPS:token');
      if (!token) {
        setUser(null);
        navigate('/login');
        return;
      }
      if (!user) {
        const response = await api.get('/user');
        if (response.status === 200) setUser(response.data.user);
      }
    };
    handleUser();
  }, [navigate, user, location.pathname, setUser]);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <MobileMenuBar pages={pages} />
      <Box
        component='main'
        sx={{
          color: 'black',
          flexGrow: 1,
          overflow: 'auto',
        }}
      >
        <Toolbar />
        <Container
          maxWidth={false}
          sx={{ mt: 4, mb: 4, width: '90%' }}
        >
          <Outlet />
        </Container>
        <Footer />
      </Box>
    </Box>
  );
}

export default MobileDashboard;
