import {
  Alert,
  Autocomplete,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { toast } from 'material-react-toastify';
import { FormEvent, useEffect, useState } from 'react';
import { useDialog } from '../../../contexts/DialogContext';
import api from '../../../services/api';
import { TeamProps } from '../../../types/TeamProps';
import { UserOptionProps, UserProps } from '../../../types/UserProps';
import isEmpty from '../../../utils/hasEmptyProperties';
import regexPatterns from '../../../utils/regexPatterns';

interface TeamDetailsDialogProps {
  getAllTeams: Function;
  team?: TeamProps;
}

const initialValues: TeamProps = {
  id: 0,
  name: '',
  supervisorId: 0,
};

export default function TeamDetailsDialog({
  getAllTeams,
  team,
}: TeamDetailsDialogProps) {
  const [teamData, setTeamData] = useState<TeamProps>(team || initialValues);

  const input = {
    name: 'teamName',
    type: 'text',
    label: 'Team Name',
    message: "Team Name shouldn't include any special character!",
    pattern: regexPatterns.spacedAlphanumeric,
    sx: { mb: 2 },
  };

  const { toggleDialog } = useDialog();
  const [supervisors, setSupervisors] = useState<UserProps[]>();
  const [supervisorOption, setSupervisorOption] = useState<UserOptionProps>();

  const noEmptyFields = isEmpty([
    teamData?.name,
    teamData?.supervisorId,
    supervisorOption?.id,
  ]);

  const isTeamNameInvalid = !input.pattern.test(teamData.name);
  const hasUpdatedFields = JSON.stringify(teamData) !== JSON.stringify(team);
  const canSubmit = team
    ? hasUpdatedFields && noEmptyFields && !isTeamNameInvalid
    : noEmptyFields && !isTeamNameInvalid;

  async function handleSubmit(e: FormEvent) {
    e.preventDefault();

    const response = team
      ? await api.put('/team', teamData)
      : await api.post('/team', teamData);

    if (response.status === 200) {
      toast.success(`Team ${team ? 'updated' : 'registered'} sucessfully!`);
      getAllTeams();
    }

    toggleDialog();
  }

  const getSupervisors = async () => {
    const response = await api.get('/users/supervisor');
    if (response.status === 200) {
      const { users } = response.data;
      const allSupervisors = users as UserProps[];
      setSupervisors(allSupervisors);

      if (team?.supervisorId) {
        const teamSupervisor = allSupervisors?.find(
          supervisor => supervisor.id === team.supervisorId,
        );

        setSupervisorOption({
          id: teamSupervisor?.id!,
          firstName: teamSupervisor?.firstName!,
          lastName: teamSupervisor?.lastName!,
          referenceNumber: teamSupervisor?.referenceNumber!,
          teamId: teamSupervisor?.teamId,
        });
      }
    }
  };

  useEffect(() => {
    getSupervisors();
  }, []);

  const hasError = !!teamData.name && !input.pattern.test(teamData.name);

  return (
    <form onSubmit={handleSubmit}>
      {!team && <DialogTitle>Create team</DialogTitle>}
      <Alert
        severity='warning'
        icon={false}
        sx={{ backgroundColor: 'warning.light' }}
      >
        Supervisors highlighted in yellow already have a team!
      </Alert>
      <DialogContent>
        <TextField
          value={teamData.name}
          margin='dense'
          size='small'
          variant='standard'
          onChange={e => setTeamData({ ...teamData, name: e.target.value })}
          error={hasError}
          helperText={hasError ? input.message : ''}
          fullWidth
          {...input}
        />
        <Autocomplete
          options={supervisors || []}
          value={supervisorOption || null}
          fullWidth
          isOptionEqualToValue={(option, value) => option.id === value.id}
          onChange={(e, value) => {
            if (value?.id && value.firstName) {
              setSupervisorOption({
                id: value.id,
                firstName: value.firstName,
                lastName: value.lastName || '',
                referenceNumber: value.referenceNumber || '',
                teamId: value.teamId || null,
              });
              setTeamData({
                ...teamData,
                supervisorId: value?.id!,
              });
            }
          }}
          getOptionLabel={option => {
            const nameToDisplay = `${option?.referenceNumber} - ${option?.firstName} ${option?.lastName}`;
            return nameToDisplay;
          }}
          renderOption={(props, option) => (
            <Box
              component='li'
              sx={{
                backgroundColor: option.teamId !== null ? 'warning.light' : '',

                '&:hover': {
                  backgroundColor:
                    option.teamId !== null ? 'warning.light' : '',
                },
              }}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
            >
              {`${option?.referenceNumber} - ${option?.firstName} ${option?.lastName}`}
            </Box>
          )}
          renderInput={params => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              margin='dense'
              variant='standard'
              label='Supervisor'
              sx={{
                '& .MuiInputBase-root': {
                  color:
                    supervisorOption && supervisorOption.teamId !== null
                      ? 'warning.main'
                      : '',
                },
              }}
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button
          type='button'
          sx={{ color: 'error.main' }}
          onClick={() => toggleDialog()}
        >
          Cancel
        </Button>
        <Button
          type='submit'
          disabled={!canSubmit}
        >
          {team ? 'Update' : 'Register'}
        </Button>
      </DialogActions>
    </form>
  );
}
