import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import DashboardTitle from '../../../components/atoms/DashboardTitle';
import VisitsTable from '../../../components/molecules/VisitsTable';
import useVisits from '../../../hooks/useVisits';

function DashboardVisits() {
  const navigate = useNavigate();
  const { visits, getAllVisits } = useVisits();

  return (
    <>
      <DashboardTitle title='Visits' />
      <Button
        variant='contained'
        endIcon={<AddIcon />}
        sx={{ my: 2 }}
        onClick={() => navigate('/dashboard/visits/add')}
      >
        Register Visit
      </Button>
      <VisitsTable
        visits={visits}
        getAllVisits={getAllVisits}
      />
    </>
  );
}

export default DashboardVisits;
