import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Add } from '@mui/icons-material';
import DashboardTitle from '../../../components/atoms/DashboardTitle';
import DriverTimesheetsTable from '../../../components/molecules/DriverTimesheetsTable';
import { useUser } from '../../../contexts/UserContext';
import useDriverTimesheets from '../../../hooks/useDriverTimesheets';

function DashboardDriverTimesheets() {
  const navigate = useNavigate();
  const { user } = useUser();
  const { getAllDriverTimesheets, driverTimesheets } = useDriverTimesheets();
  const isOnlySupervisor = user?.role === 'supervisor';

  return (
    <>
      <DashboardTitle title='Driver timesheets' />
      {isOnlySupervisor && (
        <Button
          variant='contained'
          endIcon={<Add />}
          onClick={() => navigate('/dashboard/driverTimesheets/add')}
          sx={{ my: 2, mr: 4 }}
        >
          Add driver timesheet
        </Button>
      )}
      <DriverTimesheetsTable
        driverTimesheets={driverTimesheets}
        getAllDriverTimesheets={getAllDriverTimesheets}
      />
    </>
  );
}

export default DashboardDriverTimesheets;
