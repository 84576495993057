import {
  Box,
  Button,
  InputAdornment,
  Paper,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import SendIcon from '@mui/icons-material/Send';
import { useState } from 'react';
import { toast } from 'material-react-toastify';
import { ROLES } from '../../../utils/constants';
import regexPatterns from '../../../utils/regexPatterns';

export default function InviteForm({
  createInvite,
}: {
  createInvite: Function;
}) {
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

  const emailInput = {
    name: 'email',
    type: 'email',
    label: 'Email',
    message: 'Invalid email address!',
    pattern: regexPatterns.email,
  };

  const rolesOptions = ROLES.map(
    role => `${role.charAt(0).toUpperCase()}${role.slice(1)}`,
  );

  const [email, setEmail] = useState('');
  const [role, setRole] = useState(ROLES[0].toUpperCase());

  const isFormInvalid = !emailInput.pattern.test(email);
  const hasError = !!email && isFormInvalid;

  async function handleSubmit() {
    if (!role) {
      toast.error('Select a role');
      return;
    }

    await createInvite({ role, email });

    setEmail('');
  }
  return (
    <Paper
      sx={{
        display: 'block',
        width: 'fit-content',
        py: 2,
        px: 4,
        mb: 4,
        mx: isMobile ? 'auto' : 'unset',
      }}
    >
      <Box
        sx={{
          display: isMobile ? 'block' : 'flex',
          gridTemplateColumns: isMobile ? '1fr' : 'unset',
          alignItems: 'center',
          justifyContent: 'left',
        }}
      >
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <TextField
            size='small'
            variant='standard'
            value={email}
            error={hasError}
            helperText={hasError ? emailInput.message : ''}
            onChange={e => setEmail(e.target.value)}
            {...emailInput}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label='Role'
            variant='standard'
            select
            value={role}
            defaultValue=''
            sx={{ ml: 4, mt: -0.4 }}
            SelectProps={{
              native: true,
            }}
            onChange={e => setRole(e.target.value)}
          >
            {rolesOptions.map(option => (
              <option
                key={option}
                value={option.toLowerCase()}
              >
                {option}
              </option>
            ))}
          </TextField>
        </Box>
        <Button
          variant='contained'
          endIcon={<SendIcon />}
          onClick={() => handleSubmit()}
          disabled={isFormInvalid}
          fullWidth={isMobile}
          sx={{ ml: isMobile ? 0 : 5, mt: isMobile ? 2 : 0 }}
        >
          Send Invite
        </Button>
      </Box>
    </Paper>
  );
}
