import { GridColDef } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { ProgressReportProps } from '../../../types/ProgressReportProps';
import { TimesheetProps } from '../../../types/TimesheetProps';
import { VisitTaskProps } from '../../../types/VisitTaskProps';
import DashboardTable from '../../atoms/DashboardTable';

interface VisitTasksTableProps {
  visitTasks: VisitTaskProps[];
  filteredTimesheets?: TimesheetProps[];
}

const initialValues: ProgressReportProps = {
  id: 0,
  completedUnits: 0,
  timesheetId: 0,
  visitTaskId: 0,
};

function VisitTasksTable({
  visitTasks,
  filteredTimesheets,
}: VisitTasksTableProps) {
  const [progressReports, setProgressReports] = useState<ProgressReportProps[]>(
    [],
  );

  useEffect(() => {
    const tasksToReport: ProgressReportProps[] = [];

    visitTasks?.forEach(visitTask => {
      tasksToReport.push({
        ...initialValues,
        visitTaskId: visitTask.id,
      });
    });

    setProgressReports(tasksToReport);
  }, [visitTasks]);

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      type: 'string',
      maxWidth: 80,
      align: 'center',
      headerAlign: 'center',
      filterable: false,
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
    },
    {
      field: 'units',
      headerName: 'Number of units',
      flex: 1,
    },
    {
      field: 'completedUnits',
      headerName: 'Completed units',
      flex: 1,
      filterable: true,
    },
    {
      field: 'availableUnits',
      headerName: 'Available units',
      flex: 1,
      filterable: true,
    },
  ];

  const rows = !filteredTimesheets
    ? visitTasks
    : visitTasks.map((visitTask: VisitTaskProps) => {
        let reportedTasks: ProgressReportProps[] = [];

        filteredTimesheets.forEach(timesheet => {
          const timesheetReportedTasks = timesheet.progressReports!.filter(
            progressReport => progressReport.visitTaskId === visitTask.id,
          );

          reportedTasks = reportedTasks.concat(timesheetReportedTasks);
        });

        let previouslyCompletedUnits = 0;
        reportedTasks?.forEach(element => {
          previouslyCompletedUnits += element.completedUnits;
        });

        const currentTask =
          progressReports.find(
            current => current.visitTaskId === visitTask.id,
          ) || initialValues;

        const completedUnits =
          previouslyCompletedUnits + currentTask.completedUnits;
        const totalUnits = visitTask.units || 0;
        const availableUnits = totalUnits - completedUnits;
        return {
          units: visitTask.units,
          name: visitTask.name,
          completedUnits,
          availableUnits,
          id: visitTask.id,
        };
      });

  return (
    <DashboardTable
      getRowId={Task => Task.id}
      columns={columns}
      sortModel={[{ field: 'units', sort: 'asc' }]}
      rows={rows}
    />
  );
}

export default VisitTasksTable;
