import { Button, DialogActions, DialogContent, TextField } from '@mui/material';
import { toast } from 'material-react-toastify';
import { useState } from 'react';
import { useDialog } from '../../../contexts/DialogContext';
import api from '../../../services/api';
import { ClientProps } from '../../../types/ClientProps';
import { TimesheetProps } from '../../../types/TimesheetProps';

interface TimesheetDialogProps {
  timesheet: TimesheetProps;
  refreshTimesheets: Function;
}

function TimesheetDialog({
  timesheet,
  refreshTimesheets,
}: TimesheetDialogProps) {
  const { toggleDialog } = useDialog();
  const [timesheetDetails, setTimesheetDetails] = useState(
    timesheet || ({} as ClientProps),
  );
  async function handleSubmit() {
    try {
      toast.dismiss();

      const response = await api.put(
        `/timesheet/${timesheet.id}`,
        timesheetDetails,
      );
      if (response.status === 200) {
        refreshTimesheets();
        toast.success(`Timesheet updated sucessfully!`);
      }
    } catch (error) {
      console.log(error);
      toast.error('Something went wrong!');
    }
    toggleDialog();
  }

  return (
    <>
      <DialogContent>
        <TextField
          id='comment'
          multiline
          value={timesheetDetails.comment}
          label='Comment'
          size='small'
          variant='standard'
          fullWidth
          onChange={e =>
            setTimesheetDetails((state: TimesheetProps) => ({
              ...state,
              comment: e.target.value,
            }))
          }
        />
      </DialogContent>
      <DialogActions>
        <Button
          color='error'
          type='button'
          onClick={() => toggleDialog()}
        >
          Close
        </Button>
        <Button onClick={() => handleSubmit()}>Save</Button>
      </DialogActions>
    </>
  );
}

export default TimesheetDialog;
