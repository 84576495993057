import {
  Autocomplete,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import { toast } from 'material-react-toastify';
import { useState } from 'react';
import { useDialog } from '../../../contexts/DialogContext';
import api from '../../../services/api';
import isEmpty from '../../../utils/hasEmptyProperties';
import { ClientProps } from '../../../types/ClientProps';
import TimeField from '../../atoms/TimeField';
import { EntityTaskProps } from '../../../types/EntityTaskProps';
import { ClientServiceOptionProps } from '../../../types/ClientServicesProps';

interface ClientTaskDialogProps {
  client: ClientProps;
  refreshClient: Function;
}

const initialValues: EntityTaskProps = {
  id: 0,
  name: '',
  price: 0,
  time: 0,
};

export default function ClientTaskDialog({
  client,
  refreshClient,
}: ClientTaskDialogProps) {
  const { toggleDialog } = useDialog();
  const [clientTaskData, setClientTaskData] = useState(initialValues);
  const [clientServiceOption, setClientServiceOption] =
    useState<ClientServiceOptionProps | null>(null);

  const canSubmit = isEmpty([
    clientServiceOption?.id,
    clientTaskData?.name,
    clientTaskData?.price,
    clientTaskData?.time,
  ]);

  const handleSubmit = async () => {
    const data = {
      clientServiceId: clientServiceOption ? clientServiceOption.id : null,
      ...clientTaskData,
    };

    const response = await api.post('/clientTask', data);

    if (response.status === 200) {
      toast.success('Task added successfully!');
      refreshClient();
    }

    toggleDialog();
  };

  return (
    <>
      <DialogTitle>Add service task</DialogTitle>
      <DialogContent>
        <Autocomplete
          sx={{ my: 2 }}
          options={client.clientServices || []}
          value={clientServiceOption}
          onChange={(e, value) => {
            if (value?.id && value.serviceName) {
              setClientServiceOption({
                id: value.id,
                serviceId: value.serviceId,
                serviceName: value.serviceName,
              });
            } else {
              setClientServiceOption(null);
            }
          }}
          getOptionLabel={option => option.serviceName || ''}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          style={{ width: 400 }}
          renderInput={params => (
            <TextField
              {...params}
              label='Service'
              placeholder='Service'
            />
          )}
        />
        <TextField
          label='Name'
          value={clientTaskData.name}
          variant='standard'
          sx={{ mb: 2 }}
          fullWidth
          onChange={e =>
            setClientTaskData({
              ...clientTaskData,
              name: e.target.value,
            })
          }
        />
        <TextField
          label='Price'
          value={clientTaskData.price || ''}
          variant='standard'
          type='number'
          sx={{ mb: 2 }}
          fullWidth
          onChange={e =>
            setClientTaskData({
              ...clientTaskData,
              price: parseFloat(e.target.value),
            })
          }
        />
        <TimeField
          label='Execution Time'
          value={clientTaskData.time}
          sx={{ width: '100%', mb: -0.5 }}
          onChange={e =>
            setClientTaskData({
              ...clientTaskData,
              time: Number(parseFloat(e.target.value).toFixed(2)),
            })
          }
        />
      </DialogContent>
      <DialogActions>
        <Button
          type='button'
          sx={{ color: 'error.main' }}
          onClick={() => toggleDialog()}
        >
          Cancel
        </Button>
        <Button
          onClick={() => handleSubmit()}
          disabled={!canSubmit}
        >
          Save
        </Button>
      </DialogActions>
    </>
  );
}
