import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import api from '../../../services/api';
import DashboardTitle from '../../../components/atoms/DashboardTitle';
import ProfileForm from '../../../components/molecules/ProfileForm';
import { UserProps } from '../../../types/UserProps';

function DashboardProfile() {
  const [user, setUser] = useState({} as UserProps);
  const [isLoading, setIsloading] = useState(false);
  const getUserProfile = async () => {
    setIsloading(true);
    const { data } = await api.get('/user');
    setUser(data.user);
    setIsloading(false);
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  return isLoading ? (
    <CircularProgress />
  ) : (
    <>
      <DashboardTitle title={`Hello ${user.username}!`} />
      <ProfileForm user={user} />
    </>
  );
}

export default DashboardProfile;
