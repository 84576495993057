import { IconButton } from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

function GoBackButton() {
  const navigate = useNavigate();

  return (
    <IconButton
      edge='start'
      color='inherit'
      aria-label='Go back'
      onClick={() => navigate(-1)}
      sx={{
        backgroundColor: 'white',
        boxShadow: 2,
        my: 'auto',
        height: 'fit-content',
        color: 'primary',
      }}
    >
      <ArrowBack />
    </IconButton>
  );
}

export default GoBackButton;
