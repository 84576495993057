import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/global.css';
import './styles/pdf.css';
import App from './App';
import * as serviceWorker from './utils/serviceWorker';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

serviceWorker.register();
