import { useEffect, useState } from 'react';
import api from '../services/api';
import { TimesheetProps } from '../types/TimesheetProps';

const useTimesheets = ({ bySupervisor }: { bySupervisor?: boolean }) => {
  const [timesheets, setTimesheets] = useState([] as TimesheetProps[]);

  const getAllTimesheets = async () => {
    let endpoint = '/timesheets';

    if (bySupervisor) {
      endpoint += '/bySupervisor';
    }

    const response = await api.get(endpoint);
    if (response.status === 200) {
      setTimesheets(response.data.timesheets);
    }
  };

  useEffect(() => {
    getAllTimesheets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    timesheets,
    getAllTimesheets,
  };
};
export default useTimesheets;
