import { useState, ChangeEvent } from 'react';
import { Alert, Box, Button, MenuItem, Paper, TextField } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { toast } from 'material-react-toastify';
import { useNavigate } from 'react-router-dom';
import useClients from '../../../hooks/useClients';
import useBillings from '../../../hooks/useBillings';
import regexPatterns from '../../../utils/regexPatterns';
import api from '../../../services/api';
import { BillingProps } from '../../../types/BillingProps';

const initialValues: BillingProps = {
  id: 0,
  companyName: '',
  accountsEmail: '',
  accountsContactNumber: '',
  companyAddress: '',
  companyRegNumber: '',
  status: '',
  clientId: 0,
};

export default function BillingForm() {
  const { clients } = useClients();
  const { getAllBillings } = useBillings();
  const navigate = useNavigate();

  const [billingData, setBillingData] = useState<BillingProps>(initialValues);
  const [clientId, setClientId] = useState<number | string>('');

  type ObjectKey = keyof typeof billingData;

  const inputs = [
    {
      name: 'companyName',
      type: 'text',
      label: 'Company name',
      message: "Company name shouldn't include any special character!",
      pattern: regexPatterns.spacedAlphanumeric,
    },
    {
      name: 'accountsEmail',
      type: 'email',
      label: 'Accounts email',
      message: 'Invalid email address!',
      pattern: regexPatterns.email,
    },
    {
      name: 'accountsContactNumber',
      type: 'text',
      label: 'Accounts contact number',
      message: 'Invalid phone!',
      pattern: regexPatterns.phone,
    },
    {
      name: 'companyAddress',
      type: 'text',
      label: 'Company address',
      message: "Company address shouldn't include any special character!",
      pattern: regexPatterns.spacedAlphanumeric,
    },
    {
      name: 'companyRegNumber',
      type: 'number',
      label: 'Company register number',
      message: 'Company register number must be numeric!',
      pattern: regexPatterns.numeric,
    },
  ];

  async function handleSubmit() {
    try {
      const billing = {
        ...billingData,
        clientId: clientId || null,
      };

      const response = await api.post('/billing', billing);
      if (response.status === 200) {
        toast.success('Billing created successfully!');
        getAllBillings();
      }
      navigate('/dashboard/billings');
    } catch (error) {
      toast.error('Something went wrong!');
    }
  }

  const isFormInvalid = inputs.some(input => {
    const inputName = input.name as ObjectKey;
    const inputPattern = billingData[inputName]
      ? billingData[inputName]!.toString()
      : '';
    return !input.pattern.test(inputPattern);
  });

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setBillingData({ ...billingData, [e.target.name]: e.target.value });
  };

  return (
    <Paper
      sx={{
        py: 2,
        px: 20,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(396px, 1fr))',
          columnGap: 5,
          rowGap: 3,
          my: 2,
        }}
      >
        {inputs.map(input => {
          const inputName = input.name as ObjectKey;
          const inputValue = billingData[inputName]
            ? billingData[inputName]!.toString()
            : '';
          const hasError =
            !!billingData[inputName] && !input.pattern.test(inputValue);
          const errorMessage = hasError ? input.message : '';

          return (
            <TextField
              key={input.name}
              value={billingData[inputName]}
              size='small'
              variant='standard'
              fullWidth
              onChange={onChange}
              error={hasError}
              helperText={errorMessage}
              {...input}
            />
          );
        })}
        <TextField
          label='Client'
          variant='standard'
          select
          value={clientId}
          onChange={event => setClientId(parseInt(event.target.value, 10))}
          sx={{ mt: -0.4 }}
        >
          {clients.map(option => (
            <MenuItem
              key={option.id}
              value={option.id}
            >
              {option.reference}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      {isFormInvalid && (
        <Alert
          severity='info'
          sx={{ mt: 2 }}
        >
          Please, fill all informations before submit
        </Alert>
      )}
      <Button
        variant='contained'
        endIcon={<SendIcon />}
        sx={{ mx: 'auto', mt: 3 }}
        disabled={isFormInvalid}
        onClick={() => handleSubmit()}
      >
        Create Billing
      </Button>
    </Paper>
  );
}
