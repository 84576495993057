import { Routes as RoutesList, Route, Navigate } from 'react-router-dom';
import AdminDashboard from './components/templates/AdminDashboard';
import Auth from './components/templates/Auth';
import Login from './pages/Login';
import DashboardRegister from './pages/Dashboard/Register';
import TermsAndPolicy from './pages/TermsAndPolicy';
import DashboardProfile from './pages/Dashboard/Profile';
import DashboardUsers from './pages/Dashboard/Users';
import DashboardTeams from './pages/Dashboard/Teams';
import DashboardInvite from './pages/Dashboard/Invite';
import DashboardOperativesBySupervisor from './pages/Dashboard/Operatives/Me';
import DashboardOperatives from './pages/Dashboard/Operatives';
import DashboardClients from './pages/Dashboard/Clients';
import DashboardBuildings from './pages/Dashboard/Buildings';
import DashboardBillings from './pages/Dashboard/Billings';
import AddBilling from './pages/Dashboard/Billings/Add';
import DashboardVisits from './pages/Dashboard/Visits';
import Invite from './pages/Invite';
import TeamDetails from './pages/Dashboard/Teams/Details';
import ClientDetails from './pages/Dashboard/Clients/Details';
import VisitDetails from './pages/Dashboard/Visits/Details';
import DashboardServices from './pages/Dashboard/Services';
import DashboardTask from './pages/Dashboard/Task';
import Quotes from './pages/Dashboard/Quotes';
import QuoteDetails from './pages/Dashboard/Quotes/Details';
import DashboardJobBriefs from './pages/Dashboard/JobBriefs';
import AddJobBrief from './pages/Dashboard/JobBriefs/Add';
import BuildingDetails from './pages/Dashboard/Buildings/Details';
import JobBriefDetails from './pages/Dashboard/JobBriefs/Details';
import Contracts from './pages/Dashboard/Contracts';
import DashboardTimesheets from './pages/Dashboard/Timesheets';
import DashboardTimesheetDetails from './pages/Dashboard/Timesheets/Details';
import AddTimesheet from './pages/Dashboard/Timesheets/Add';
import DashboardTimesheetReport from './pages/Dashboard/Timesheets/TimesheetReport';
import DashboardTimesheetReportDetails from './pages/Dashboard/Timesheets/TimesheetReport/Details';
import DashboardProgressReport from './pages/Dashboard/Timesheets/ProgressReport';
import DashboardProgressReportDetails from './pages/Dashboard/Timesheets/ProgressReport/Details';
import DashboardProductivity from './pages/Dashboard/Productivity';
import DashboardProductivityDetails from './pages/Dashboard/Productivity/details';
import ForgotPassword from './pages/ForgotPassword';
import PasswordReset from './pages/PasswordReset';
import AddVisit from './pages/Dashboard/Visits/Add';
import MobileDashboard from './components/templates/MobileDashboard';
import { useUser } from './contexts/UserContext';
import DashboardEOJs from './pages/Dashboard/EOJs';
import DashboardOverview from './pages/Dashboard/Overview';
import AddDriverTimesheet from './pages/Dashboard/DriverTimesheets/Add';
import DashboardDriverTimesheets from './pages/Dashboard/DriverTimesheets';
import DashboardPayrollReport from './pages/Dashboard/Timesheets/PayrollReport';
import DashboardDriversPayrollReport from './pages/Dashboard/Timesheets/DriversPayrollReport';
import DashboardPayrollReportDetails from './pages/Dashboard/Timesheets/PayrollReport/Details';
import DashboardDriversPayrollReportDetails from './pages/Dashboard/Timesheets/DriversPayrollReport/Details';

function Routes() {
  const { user } = useUser();
  const isSupervisor =
    user?.role === 'supervisor' || user?.role === 'contractor';

  return (
    <RoutesList>
      <Route element={<Auth />}>
        <Route
          path='/'
          element={
            <Navigate
              replace
              to='/login'
            />
          }
        />
        <Route
          path='/login'
          element={<Login />}
        />
        <Route
          path='/forgotPassword'
          element={<ForgotPassword />}
        />
        <Route
          path='/passwordReset/:passwordResetHash'
          element={<PasswordReset />}
        />
        <Route
          path='/terms'
          element={<TermsAndPolicy />}
        />
        <Route
          path='/invite/:hashLink'
          element={<Invite />}
        />
      </Route>
      <Route element={isSupervisor ? <MobileDashboard /> : <AdminDashboard />}>
        <Route
          path='/dashboard'
          element={<DashboardOverview />}
        />
        <Route
          path='/dashboard/profile'
          element={<DashboardProfile />}
        />
        <Route
          path='/dashboard/teams'
          element={<DashboardTeams />}
        />
        <Route
          path='/dashboard/teams/:id'
          element={<TeamDetails />}
        />
        <Route
          path='/dashboard/register'
          element={<DashboardRegister />}
        />
        <Route
          path='/dashboard/users'
          element={<DashboardUsers />}
        />
        <Route
          path='/dashboard/invite'
          element={<DashboardInvite />}
        />
        <Route
          path='/dashboard/operatives/me'
          element={<DashboardOperativesBySupervisor />}
        />
        <Route
          path='/dashboard/operatives'
          element={<DashboardOperatives />}
        />
        <Route
          path='/dashboard/clients'
          element={<DashboardClients />}
        />
        <Route
          path='/dashboard/clients/:id'
          element={<ClientDetails />}
        />
        <Route
          path='/dashboard/buildings'
          element={<DashboardBuildings />}
        />
        <Route
          path='/dashboard/buildings/:id'
          element={<BuildingDetails />}
        />
        <Route
          path='/dashboard/billings'
          element={<DashboardBillings />}
        />
        <Route
          path='/dashboard/billings/add'
          element={<AddBilling />}
        />
        <Route
          path='/dashboard/visits'
          element={<DashboardVisits />}
        />
        <Route
          path='/dashboard/visits/add'
          element={<AddVisit />}
        />
        <Route
          path='/dashboard/visits/:id'
          element={<VisitDetails />}
        />
        <Route
          path='/dashboard/services'
          element={<DashboardServices />}
        />
        <Route
          path='/dashboard/task'
          element={<DashboardTask />}
        />
        <Route
          path='/dashboard/quotes'
          element={<Quotes />}
        />
        <Route
          path='/dashboard/quotes/:id'
          element={<QuoteDetails />}
        />
        <Route
          path='/dashboard/jobBriefs'
          element={<DashboardJobBriefs />}
        />
        <Route
          path='/dashboard/eojs'
          element={<DashboardEOJs />}
        />
        <Route
          path='/dashboard/jobBriefs/add'
          element={<AddJobBrief />}
        />
        <Route
          path='/dashboard/jobBriefs/:id'
          element={<JobBriefDetails />}
        />
        <Route
          path='/dashboard/contracts'
          element={<Contracts />}
        />
        <Route
          path='/dashboard/timesheets'
          element={<DashboardTimesheets />}
        />
        <Route
          path='/dashboard/timesheets/:id'
          element={<DashboardTimesheetDetails />}
        />
        <Route
          path='/dashboard/timesheets/add'
          element={<AddTimesheet />}
        />
        <Route
          path='/dashboard/driverTimesheets'
          element={<DashboardDriverTimesheets />}
        />
        <Route
          path='/dashboard/driverTimesheets/add'
          element={<AddDriverTimesheet />}
        />
        <Route
          path='/dashboard/timesheetReport'
          element={<DashboardTimesheetReport />}
        />
        <Route
          path='/dashboard/timesheetReport/details'
          element={<DashboardTimesheetReportDetails />}
        />
        <Route
          path='/dashboard/progressReport'
          element={<DashboardProgressReport />}
        />
        <Route
          path='/dashboard/progressReport/details'
          element={<DashboardProgressReportDetails />}
        />
        <Route
          path='/dashboard/payrollReport'
          element={<DashboardPayrollReport />}
        />
        <Route
          path='/dashboard/payrollReport/details'
          element={<DashboardPayrollReportDetails />}
        />
        <Route
          path='/dashboard/driversPayrollReport'
          element={<DashboardDriversPayrollReport />}
        />
        <Route
          path='/dashboard/driversPayrollReport/details'
          element={<DashboardDriversPayrollReportDetails />}
        />
        <Route
          path='/dashboard/productivity'
          element={<DashboardProductivity />}
        />
        <Route
          path='/dashboard/productivity/details'
          element={<DashboardProductivityDetails />}
        />
      </Route>
    </RoutesList>
  );
}

export default Routes;
