import axios from 'axios';

const baseURL = process.env.REACT_APP_API_URL;
const api = axios.create({
  baseURL,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(config => {
  const token = localStorage.getItem('HPS:token');
  if (token) {
    /* eslint-disable no-param-reassign */
    config.headers!.Authorization = token;
  }

  return config;
});
export default api;
