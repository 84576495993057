import React, { ChangeEvent, FormEvent, useState } from 'react';
import {
  DialogContent,
  TextField,
  Button,
  DialogActions,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { toast } from 'material-react-toastify';
import api from '../../../services/api';
import { UserProps } from '../../../types/UserProps';
import { useDialog } from '../../../contexts/DialogContext';
import isEmpty from '../../../utils/hasEmptyProperties';
import regexPatterns from '../../../utils/regexPatterns';
import SelectField from '../../atoms/SelectField';
import { ROLES } from '../../../utils/constants';

interface UpdateUserProps {
  getAllUsers?: Function;
  user: UserProps;
}

const initialValues: UserProps = {
  id: null,
  username: '',
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  hourlyPayRate: 0,
  role: '',
  isDriver: false,
  teamId: null,
};

const roleOptions = ROLES.map(role => ({
  label: `${role.charAt(0).toUpperCase()}${role.slice(1)}`,
  value: role,
}));

function DetailsUserDialog({ getAllUsers, user }: UpdateUserProps) {
  const { toggleDialog } = useDialog();
  const [userData, setUserData] = useState<UserProps>(user || initialValues);

  const isOperative = userData?.role === 'operative';

  type ObjectKey = keyof typeof userData;

  const inputs = [
    {
      name: 'username',
      type: 'text',
      label: 'Username',
      message: 'Invalid username!',
      pattern: regexPatterns.alphanumeric,
      disabled: true,
      sx: { mb: 2 },
    },
    {
      name: 'firstName',
      type: 'text',
      label: 'First name',
      message: "First name shouldn't include any special character!",
      pattern: regexPatterns.spacedAlphanumeric,
      sx: { mb: 2 },
    },
    {
      name: 'lastName',
      type: 'text',
      label: 'Last name',
      message: "Last name shouldn't include any special character!",
      pattern: regexPatterns.spacedAlphanumeric,
      sx: { mb: !isOperative ? 2 : 0 },
    },
    ...(!isOperative
      ? [
          {
            name: 'email',
            type: 'text',
            label: 'Email',
            message: 'Invalid email!',
            pattern: regexPatterns.email,
            disabled: true,
            sx: { mb: 2 },
          },
          {
            name: 'phone',
            type: 'text',
            label: 'Phone',
            message: 'Invalid phone!',
            pattern: regexPatterns.phone,
          },
        ]
      : []),
  ];

  const defaultRequiredFields = [
    userData?.username,
    userData?.firstName,
    userData?.lastName,
    userData?.role,
  ];

  const noEmptyFields = isEmpty(
    isOperative
      ? defaultRequiredFields
      : defaultRequiredFields.concat([userData?.email, userData?.phone]),
  );

  const isFormInvalid = inputs.some(input => {
    const inputName = input.name as ObjectKey;
    const inputPattern = userData[inputName]
      ? userData[inputName]!.toString()
      : '';
    return !input.pattern.test(inputPattern);
  });

  const hasUpdatedFields =
    userData?.username !== user?.username ||
    userData?.firstName !== user?.firstName ||
    userData?.lastName !== user?.lastName ||
    (!isOperative && userData?.email !== user?.email) ||
    (!isOperative && userData?.phone !== user?.phone) ||
    Number(userData?.hourlyPayRate) !== Number(user?.hourlyPayRate) ||
    userData?.isDriver !== user?.isDriver ||
    userData?.role !== user?.role;

  const canSubmit = hasUpdatedFields && !isFormInvalid && noEmptyFields;

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUserData({
      ...userData,
      [e.target.name]: e.target.value ? e.target.value : null,
    });
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    toast.dismiss();

    const response = await api.put('/update', userData);

    if (response.status === 200) {
      toast.success(`User updated sucessfully!`);
      if (getAllUsers) getAllUsers();
    }

    toggleDialog();
  };

  return (
    <form onSubmit={handleSubmit}>
      <DialogContent sx={{ width: '100%', maxWidth: '450px' }}>
        {inputs.map(input => {
          const inputName = input.name as ObjectKey;
          const inputValue = userData[inputName]
            ? userData[inputName]!.toString()
            : '';
          const hasError =
            !!userData[inputName] && !input.pattern.test(inputValue);
          const errorMessage = hasError ? input.message : '';

          return (
            <TextField
              key={input.name}
              value={inputValue}
              size='small'
              variant='standard'
              fullWidth
              onChange={onChange}
              error={hasError}
              helperText={errorMessage}
              {...input}
            />
          );
        })}
        <TextField
          type='number'
          label='Hourly pay rate'
          value={userData.hourlyPayRate}
          size='small'
          variant='standard'
          fullWidth
          sx={{ mt: 2 }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setUserData({
              ...userData,
              hourlyPayRate: Number(e.target.value),
            });
          }}
        />
        {user.role !== 'operative' && (
          <SelectField
            label='Role'
            sx={{ mt: 3 }}
            value={userData.role}
            onChange={e =>
              setUserData({
                ...userData,
                role: e.target.value as string,
              })
            }
            options={roleOptions}
          />
        )}
        <FormControlLabel
          sx={{ minWidth: 'fit-content', mt: 2, mb: -2 }}
          control={
            <Checkbox
              checked={userData.isDriver}
              name='show'
              onClick={() =>
                setUserData({
                  ...userData,
                  isDriver: !userData.isDriver,
                })
              }
            />
          }
          label='Driver'
        />
      </DialogContent>
      <DialogActions>
        <Button
          color='error'
          type='button'
          onClick={() => toggleDialog()}
        >
          Cancel
        </Button>
        <Button
          type='submit'
          disabled={!canSubmit}
        >
          Update
        </Button>
      </DialogActions>
    </form>
  );
}

export default DetailsUserDialog;
