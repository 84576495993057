import React from 'react';
import DashboardTitle from '../../../../components/atoms/DashboardTitle';
import VisitForm from '../../../../components/molecules/VisitForm';

function AddVisit() {
  return (
    <>
      <DashboardTitle
        title='Visits'
        hasBackButton
      />
      <VisitForm />
    </>
  );
}

export default AddVisit;
