import { Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DashboardTitle from '../../../components/atoms/DashboardTitle';
import TeamDetailsDialog from '../../../components/molecules/TeamDetailsDialog';
import TeamsTable from '../../../components/molecules/TeamsTable';
import useTeams from '../../../hooks/useTeams';
import { useDialog } from '../../../contexts/DialogContext';

function DashboardTeams() {
  const { showDialog } = useDialog();
  const { teams, getAllTeams } = useTeams();

  return (
    <>
      <DashboardTitle title='Teams' />
      <Button
        variant='contained'
        endIcon={<AddIcon />}
        onClick={() =>
          showDialog({
            componentToRender: <TeamDetailsDialog getAllTeams={getAllTeams} />,
          })
        }
        sx={{ my: 2, mr: 4 }}
      >
        Add Team
      </Button>
      <TeamsTable
        teams={teams}
        getAllTeams={getAllTeams}
      />
    </>
  );
}

export default DashboardTeams;
