import AddIcon from '@mui/icons-material/Add';
import SendIcon from '@mui/icons-material/Send';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DashboardTitle from '../../../components/atoms/DashboardTitle';
import UsersTable from '../../../components/molecules/UsersTable';
import useUsers from '../../../hooks/useUsers';

function DashboardUsers() {
  const { getAllUsers, users } = useUsers();
  const navigate = useNavigate();

  return (
    <>
      <DashboardTitle title='Users' />
      <Button
        variant='contained'
        endIcon={<AddIcon />}
        onClick={() => navigate('/dashboard/register')}
        sx={{ my: 2 }}
      >
        Add User
      </Button>
      <Button
        variant='contained'
        endIcon={<SendIcon />}
        onClick={() => navigate('/dashboard/invite')}
        sx={{ my: 2, ml: 4 }}
      >
        Invite User
      </Button>
      <UsersTable
        getAllUsers={getAllUsers}
        users={users}
      />
    </>
  );
}

export default DashboardUsers;
